import * as React from 'react'
import {SVGProps} from 'react'

const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.79 3.41005C14.3367 3.95678 14.3367 4.84322 13.79 5.38995L9.17995 10L13.79 14.6101C14.3367 15.1568 14.3367 16.0432 13.79 16.5899C13.2433 17.1367 12.3568 17.1367 11.8101 16.5899L6.2101 10.9899C5.66337 10.4432 5.66337 9.55678 6.2101 9.01005L11.8101 3.41005C12.3568 2.86332 13.2433 2.86332 13.79 3.41005Z'
      fill='white'
    />
  </svg>
)
export default ArrowLeft
