/**
 * Props for the FormElemenWrapper component.
 */
interface FormElemenWrapperProps {
  /**
   * The label for the form element.
   */
  label?: string;

  /**
   * Specifies whether the form element is required or not.
   */
  required?: boolean;

  /**
   * Additional CSS class for the input element.
   */
  inputClass?: string;

  /**
   * The child elements of the FormElemenWrapper component.
   */
  children: React.ReactNode;
}
interface FormElemenWrapperProps {
  label?: string
  required?: boolean
  inputClass?: string
  children: React.ReactNode
}

const FormElemenWrapper = (props: FormElemenWrapperProps) => {
  // The props are destructured to get the values of the properties.
  const {label, required, children, inputClass = 'fv-row col-12 col-md-6 mb-xl-6 mb-sm-0 m-md-0'} = props
  return (
    // The FormElemenWrapper component is a functional component.
    <div className={`${inputClass}`}>
      {/* The label is displayed if it exists. */}
      {label && <label className={`form-label fs-6 text-dark ${required ? 'required' : ''}`} >{label}</label>}
      {/* The children of the component are displayed. */}
      {children}
    </div>
  )
}

export default FormElemenWrapper
