import { EventCatalogueGet, EventCatalogueUpsert } from "../../commons/interfaces/event/eventCatalogue.interface";
import api from "../../config/axios";

const catalog_api_base = '/api/content/event/catalog';

const eventCatalogueService = {
  show: async (id: number) => {
    try {
      const response = await api.get<EventCatalogueGet>(`${catalog_api_base}/get?content_event_id=${id}`)
      return response;
    } catch (error) {
        throw(error);
    }
  },
  upsert: async (data: EventCatalogueUpsert) => {
    try {
      const response = await api.post<any>(catalog_api_base, data);
      return response;
    } catch (error) {
        throw(error);
    }
  }
}

export default eventCatalogueService;