
/* import axios, { AxiosInstance } from 'axios';

 // Asegúrate de importar RootState desde tu configuración Redux

 function obtenerTokenDesdeLocalStorage() {
  const localStorageDataString = localStorage.getItem('persist:auth');
  
  if (localStorageDataString !== null) {
    const localStorageData = JSON.parse(localStorageDataString);
  
    if (localStorageData && localStorageData.token) {
      const token = localStorageData.token;
      const tokenWithoutQuotes = token.replace(/^"(.*)"$/, '$1');
      return tokenWithoutQuotes;
    }
  }
  
  return null;
}

// Obtén el token desde el localStorage
const tokenWithoutQuotes = obtenerTokenDesdeLocalStorage();
// Crea una instancia de Axios con una configuración personalizada
const api: AxiosInstance = axios.create({
  baseURL: 'https://api.eventostailoy.test.delfosti.site', // Reemplaza esto con la URL de tu API
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenWithoutQuotes}`,
  },

});


export default api; */

import axios from 'axios';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';

export const getAuthToken = () => {
  const state = store.getState()
  return state.auth.token;
};
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.eventostailoy.test.delfosti.site', 
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
  // if(error.response.status==403) window.location.href = '/error/403';
  // const userLocal= localStorage.getItem('persist:auth');
  // if(error.response.status==401 && userLocal && (JSON.parse(userLocal)?.userLogin!=="null")) window.location.href = '/error/401';
    if (error.response.status === 401){
      const pathRaw = window.location.pathname;
      const path = pathRaw.split('/').filter((item) => item !== '');
      const accessible_root_path = ['auth'];
      const isAccessible = accessible_root_path.includes(path[0]);
      if (isAccessible) return Promise.reject(error)
      
      const userLocal= localStorage.getItem('persist:auth');
      if (userLocal) localStorage.removeItem('persist:auth');
      window.location.href = '/auth';
    }

    return Promise.reject(error);
  }
);

export default api;
