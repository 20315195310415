import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTIcon, toAbsoluteUrl, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'
import { useDispatch } from 'react-redux'
import { setSelectedItem } from '../../../../../store/sidebar/sidebarSlice'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  urlIcon?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  urlIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config

  const dispatch = useDispatch();

  const handleSidebarItemClick = (t: { title: string; urlIcon: string; }) => {
    // Despacha la acción para actualizar el elemento seleccionado en Redux
    dispatch(setSelectedItem({ text: t.title, icon: t.urlIcon }));
  };

  return (
    <div className='menu-item' onClick={() => {
      if (urlIcon) {
        handleSidebarItemClick({ title, urlIcon })
      }
    }}>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        {urlIcon && (
          <span className='menu-icon'>

            <img src={toAbsoluteUrl(urlIcon + '.svg')} alt="" />
          </span>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div >
  )
}

export { SidebarMenuItem }
