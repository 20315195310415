import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import {useQuery} from 'react-query'

import {useNavigate} from 'react-router-dom'
import {TableListEvent} from './components/tables/TableListEvent'
import eventService from '../../../../services/event'
import IconSearch from '../../../../commons/icons/IconSearch'
import {IconMagnifier} from '../../../../commons/icons'
import {usePaginator} from '../../../../commons/hooks/usePaginator'
import {FilterOffCanvas} from './components/offCanvas/FilterOffCanvas'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {FormProvider, useForm} from 'react-hook-form'
import useModal from '../../../../commons/hooks/useModal'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store/store'
import { useAuth } from '../../auth'
import SearchFilter from '../../../../commons/components/search/SearchFilter'

const pageSizes = [10, 20, 50]
export const ListEvent = () => {
  const navigation = useNavigate()

  const {handleClose, handleShow, show} = useModal()
  const {eventSelectedCtx} = useAuth()
  const [notResult, setNotResult] = useState(false)
  const [notResultFilter, setNotResultFilter] = useState(false)
  const [filter, setFilter] = useState(eventSelectedCtx==0 ? {} : {id:eventSelectedCtx})
  const [search, setSearch] = useState<string>('')
  const {userLogin} = useSelector((state: RootState) => state.auth)
  const searchFilterRef = useRef<any>(null); // Referencia para el componente SearchFilter

  const methods =
    useForm(/* {
        defaultValues: {
            status: [{ value: '1', label: 'Activo' }],
        },
    } */)
  const {
    limit,
    setPage,
    paginationComponent,
    query: {data: event, refetch, isRefetching},
  } = usePaginator({
    /*    filters: data, */
    initPage: 1,
    initLimit: pageSizes.at(1)!,
    pageSizes,
    filters: filter,
    search,
    paginatorOptions: {
      queryKey: ['eventList'],
      fetch: eventService.list,
    },
  })

  useEffect(() => {
    setPage(1)
    const fetchData = async () => {
      const response = await refetch()
      setNotResult(response.data.data.result.data.length > 0 ? false : true)
      setNotResultFilter(
        response.data.data.result.data.length == 0 &&
          !(
            (Object.keys(filter).length == 1 && Object.keys(filter)[0] == 'content_event_id') ||
            Object.keys(filter).length == 0
          )
      )
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, filter, refetch, search])

  const handleSearch = () => {
    if(searchFilterRef) searchFilterRef.current.triggerSearch()
  }

  return (
    <div className=''>
      <div className='title'>Lista de Eventos</div>

      {eventSelectedCtx==0 &&(<div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap border-0 pt-6'>
        {/* begin::Search */}
        <div className='d-flex gap-3'>
           <SearchFilter manageSearch={setSearch} ref={searchFilterRef} name='evento'/>
           <div className='p-1'>
           <button
              type='button'
              className='btn btn-primary'
              onClick={handleSearch}
            >
              <IconMagnifier stroke="white"/>
            </button>
           </div>
        </div>
        {/* end::Search */}
        <div className='row w-sm-325px mt-4 mt-sm-0 d-flex justify-content-end'>
          <div className='col-6 col-sm-5 '>
            <button
              type='button'
              className='btn btn-outline btn-outline-primary w-100 '
              onClick={handleShow}
            >
              <IconSearch className='me-3' />
              Filtros
            </button>
          </div>
          
          {(userLogin?.capability_permissions.includes('content_event_create') || userLogin?.admin == 1) && (
            <div className='col-6 col-sm-7'>
              <button
                type='button'
                className='btn btn-primary w-100 '
                onClick={() => navigation('/eventos/crear')}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Crear Evento
              </button>
            </div>
          )}

        </div>
      </div>)}

      <div className='mt-6'>
        <div className='overflow-auto table-list'>
          <TableListEvent
            refetched={isRefetching}
            refetch={refetch}
            data={event?.data.result.data}
            isNotResult={notResult}
            isNotResultFilter={notResultFilter}
          />
        </div>
      </div>

      {notResult || notResultFilter ? null : paginationComponent()}

      {/* Filtros */}
      <div className='d-none'>
        <FormProvider {...methods}>
          <FilterOffCanvas
            filter={filter}
            setFilter={setFilter}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
          />
        </FormProvider>
      </div>
    </div>
  )
}
