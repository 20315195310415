export const documentTypeCodes = {
  DNI: 'DNI',
  CE: 'CE',
  PA: 'PA',
  RUC: 'RUC'
}

export const documentTypeList = [
  {
    value: documentTypeCodes.DNI,
    label: 'DNI',
  },
  {
    value: documentTypeCodes.CE,
    label: 'Carnet de extranjería',
  },
  {
    value: documentTypeCodes.PA,
    label: 'Pasaporte',
  },
  {
    value: documentTypeCodes.RUC,
    label: 'RUC',
  },
]