import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { title } from 'process';

interface SidebarState {
  selectedItem: {
    text: string;
    icon: string;
  } | null;
}

const initialState: SidebarState = {
  selectedItem: null,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<{ text: string; icon: string } | null>) => {
      state.selectedItem = action.payload;
    },
  },
});

export const { setSelectedItem } = sidebarSlice.actions;
//export const selectSelectedItem = (state: { sidebar: SidebarState }) => state.sidebar.selectedItem;
export default sidebarSlice.reducer;
