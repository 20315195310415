import FormElemenWrapper from '../../../../../commons/components/formElemenWrapper/FormElemenWrapper'
import {documentTypeList} from '../../../../../commons/enums/documentTypes.enums'
import {GeneralDataform} from '../../../../../commons/interfaces/order/orderView'

interface GeneralDataProps {
  data: GeneralDataform
}

const GeneralData = ({data}: GeneralDataProps) => {
  return (
    <div className='mt-6 ms-md-4'>
      <h2 className='title-form fw-bold mb-5'>Información Personal</h2>
      <div className='row  mb-6 '>
        <FormElemenWrapper label='Tipo de documento '>
          <select
            className='form-select form-select-solid form-select-solid-disabled'
            aria-label='Disabled select example'
            value={data.document_type}
            disabled
          >
            {documentTypeList.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </FormElemenWrapper>
        <FormElemenWrapper label='Número de documento '>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={data.document_number}
            disabled
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Nombres'>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={data.firstname}
            disabled
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Apellidos'>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={data.lastname}
            disabled
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Nombre del evento'>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={data.event_name}
            disabled
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Fechas del evento'>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={`${data.start_date} - ${data.end_date}`}
            disabled
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Estado'>
          <input
            type='text'
            className='form-control form-control-solid form-control-lg'
            value={data.status}
            disabled
          />
        </FormElemenWrapper>
      </div>
    </div>
  )
}

export default GeneralData
