import * as React from "react"
const IconShoppingBag = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={19}
        fill="none"
        {...props}
    >
        <path
            stroke={props.stroke ?? "#989898"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.67}
            d="M11.577 8.605V5.027a3.578 3.578 0 0 0-7.155 0v3.578m-2.684-1.79H14.26l.895 10.734H.844l.894-10.733Z"
        />
    </svg>
)
export default IconShoppingBag