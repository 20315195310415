import { Link, useNavigate } from 'react-router-dom'
import Dropdown from 'react-overlays/Dropdown'
import {
  IconDot,
  IconEye,
  IconGift,
  IconHeart,
  IconSearch2,
  IconShoppingBag,
  IconUsers,
} from '../../../../../../commons/icons'
import { Daum } from '../../../../../../commons/interfaces/event/eventList'
import React, { useEffect, useRef, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { format, parseISO } from 'date-fns'
import { Button, Overlay, Popover } from 'react-bootstrap'
import DropdownIcon from '../../../../../../commons/components/overlay/DropdownIcon'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'

interface IProps {
  data: Daum[] | undefined
  refetched: boolean
  refetch: any
  isNotResult?: boolean
  isNotResultFilter?: boolean
}
export const TableListEvent = ({
  data,
  refetched,
  refetch,
  isNotResult,
  isNotResultFilter,
}: IProps) => {
  const navigation = useNavigate()
  const { userLogin } = useSelector((state: RootState) => state.auth)

  const redirectDetail = (item: Daum) => {
    navigation(`/eventos/detalle/${item.id}`, {
      state: { item },
    })
  }


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded '>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-50px'> </th>
            <th className='min-w-100px'>Código del Evento</th>
            <th className='min-w-100px'>Nombre</th>
            <th className='min-w-100px'>ID-AS400</th>
            <th className='min-w-100px'>Fecha del Evento</th>
            <th className='min-w-100px'>Estado</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => {
            const fechaStartFormateada = format(parseISO(item.start_date), 'dd/MM/yyyy')
            const fechaEndFormateada = format(parseISO(item.end_date), 'dd/MM/yyyy')

            return (
              <tr key={item.id}>
                <td>
                  <div className='d-flex justify-content-start'>
                    <DropdownIcon>
                      <>
                        <div
                          onClick={() => redirectDetail(item)}
                          className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-start align-items-start gap-3'
                        >
                          <div className='w-full'>
                            <IconEye color='#00A160' />
                          </div>

                          <div className='text-label fw-lighter tw-green-700'>Detalle</div>
                        </div>
                        <Link
                          to={`/surtido/listar/?code=${item.code}`}
                          // onClick={() => redirectDetail(item)}
                          className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-start align-items-start gap-3'
                        >
                          <div className='w-full'>
                            <IconShoppingBag stroke='#0CB1EB' />
                          </div>
                          <div className='text-label fw-lighter tw-sky-blue-500'>Surtido</div>
                        </Link>
                        <Link
                          to={`/clientes/listar/?code=${item.code}`}
                          //onClick={() => redirectDetail(item)}
                          className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-start align-items-start gap-3'
                        >
                          <div className='w-full text-center'>
                            <IconUsers stroke='#F79B40' />
                          </div>
                          <div className='text-label fw-lighter tw-orange-400'>Clientes</div>
                        </Link>
                        {(userLogin?.capability_permissions.includes('purchase_cart_list') ||
                          userLogin?.admin === 1) &&
                            <Link
                              to={`/wishlist/listar/?code=${item.code}`}
                              onClick={() => redirectDetail(item)}
                              className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-start align-items-start gap-3'
                            >
                              <div className='w-full text-center'>
                                <IconHeart stroke='#8542DB' />
                              </div>
                              <div className='text-label fw-lighter tw-purple-600'>Pre-pedido</div>
                            </Link>
                        }
                        {/* <div
                          onClick={() => redirectDetail(item)}
                          className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-start align-items-start gap-3'
                        >
                          <div className='w-full text-center'>
                            <IconGift stroke='#E919A2' />
                          </div>
                          <div className='text-label fw-lighter tw-fucsia-600'>Regalos</div>
                        </div> */}
                      </>
                    </DropdownIcon>
                  </div>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {item.code}
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {item.name}
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    <span className='text-muted me-2 fs-7 fw-semibold'>{item.code_as400}</span>
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted '>
                    {fechaStartFormateada && fechaEndFormateada
                      ? `${fechaStartFormateada} - ${fechaEndFormateada}`
                      : 'Sin programación'}
                  </span>
                </td>
                {/*                             <td>
                                    <span className='text-muted fw-semibold text-muted '>
                                        {item.end_date ? item.end_date : "Sin programación"}
                                    </span>
    
                                </td> */}

                <td>
                  <span className='fw-semibold'>
                    {item.status === 1 ? (
                      <div className='state-active fw-bold w-100px d-flex justify-content-center'>
                        <div className='d-flex align-items-center '>
                          <IconDot height={7} width={7} />
                          <div className='ms-2'> Activo</div>
                        </div>
                      </div>
                    ) : (
                      <div className='state-inactive fw-bold  w-125px d-flex justify-content-center'>
                        <div className='d-flex align-items-center'>
                          <IconDot fill='#EF4444' height={7} width={7} />
                          <div className='ms-2'> Inactivo</div>
                        </div>
                      </div>
                    )}
                  </span>
                </td>
              </tr>
            )
          })}
          {isNotResult && (
            <tr>
              <td colSpan={10}>
                <div className='d-flex justify-content-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  {isNotResultFilter && (
                    <p style={{ fontWeight: 300 }} className=' text-label mb-2'>
                      No se han encontrado resultados en esta búsqueda.
                    </p>
                  )}
                  <p className='text-label tw-green-700'>
                    {isNotResultFilter
                      ? 'Por favor, intenta otra vez.'
                      : 'No se han encontrado resultados en esta búsqueda.'}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}
