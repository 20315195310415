import * as React from "react"
import { SVGProps } from "react"
const IconNote = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={17}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.66677 2.89931H3.11122C2.25211 2.89931 1.55566 3.59575 1.55566 4.45486V13.7882C1.55566 14.6473 2.25211 15.3438 3.11122 15.3438H10.889C11.7481 15.3438 12.4446 14.6473 12.4446 13.7882V4.45486C12.4446 3.59575 11.7481 2.89931 10.889 2.89931H9.33344M4.66677 2.89931C4.66677 3.75842 5.36322 4.45486 6.22233 4.45486H7.77789C8.637 4.45486 9.33344 3.75842 9.33344 2.89931M4.66677 2.89931C4.66677 2.0402 5.36322 1.34375 6.22233 1.34375H7.77789C8.637 1.34375 9.33344 2.0402 9.33344 2.89931M7.00011 8.34375H9.33344M7.00011 11.4549H9.33344M4.66677 8.34375H4.67455M4.66677 11.4549H4.67455"
            stroke="#00A160"
            strokeWidth={1.67}
            strokeLinecap="round"
        />
    </svg>
)
export default IconNote