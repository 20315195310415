import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import wishlistService from '../../../../../services/whislist'
import { useAuth } from '../../../auth'
import eventService from '../../../../../services/event'
import clsx from 'clsx'
import { IconAlertCircle, IconCheckCircle, IconSearch2 } from '../../../../../commons/icons'
import { useNavigate } from 'react-router-dom'
import Toast from '../Toast'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
interface IProps {
  show: boolean
  onHide: () => void
  onClick?: () => void
}

const searchClientSchema = Yup.object().shape({
  document_type: Yup.string().required('Tipo de documento es requerido'),
  document_number: Yup.string()
    .required('Numero de documento es requerido')
    .test('document_number', 'El número de documento es inválido', (value, context) => {
      const { document_type } = context.parent
      if (document_type === 'DNI') {
        return value.length === 8 && /^[0-9]+$/.test(value)
      } else if (document_type === 'CE') {
        return value.length === 9 && /^[0-9]+$/.test(value)
      }
      return true
    }),
  content_event_id: Yup.string().required('Evento es requerido'),
})

export const ModalSearchDocument = ({ show, onHide, onClick }: IProps) => {
  const { eventSelectedCtx } = useAuth()
  const { userLogin } = useSelector((state: RootState) => state.auth)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSuccessRedirect, setIsSuccessRedirect] = useState(false)
  const [contentEventId, setContentEventId] = useState('')
  const [entityCustomerId, setEntityCustomerId] = useState('')
  const navigate = useNavigate()


  useEffect(() => {
    resetForm()
  }, [show])

  useEffect(() => {
    if (isError || isSuccess)
      setTimeout(() => {
        setIsError(false)
        setIsSuccess(false)
      }, 3000)
  }, [isError, isSuccess])

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(searchClientSchema),
  })

  const searchMutation = useMutation({
    mutationFn: wishlistService.searchByDocument,
    onSuccess: (data) => {
      if (data.status == 200 && data.data.result.status == 1) {
        setIsSuccessRedirect(true)
        setIsError(false)
        setIsSuccess(true)
        setLoading(false)
        setContentEventId(data.data.result.content_event_id)
        setEntityCustomerId(data.data.result.id)
      } else showError()
    },
    onError: (e: any) => {
      showError()
    },
  })

  const showError = () => {
    setIsError(true)
    setIsSuccess(false)
    setContentEventId('')
    setEntityCustomerId('')
    setLoading(false)
    setIsSuccessRedirect(false)
  }

  const onSubmit = (data: any) => {
    setLoading(true)
    searchMutation.mutate({ ...data })
    //onHide()
  }

  const handleBlur = async () => {
    await trigger('document_number')
  }

  const resetForm = () => {
    reset()
    setIsSuccess(false)
    setIsError(false)
    setLoading(false)
    setIsSuccessRedirect(false)
    setContentEventId('')
    setEntityCustomerId('')
  }

  return (
    <Modal
      show={show}
      aria-labelledby='example-modal-sizes-title-lg'
      centered
      dialogClassName='bs-modal-width-md'
    >
      <Modal.Header onClick={onHide} closeButton className='border-0 pb-0 mb-0'></Modal.Header>
      <Modal.Title id='example-modal-sizes-title-lg'>
        <div className='row container m-4'>
          <h3 className='fs-1'> Buscar pre-pedido</h3>
        </div>
      </Modal.Title>
      <Modal.Body className='pt-3'>
        <div className='container'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-6 mb-6'>
                <label className='form-label fs-6 required text-dark'>Tipo de documento</label>
                <select
                  id='document_type'
                  defaultValue={'DNI'}
                  {...register('document_type')}
                  required
                  className={clsx('form-select bg-transparent', {
                    'is-invalid': errors.document_type,
                  })}
                >
                  <option selected value='DNI'>
                    DNI
                  </option>
                  <option value='CE'>Carnet de extranjería</option>
                  <option value='PA'>Pasaporte</option>
                  <option value='RUC'>RUC</option>
                </select>
                {errors.document_type && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {errors.document_type.message as string}
                    </span>
                  </div>
                )}
              </div>
              <div className='col-6  mb-6'>
                <label className='form-label fs-6 required text-dark'>Número de documento</label>
                <input
                  type='text'
                  id='document_number'
                  required
                  autoComplete='off'
                  {...register('document_number')}
                  className={clsx('form-control', { 'is-invalid': errors.document_number })}
                  onKeyUp={handleBlur}
                />
                {errors.document_number && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {errors.document_number.message as string}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <label className='form-label fs-6 required text-dark'>Evento</label>
                <select
                  id='select_event'
                  {...register('content_event_id')}
                  required
                  className={clsx('form-select bg-transparent', {
                    'is-invalid': errors.content_event_id,
                  })}
                >
                  {eventSelectedCtx == 0
                    ? userLogin?.active_events?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                    : userLogin?.active_events
                      ?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                      .filter((item) => item.key == eventSelectedCtx)}
                </select>
                {errors.content_event_id && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {errors.content_event_id.message as string}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {isError && (
              <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
                <Toast
                  title='Cliente no registrado y/o inactivo.'
                  type='warning'
                  icon={<IconAlertCircle width={24} height={24} />}
                />
              </div>
            )}

            {isSuccess && (
              <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
                <Toast
                  title='Se encontró el pre-pedido del cliente.'
                  type='success'
                  icon={<IconCheckCircle width={24} height={24} />}
                />
              </div>
            )}

            <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
              {!isSuccessRedirect && (
                <button
                  type='submit'
                  disabled={!isDirty || !isValid || loading}
                  id='kt_search_submit'
                  className={`btn ${!isDirty || !isValid ? 'btn-disabled' : 'btn-primary'}`}
                >
                  {!loading && (
                    <>
                      <IconSearch2
                        fill={!isDirty || !isValid ? '#BDBDBD' : 'white'}
                        width={20}
                        height={20}
                      />
                      <span className='indicator-label'> Buscar pre-pedido</span>
                    </>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Cargando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}

              {isSuccessRedirect && (
                <button type='button' id='kt_redirect_submit' className={`btn btn-primary`} onClick={() => { navigate(`/wishlist/cliente/${contentEventId}/${entityCustomerId}`) }}>
                  <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                  <span className='indicator-label'>Ir a pre-pedido</span>
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
