import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import { Controller, useForm, useFormContext } from 'react-hook-form';

import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';



export const AssortmentDetail = () => {
    const { userLogin } = useSelector((state: RootState) => state.auth)

    const { clearErrors, control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields, isSubmitting, isValid }, setError } = useFormContext();




    return (
        <div className="">
            <div className="mt-6 ms-md-4">

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6 text-dark'>SKU</label>
                        <input
                            placeholder=''
                            disabled
                            {...register('sku')}
                            // defaultValue={result?.data?.result?.document_number}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.sku },
                                {
                                    'is-valid': touchedFields.sku && !errors.sku,
                                }
                            )}
                            type='text'
                            autoComplete='off'

                        />
                        {/*     {errors.sku && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.sku.message as string}</span>
                            </div>
                        )}
 */}

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Nombre del SKU</label>
                        <input
                            placeholder=''
                            disabled
                            {...register('name')}
                            //={result?.data?.result?.name}
                            className={clsx(
                                'form-control',
                                { 'is-invalid': errors.name },
                                {
                                    'is-valid': touchedFields.name && !errors.name,
                                }
                            )}
                            type='text'
                            name='name'
                            autoComplete='off'
                        />
                        {/* {errors.name && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.name.message as string}</span>
                            </div>
                        )} */}

                    </div >
                </div>

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6 text-dark'>Unidad de medida </label>
                        <select
                            disabled

                            className={clsx(
                                'form-select',
                            )}
                            aria-label="Disabled select example"
                            {...register("unit_measure_id")}
                        //defaultValue={result?.data?.result?.phone}
                        >
                            {userLogin?.unit_measures.map(item => (
                                <option value={item.id}>{item.name}</option>
                            ))}

                        </select>

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Marca</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={result?.data?.result?.phone}
                            {...register('brand')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.brand },
                                {
                                    'is-valid': touchedFields.brand && !errors.brand,
                                }
                            )}
                            type='text'
                            name='brand'
                            autoComplete='off'
                        />
                        {errors.brand && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.brand.message as string}</span>
                            </div>
                        )}

                    </div >
                </div>

                <div className='row  mb-6'>


                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6  text-dark'>Linea</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={result?.data?.result?.phone}
                            {...register('line')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.line },
                                {
                                    'is-valid': touchedFields.line && !errors.line,
                                }
                            )}
                            type='text'
                            name='line'
                            autoComplete='off'
                        />
                        {errors.line && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.line.message as string}</span>
                            </div>
                        )}

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Empaque Master</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={`${userLogin?.firstname} ${userLogin?.lastname}`}
                            {...register('master_package')}
                            className={clsx(
                                'form-control form-control-solid',
                                { 'is-invalid': errors.master_package },
                                {
                                    'is-valid': touchedFields.master_package && !errors.master_package,
                                }
                            )}
                            type='text'
                            name='master_package'
                            autoComplete='off'
                        />
                        {errors.master_package && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.master_package.message as string}</span>
                            </div>
                        )}

                    </div >

                </div>
                <div className='row  mb-6'>


                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6  text-dark'>RUC</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={result?.data?.result?.phone}
                            {...register('ruc')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.ruc },
                                {
                                    'is-valid': touchedFields.ruc && !errors.ruc,
                                }
                            )}
                            type='text'
                            name='ruc'
                            autoComplete='off'
                        />
                        {errors.ruc && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.ruc.message as string}</span>
                            </div>
                        )}

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Razón Social</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={`${userLogin?.firstname} ${userLogin?.lastname}`}
                            {...register('business_name')}
                            className={clsx(
                                'form-control form-control-solid',
                                { 'is-invalid': errors.business_name },
                                {
                                    'is-valid': touchedFields.business_name && !errors.business_name,
                                }
                            )}
                            type='text'
                            name='business_name'
                            autoComplete='off'
                        />
                        {/*  {errors.business_name && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.business_name.message as string}</span>
                            </div>
                        )} */}

                    </div >

                </div>

                <div className='row  mb-6'>


                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6  text-dark'>Código del evento</label>

                        <input
                            placeholder=''
                            disabled
                            //defaultValue={result?.data?.result?.phone}
                            {...register('code_as400')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.code_as400 },
                                {
                                    'is-valid': touchedFields.code_as400 && !errors.code_as400,
                                }
                            )}
                            type='text'
                            name='code_as400'
                            autoComplete='off'
                        />
                        {/*  {errors.code_as400 && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.code_as400.message as string}</span>
                            </div>
                        )} */}

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6 text-dark'>Categoría del producto</label>
                        <select
                            disabled

                            className={clsx(
                                'form-select',
                            )}
                            aria-label="Disabled select example"
                            {...register("taxonomy_category_id")}
                        //defaultValue={result?.data?.result?.phone}
                        >
                            {userLogin?.taxonomy_categories.map(item => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </select>


                    </div >




                </div>


                <div className='row  mb-6'>

                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6 text-dark'>Estado</label>
                        <select
                            disabled

                            className={clsx(
                                'form-select',
                            )}
                            aria-label="Disabled select example"
                            {...register("status")}
                        //defaultValue={result?.data?.result?.phone}
                        >
                            <option value="1">Activo</option>
                            <option value="2">Inactivo</option>
                        </select>


                    </div >




                </div>
            </div>


        </div >
    )
}
