import { InformationBlockEventGet, InformationBlockEventUpsert } from "../../commons/interfaces/event/informationBlockEvent.interface";
import api from "../../config/axios";

const information_block_api_base = '/api/content/event/block';

const eventInformationBlock = {
  show: async (id: number) => {
    try {
      const response = await api.get<InformationBlockEventGet>(`${information_block_api_base}/get?content_event_id=${id}`)
      return response;
    } catch (error) {
        throw(error);
    }
  },
  upsert: async (data: InformationBlockEventUpsert) => {
    try {
      const response = await api.post<any>(information_block_api_base, data);
      return response;
    } catch (error) {
        throw(error);
    }
  }
}

export default eventInformationBlock;