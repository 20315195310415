import Modal from 'react-bootstrap/Modal'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'

export const ModalRecoverPassword = (props) => {
  return (
    <Modal
      {...props}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <SVG src={toAbsoluteUrl('/media/svg/recovery-password/check.svg')} />
              <h4 className='fs-2 text-label tw-neutral-800 mt-9'>Enlace enviado</h4>
              <p className='tw-neutral-600 fs-6 fw-light  mt-2'>
                Se volvió a enviar el enlace de recuperación.
              </p>
            </div>

            <div className='d-grid gap-2 mt-8'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary '
                onClick={props.onHide}
                /*  disabled={isSubmitting || !isValid} */
              >
                Aceptar
              </button>
            </div>
          </div>

          <div onClick={props.onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
