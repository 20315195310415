import {GiftSelected} from '../../../../../commons/interfaces/gifts/giftList.interface'

interface TableGiftsWithCheckboxProps {
  giftsTable: GiftSelected[]
  setGiftsTable: (giftsSelected: GiftSelected[]) => void
}

const TableGiftsWithCheckbox = (props: TableGiftsWithCheckboxProps) => {
  const { giftsTable } = props


  return (
    <div className='table-responsive rounded'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-150px px-5'>
              Producto
            </th>
            <th className='min-w-200px px-5'>
              Cant. disponible
            </th>
          </tr>
        </thead>
        {/* end::Table head */}
        <tbody>
          {giftsTable?.map((gift, index) => (
            <tr key={index}>
              <td className='px-5'>
                <span className='text-muted fw-semibold d-block fs-7'>
                  {gift.name}
                </span>
              </td>
              <td className='px-5'>
                <span className='text-muted fw-semibold d-block fs-7'>
                  {gift.quantity}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
        {/* begin::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}

export default TableGiftsWithCheckbox
