import { useNavigate } from 'react-router-dom'
import { IconArrowDown } from '../../icons'

interface IProps {
    title: string
    icon?: boolean
    disabled?: boolean
    editTitle?: string
}

export const MainTitle = ({ title, icon, disabled, editTitle }: IProps) => {
    const navigation = useNavigate()
    return (
        <div className="d-flex gap-5 align-items-center">
            {icon && (
                <div className="p-2 cursor-pointer fa-rotate-270 " onClick={() => navigation(-1)}>
                    <IconArrowDown stroke='#00A160' width={25} height={25} />
                </div>)}
            <div className="title">
                {disabled && title}
                {!disabled && editTitle}

            </div>
        </div >

    )
}
