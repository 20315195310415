import * as React from "react"
import { SVGProps } from "react"
const IconDecrement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={2}
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1H13"
      stroke={props.stroke ?? "#292D32"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconDecrement
