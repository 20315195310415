import {SuppliersGet} from '../../commons/interfaces/event/eventSupplier.interface'
import api from '../../config/axios'

const map_api_base = '/api/entity/supplier'

const eventSupplierService = {
  get: async (id: number) => {
    try {
      const response = await api.get<SuppliersGet>(`${map_api_base}/get?content_event_id=${id}`)
      return response?.data?.result || []
    } catch (error) {
      throw error
    }
  },
}

export default eventSupplierService
