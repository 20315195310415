import React, { useEffect, useState } from 'react'
import { MainTitle } from '../../../../commons/components/Title/MainTitle'
import { IconAlertCircle, IconCalculator, IconCheckCircle } from '../../../../commons/icons'
import ArrowBack from '../../../../commons/icons/ArrowBack'
import { useNavigate, useParams } from 'react-router'
import { ModalConfirmation } from '../../../../commons/components/modals/ModalConfirmation'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import wishlistService from '../../../../services/whislist'
import { ModalError } from '../../configuration/components/modals/ModalError'

export const WishListConfirmClient = () => {
  const params = useParams()
  const { id } = params;
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  // Status to control the message displayed when the service fails
  const [messageError, setMessageError] = useState<string>("Lo sentimos, parece que ha ocurrido un error en tu pedido. ");
  const [IgvValue, setIgvValue] = useState('')
  const response = useSelector((state: any) => state.wishlistconfirm.response)
  const paymentCondition = useSelector((state: any) => state.wishlistData.payment_condition);
  const dispatchDate = useSelector((state: any) => state.wishlistData.dispatch_date);
  const contentProducts = useSelector((state: any) => state.wishlistData.content_products);
  const idCustumer = useSelector((state: any) => state.wishlistData.entity_customer_id);
  const [SubtotalValue, setSubtotal] = useState('')
  const navigate = useNavigate()
  const dataJsonList = response?.data?.result

  const ChangeValueIgv = (total: any) => {
    const igv = total * 0.18
    const subtotal = total - igv
    const igvFormatted = formatted(igv)
    setIgvValue(igvFormatted)
    const subtotalFormatted = formatted(subtotal)
    setSubtotal(subtotalFormatted)
  }

  const formatted = (data: any) => {
    const formatter = new Intl.NumberFormat('es-PE', {
      style: 'currency',
      currency: 'PEN',
    })
    const formmated = formatter.format(data)
    return formmated
  }
  useEffect(() => {
    ChangeValueIgv(dataJsonList?.total)
  })
  const handleConfirmation = async () => {
    try {
      // setLoading(true)
      const response = await wishlistService.generateWishlist(
        contentProducts,
        idCustumer,
        paymentCondition,
        dispatchDate)
      if (response) {
        toast.success('El pedido fue generado con éxito.', {
          position: 'bottom-right',
          autoClose: 5000, // Tiempo de cierre automático en milisegundos
          hideProgressBar: true, // Mostrar barra de progreso
          closeOnClick: true, // Cerrar al hacer clic
          draggable: true, // Arrastrable
          progress: undefined, // Personalizar la barra de progreso
          icon: <IconCheckCircle />,
          bodyClassName: 'custom-toast-success',
        })
        navigateWishlist()

      }
    } catch (error: any) {
      // When the services fail, the message that comes with error 500 should be displayed
      if ( error?.response?.data?.code === 500 && error?.response?.data?.errors?.msg ) {
        setMessageError(error?.response?.data?.errors?.msg)
      } else {
        setMessageError('Lo sentimos, parece que ha ocurrido un error en tu pedido. ')
      }
      setShowModalError(true)
    }
  }





  const navigateWishlist = () => {
    navigate(`/wishlist/detalle/${id}`)
  }
  return (
    <div className=''>
      <MainTitle disabled title='Generar pedido' icon />
      <div className=' '>
        <div className='mt-6 '>
          <h2 className='title-form fw-bold mb-5'>Resumen de pre-pedido</h2>
          <div className='table-responsive rounded ' style={{ maxHeight: '400px' }}>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead className='bg-head-table '>
                <tr className='fw-medium text-label'>
                  <th className='min-w-150px'>Código de SKU</th>
                  <th className='min-w-150px'>Nombre del SKU</th>
                  <th className='min-w-150px'>Marca del SKU</th>
                  <th className='min-w-150px'>Cant. solicitada</th>
                  <th className='min-w-150px'>Und. de medida</th>
                  <th className='min-w-150px'>Precio unitario</th>
                  <th className='min-w-175px'>Equi. en unidad master</th>
                  <th className='min-w-150px'>Und. master</th>
                  <th className='min-w-150px'>Precio final</th>
                  <th className='min-w-150px'>Línea</th>
                  <th className='min-w-150px'>Proveedor del SKU</th>
                </tr>
              </thead>
              <tbody>
                {dataJsonList?.content_products?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.sku ? item?.sku : ''}</td>
                    <td>{item?.name ? item?.name : ''}</td>
                    <td>{item?.brand ? item?.brand : ''}</td>
                    <td>{item?.quantity ? item?.quantity : ''}</td>
                    <td>{item?.unit_measure ? item?.unit_measure : ''}</td>
                    <td>{item?.unit_price ? formatted(item?.unit_price) : ''}</td>
                    <td>{item?.master_package ? item?.master_package : ''}</td>
                    <td>{item?.conversion_unit ? item?.conversion_unit : ''}</td>
                    <td>{item?.final_price ? formatted(item?.final_price) : ''}</td>
                    <td>{item?.line ? item?.line : ''}</td>
                    <td>{item?.business_name ? item?.business_name : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* {dataEventkey && <PersonalInformationValidate dataEventkey={dataEventkey} />} */}
        </div>
        <div className='row mt-6'>
          <div>
            <h2 className='title-form fw-bold mb-5'>Resumen de pedido</h2>
          </div>
          <div className='col-md-6 col-12 mt-6'>
            <div className='card-total p-6'>
              <div className='d-flex align-items-start h-50px'>
                <div className="">
                  <span className='col text-start title-form fw-bold fs-3'>Monto total</span>
                  <p style={{ marginTop: "-2px" }} className=' col text-start title-form fs-4'>(Incluye IGV)</p>
                </div>
                <h2 className='col text-end text-primary mt-1 fw-medium fs-3'> {formatted(dataJsonList?.total)}</h2>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12 mt-6'>
            <div className='card-total  p-6'>
              <div className='row'>
                <h5 className='col text-start subtitle-form fs-6 fw-medium'>Línea de crédito</h5>
                <div className='col text-end subtitle-form-total fw-light'>
                  {formatted(dataJsonList?.credit)}
                </div>
              </div>
              {dataJsonList?.credit >= dataJsonList?.total ? (
                <div className=''>
                  <div className="d-flex align-items-center  ">
                    <div className="">
                      <IconCheckCircle width={18} className='me-2 ml-5' />
                    </div>
                    <h6 className='fs-7 mt-2 fw-medium text-primary'>
                      Cuenta con línea de crédito disponible
                    </h6>
                  </div>
                </div>
              ) : (
                <div className=''>
                  <div className="d-flex align-items-end">
                    <div className="">
                      <IconAlertCircle width={18} className='me-2' />
                    </div>
                    <h6 className='fs-7 fw-medium tw-orange-400'>
                      No cuenta con línea de crédito disponible, pero igualmente puede realizar el pedido.
                    </h6>
                  </div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='content-footer p-10'>
        <div className='d-flex justify-content-center mt-10 mb-10'>
          <button
            type='submit'
            className='btn btn-outline btn-outline-primary me-2  d-flex align-items-center '
          >
            {' '}
            <div
              onClick={() => {
                navigateWishlist()
              }}
              className='d-flex gap-2 align-items-center justify-content-center'
            >
              <ArrowBack /> <span className='indicator-label'>Regresar al pre-pedido</span>
            </div>
          </button>
          <button
            type='submit'
            className='btn btn-primary me-2  d-flex align-items-center '
            onClick={() => setShowModalAlert(true)}
          >
            {' '}
            <div className='d-flex gap-2 align-items-center justify-content-center'>
              <IconCalculator className='me-2' stroke='#fff' />
              <span className='indicator-label'>Generar pedido</span>
            </div>
          </button>
        </div>
      </div>
      <ModalConfirmation
        title='¿Estás seguro que deseas generar el pedido?'
        textButton='Sí, generar'
        show={showModalAlert}
        onHide={() => setShowModalAlert(false)}
        onClick={() => {
          handleConfirmation()
        }}
      />

      <ModalError
        title='¡Error!'
        description={messageError}
        show={showModalError}
        onHide={() => setShowModalError(false)}
      />
    </div>
  )
}
