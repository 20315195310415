import {memo} from 'react'
import {EventBannerForm} from '../../interfaces/event/eventBanner.interface'
import FormElemenWrapper from '../formElemenWrapper/FormElemenWrapper'
import InputUploadFiles from '../../../_metronic/layout/components/input/InputUploadFiles'
import {generalStatesList} from '../../enums/states.enums'
import {FileUploaded} from '../../interfaces/managementFile/managementFileCreate'

/**
 * Represents the props for the Form component.
 */
interface FormProps {
  /**
   * The element to be displayed in the form.
   */
  element: EventBannerForm;

  /**
   * Indicates whether the form is disabled or not.
   */
  formDisabled: boolean;

  /**
   * The callback function to be called when a change occurs in the form.
   * @param code - The code of the form.
   * @param field - The field that has changed.
   * @param value - The new value of the field.
   */
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void;
}
interface FormProps {
  element: EventBannerForm
  formDisabled: boolean
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void
}

/**
 * Renders a form for a banner.
 * The BannerForm component is a functional component.
 * Is the base component for the banner form that will be used in the DndForm.
 * @param element - The banner element.
 * @param formDisabled - Indicates if the form is disabled.
 * @param onChange - The function to handle form field changes.
 */
const BannerForm: React.FC<FormProps> = memo(({element, formDisabled, onChange}: FormProps) => {
  // The element is destructured to get the values of the banner.
  const {code, content, management_file_public, url, status} = element
  return (
    <>
      <div className='row p-4'>
        <FormElemenWrapper label=''>
          <InputUploadFiles
            required
            id={`image-${code}`}
            formDisabled={formDisabled}
            file={management_file_public ? management_file_public[0] : null}
            handleFunction={(value: FileUploaded | null) =>
              onChange(code, 'management_file_public', value ? [value] : [])
            }
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Texto descriptivo' required>
          <input
            id={`content-${code}`}
            className='form-control input-document'
            type='text'
            value={content || ''}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'content', e.target.value)}
          />
        </FormElemenWrapper>

        <FormElemenWrapper label='URL' inputClass='fv-row col-12 mb-xl-6 mb-sm-0 m-md-0'>
          <input
            id={`url-${code}`}
            className='form-control input-document'
            type='text'
            value={url || ''}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'url', e.target.value)}
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Estado'>
          <select
            id={`status-${code}`}
            className='form-select'
            value={status}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'status', e.target.value)}
          >
            {generalStatesList.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
        </FormElemenWrapper>
      </div>
    </>
  )
})

export default BannerForm
