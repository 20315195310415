import * as React from 'react'
import {SVGProps} from 'react'
const IconDoubleArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.1112 14.3889L3.77783 14.3889M3.77783 14.3889L6.88894 17.5M3.77783 14.3889L6.88894 11.2778M6.88894 6.61111L16.2223 6.61111M16.2223 6.61111L13.1112 3.5M16.2223 6.61111L13.1112 9.72222'
      stroke={'#00A160'}
      strokeWidth={1.67}
    />
  </svg>
)
export default IconDoubleArrow
