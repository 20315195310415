import React from 'react'
import {Modal} from 'react-bootstrap'

interface IProps {
  show: boolean
  onClose: () => void
}
const ModalLoader = ({show, onClose}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <span
                className='spinner-border border-5 text-primary spinner-border align-middle ms-2'
                style={{
                  width: '5rem',
                  height: '5rem',
                }}
              ></span>
              <h4 className='fs-2 px-5 mb-5 text-center text-neutral-800 fw-medium mt-8'>
                Un momento, por favor.
              </h4>

              <p className='tw-neutral-600 fw-light text-center mt-1 px-10'>
                Estamos cargando la información que solicitaste.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalLoader
