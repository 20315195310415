import * as React from 'react'
import {SVGProps} from 'react'

const IconArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width={20} 
    height={20} 
    viewBox='0 0 20 20' 
    fill='none' 
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    >
    <path
      d='M13.8889 6.88867L17 9.99978M17 9.99978L13.8889 13.1109M17 9.99978L3 9.99978'
      stroke={props.color ? props.color : "#00A160"}
      strokeWidth={1.67}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default IconArrow
