import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useStateCallback } from './useStateCallback'
import { QueryOptions, useQuery } from 'react-query'
import { PaginationItem } from '../components/table/PaginationItem'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../_metronic/helpers'

interface IProps {
  initPage: number
  initLimit: number
  pageSizes: number[]
  search?: string
  pageSizeBackground?: string
  paginatorOptions: PaginatorOptions
  queryOptions?: QueryOptions
  filters?: any
  extra?: any
}

interface PaginatorOptions {
  queryKey: string[]
  fetch: any
}

export const usePaginator = ({
  initPage,
  initLimit,
  pageSizes,
  pageSizeBackground = '#f4f9ff',
  paginatorOptions,
  queryOptions,
  search,
  filters,
  extra,
}: IProps) => {
  const [page, setPage] = useStateCallback<any>(initPage)
  const [limit, setLimit] = useStateCallback(initLimit)

  useEffect(() => {
    setPage(initPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const query = useQuery<any>(
    [...paginatorOptions.queryKey, page, extra ?? search, filters],
    () => paginatorOptions.fetch(page, limit, search, filters, extra),
    {
      ...queryOptions,
      keepPreviousData: true,
      staleTime: 60000,
    }
  )

  const pages = query.data
    ? Array.from(
      Array(
        Math.ceil(
          Number(query.data?.data.result.meta.total / query.data?.data.result.meta.per_page)
        )
      ).keys()
    ).length
      ? Array.from(
        Array(
          Math.ceil(
            Number(query.data?.data.result.meta.total / query.data?.data.result.meta.per_page)
          )
        ).keys()
      )
      : [0]
    : []

  const generatePagination = (data: number[]) => {
    return data.map((page, index) => {
      return (
        <PaginationItem
          key={index}
          setPage={setPage}
          currentPage={query.data?.data.result.meta.current_page}
          page={page + 1}
        />
      )
    })
  }

  const paginationComponent = () => {
    return (
      <div className='d-flex flex-stack flex-wrap pt-10 pb-5'>
        {pages.length >= 1 && (
          <React.Fragment>
            <div className='w-75px'>
              <select
                onChange={(e) => {
                  setLimit(Number(e.target.value))
                }}
                defaultValue={limit}
                className='form-select form-select-sm select2-hidden-accessible'
              >
                {pageSizes.map((size: number, index: number) => (
                  <option
                    key={index}
                    value={size}
                    style={{ fontWeight: 'lighter' }}
                    className='pt-3 tw-neutral-800 pb-3'
                  >
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <ul className='pagination pagination-circle'>
              <li className='page-item previous'>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => !query.isPreviousData && setPage(pages.map((e) => e + 1)[0])}
                  className='page-link'
                >
                  <SVG src={toAbsoluteUrl('/media/svg/general/double-arrow-left.svg')} />
                </div>
              </li>
              <li className={clsx('page-item previous', page === 1 && 'disabled')}>
                <div
                  onClick={() => setPage((old: any) => Math.max(old - 1, 0))}
                  className='page-link'
                  style={{ cursor: 'pointer' }}
                >
                  <i className='previous'></i>
                </div>
              </li>

              {pages.length < 5 ? (
                <React.Fragment>
                  {pages.map((page: number, index: number) => (
                    <PaginationItem
                      key={index}
                      setPage={setPage}
                      currentPage={query.data?.data.result.meta.current_page}
                      page={page + 1}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {pages.slice(0, 4).includes(page - 1) && pages.length !== 5 ? (
                    <React.Fragment>
                      {generatePagination(pages.slice(0, 5))}
                      {/* <PaginationItem
                        setPage={setPage}
                        currentPage={query.data?.page}
                        page={'...'}
                      /> */}
                      {/* {generatePagination(pages.slice(pages.length - 1, pages.length))} */}
                    </React.Fragment>
                  ) : pages.slice(pages.length - 5, pages.length).includes(page - 1) &&
                    pages.length !== 5 ? (
                    <React.Fragment>
                      {/*    {generatePagination(pages.slice(0, 1))} */}
                      {/*  <PaginationItem
                        setPage={setPage}
                        currentPage={query.data?.page}
                        page={'...'}
                      /> */}
                      {generatePagination(pages.slice(pages.length - 5, pages.length))}
                    </React.Fragment>
                  ) : pages.slice(4, pages.length - 5).includes(page - 1) ? (
                    <React.Fragment>
                      {/*  {generatePagination(pages.slice(0, 1))} */}
                      {/* <PaginationItem
                        setPage={setPage}
                        currentPage={query.data?.page}
                        page={'...'}
                      /> */}
                      {generatePagination(pages.slice(page - 3, page + 2))}
                      {/*  <PaginationItem
                        setPage={setPage}
                        currentPage={query.data?.page}
                        page={'...'}
                      /> */}
                      {/* {generatePagination(pages.slice(pages.length - 1, pages.length))} */}
                    </React.Fragment>
                  ) : (
                    pages.length === 5 && (
                      <React.Fragment>{generatePagination(pages)}</React.Fragment>
                    )
                  )}
                </React.Fragment>
              )}

              <li
                className={clsx(
                  'page-item next',
                  page === pages[pages.length - 1] + 1 && 'disabled'
                )}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    !query.isPreviousData && setPage((old: any) => Math.max(old + 1, 0))
                  }
                  className='page-link'
                >
                  <i className='next'></i>
                </div>
              </li>
              <li
                className={clsx(
                  'page-item next fa-rotate-180',
                  page === pages[pages.length - 1] + 1 && 'disabled'
                )}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    !query.isPreviousData && setPage(pages.map((e) => e + 1)[pages.length - 1])
                  }
                  className='page-link'
                >
                  <SVG
                    stroke='#4b5675'
                    src={toAbsoluteUrl('/media/svg/general/double-arrow-left.svg')}
                  />
                </div>
              </li>
            </ul>
          </React.Fragment>
        )}
      </div>
    )
  }

  return { pages, page, setPage, limit, setLimit, paginationComponent, query }
}
