import * as React from "react"
import { SVGProps } from "react"
const IconPencil = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.2581 3.25968L12.7281 5.72969M11.306 2.21175C11.9881 1.52968 13.0939 1.52968 13.776 2.21175C14.4581 2.89382 14.4581 3.99968 13.776 4.68176L4.15758 14.3002H1.7124V11.8054L11.306 2.21175Z"
            stroke={props.stroke ?? "#fff"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconPencil
