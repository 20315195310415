import React, {useEffect, useState} from 'react'
import Select, {components} from 'react-select'
import {IconCheck, IconTicket} from '../../../../commons/icons'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../store/store'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import {logout} from '../../../../store/auth/authSlice'

interface IOption {
  value: number
  label: string
}

export default function SeletecEvents() {
  const {userLogin} = useSelector((state: RootState) => state.auth)
  const [options, setOptions] = useState<IOption[]>([])
  const [selectEvent, setSelectEvent] = useState<IOption | null>(null)
  const {setSelectedEventCtx, eventSelectedCtx} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const conditionalRole = userLogin?.capability_roles.some(
      (item) =>
        item.machine === 'administrator' ||
        item.machine === 'marketing' ||
        item.machine === 'commercial' ||
        item.machine === 'reception' ||
        item.machine === 'data_entry'
    )
    if (userLogin?.active_events && userLogin?.active_events.length > 0) {
      if (options.length == 0) {
        const options = userLogin?.active_events?.map((e) => {
          return {
            value: e.id,
            label: e.name,
          }
        })
        if (conditionalRole) options.unshift({value: 0, label: 'Todos los eventos'})
        setOptions(options)

        if (eventSelectedCtx !== '') {
          const index = options.findIndex((item) => item.value == eventSelectedCtx)
          if (index !== -1) setSelectEvent(options[index])
        } else setSelectEvent(options[0])
      }
    } else if (conditionalRole) {
      localStorage.setItem('eventSelectedCtx','0')
      setSelectedEventCtx(0)
      window.location.href = '/'
    }
  }, [userLogin?.active_events])

  return (
    <>
      {userLogin?.active_events && userLogin?.active_events.length > 0 ? (
        <form className='form w-100 ' noValidate id='kt_select_event_form'>
          <div className='text-left mb-10'>
            <div className='tw-neutral-500 fw-lighter fs-4'>Te damos la bienvenida</div>
            <h1 className='tw-neutral-900 fw-bolder mb-0 title '>Elige el evento</h1>
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 tw-neutral-800'>Evento</label>
            <div className='position-relative'>
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#00a16080',
                  },
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#dcdcdc',
                    overflow: 'auto',
                    height: '44px',
                    backgroundColor: 'transparent',
                  }),
                  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                    return {
                      ...styles,
                      backgroundColor: isFocused || isSelected ? '#EFEFEF' : 'white',
                      fontWeight: isSelected ? 500 : 400,
                      color: isSelected ? '#00A160' : '#464646',
                      cursor: isDisabled ? 'not-allowed' : 'default',
                      '&:active': {
                        backgroundColor: '#EFEFEF',
                      },
                    }
                  },
                }}
                placeholder=''
                onChange={(e) => {
                  if (e) setSelectEvent(e)
                }}
                isSearchable={false}
                value={selectEvent}
                options={options}
                components={{
                  Control: ({children, ...rest}) => (
                    <components.Control {...rest}>
                      <IconTicket style={{marginLeft: 15}} stroke='#00A160' /> {children}
                    </components.Control>
                  ),
                  Option: ({children, ...rest}) => (
                    <components.Option {...rest}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {children}
                        {rest.isSelected && <IconCheck />}
                      </div>
                    </components.Option>
                  ),
                }}
              />
            </div>
          </div>
          <div className='d-grid mt-5'>
            <button
              type='button'
              id='kt_select_submit'
              className='btn btn-primary'
              onClick={() => {
                //@ts-ignore
                localStorage.setItem('eventSelectedCtx', selectEvent?.value.toString())
                setSelectedEventCtx(selectEvent?.value)
                window.location.href = '/'
              }}
            >
              <div className='d-flex gap-2 align-items-center justify-content-center'>
                <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                <span className='indicator-label'>Ingresar</span>
              </div>
            </button>
          </div>
        </form>
      ) : (
        <form className='form w-100 ' noValidate id='kt_select_event_form'>
          <div className='text-left mb-10'>
            <div className='tw-neutral-500 fw-lighter fs-4'>Lo sentimos...</div>
            <h1 className='tw-neutral-900 fw-bolder mb-0 title '>No tienes eventos disponibles</h1>
          </div>

          <div className='d-grid mt-5'>
            <button
              type='button'
              id='kt_select_submit'
              className='btn btn-primary'
              onClick={() => {
                dispatch(logout())
                navigate('/auth')
              }}
            >
              <div className='d-flex gap-2 align-items-center justify-content-center'>
                <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                <span className='indicator-label'>Cerrar sesión</span>
              </div>
            </button>
          </div>
        </form>
      )}
    </>
  )
}
