import * as React from 'react'
import {SVGProps} from 'react'
const IconSearch2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={46} height={46} viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9749 10.9234C14.529 10.9234 10.9249 14.5275 10.9249 18.9734C10.9249 23.4193 14.529 27.0234 18.9749 27.0234C23.4208 27.0234 27.0249 23.4193 27.0249 18.9734C27.0249 14.5275 23.4208 10.9234 18.9749 10.9234ZM6.8999 18.9734C6.8999 12.3046 12.3061 6.89844 18.9749 6.89844C25.6437 6.89844 31.0499 12.3046 31.0499 18.9734C31.0499 21.5813 30.2232 23.9961 28.8175 25.97L38.5105 35.6629C39.2964 36.4488 39.2964 37.7231 38.5105 38.509C37.7245 39.2949 36.4503 39.2949 35.6643 38.509L25.9714 28.8161C23.9975 30.2217 21.5828 31.0484 18.9749 31.0484C12.3061 31.0484 6.8999 25.6423 6.8999 18.9734Z'
      fill={props.fill ?? "#00A160"}
    />
  </svg>
)
export default IconSearch2
