/**
 * A simple date picker component.
 * @param props - The props for the DatePickerSimpleToModal component.
 * @component
 * @example
 * ```tsx
 * import { DatePickerSimpleToModal } from './DatePickerSimpleToModal';
 *
 * const MyComponent = () => {
 *   const handleChange = (value: string) => {
 *     console.log(value);
 *   };
 *
 *   return (
 *     <DatePickerSimpleToModal
 *       id="myDatePicker"
 *       formDisabled={false}
 *       dateValue="2022-01-01"
 *       handleChange={handleChange}
 *       minDate="2022-01-01"
 *       maxDate="2022-12-31"
 *     />
 *   );
 * };
 * ```
 */
import {format, parseISO} from 'date-fns'
import {useEffect, useRef, useState} from 'react'
import {Calendar} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import clsx from 'clsx'
import IconCalendar from '../../icons/IconCalendar'
import { Placement } from 'react-bootstrap/esm/types'

/**
 * Represents the props for the DatePickerSimpleToModal component.
 */
interface IProps {
  /**
   * The unique identifier for the DatePickerSimpleToModal component.
   */
  id: string

  /**
   * Specifies whether the form is disabled or not.
   */
  formDisabled?: boolean

  /**
   * The value of the date.
   */
  dateValue?: string

  /**
   * The minimum date allowed.
   */
  minDate?: string

  /**
   * The maximum date allowed.
   */
  maxDate?: string

  /**
   * The placement of the calendar.
   */
  placement?: Placement

  /**
   * The callback function to handle the change event.
   * @param value - The new value of the date.
   */
  handleChange?: (value: string) => void
}
interface IProps {
  id: string
  formDisabled?: boolean
  dateValue?: string
  minDate?: string
  maxDate?: string
  placement?: Placement
  handleChange?: (value: string) => void
}

export const DatePickerSimpleToModal = (props: IProps) => {
  const {handleChange, dateValue, formDisabled, id, minDate, maxDate, placement = "bottom"} = props
  // The dateValue is parsed to an ISO date.
  const fechaISO = dateValue && parseISO(dateValue)
  // The date and setDate are initialized with the fechaISO or a new Date.
  const [date, setDate] = useState(fechaISO ? fechaISO : null)
  // The showDatePicker is initialized with false.
  const [showDatePicker, setShowDatePicker] = useState(false)
  // The buttonRef is initialized with null.
    const buttonRef = useRef<HTMLDivElement>(null);
  // The handleDateChange function is defined.
  const handleDateChange = (date) => {
    const dateFormat = format(date, 'yyyy-MM-dd')
    if (handleChange) handleChange(dateFormat)
    setDate(date)
  }
  // The handleInputClick function is defined.
  const handleInputClick = () => {
    setShowDatePicker(!showDatePicker)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (!buttonRef.current?.contains(event.target as Node)) {
      setShowDatePicker(false);
    }
  };

  const getDateToLimit = (date: string) => {
    return parseISO(date)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='row' ref={buttonRef}>
      <div className='position-relative'>
        <input
          id={id}
          type='text'
          className='form-control'
          value={date ? format(date, 'yyyy-MM-dd') : ''}
          onClick={handleInputClick}
          readOnly
          disabled={formDisabled}
        />
        <div
          style={{
            border: 'none',
            right: '0px',
            top: '-1px',
            position: 'absolute',
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
          className={clsx('p-4 ', formDisabled ? 'bg-gray-400' : 'bg-primary')}
          onClick={handleInputClick}
        >
          <IconCalendar />
        </div>
        {showDatePicker && <Calendar
          className={`position-absolute ${placement === "bottom" ? "bottom-100" : "top-100"}`}
          date={date}
          onChange={handleDateChange}
          locale={locales.es}
          showDateDisplay={false}
          minDate={minDate ? getDateToLimit(minDate) : undefined}
          maxDate={maxDate ? getDateToLimit(maxDate) : undefined}
        />}
      </div>
    </div>
  )
}
