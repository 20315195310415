import * as React from "react"
import { SVGProps } from "react"
const Trash = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={12}
        height={14}
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.3555 3.88928L9.81588 11.4446C9.76937 12.0958 9.22749 12.6004 8.5746 12.6004H3.42539C2.7725 12.6004 2.23062 12.0958 2.18411 11.4446L1.64444 3.88928M4.75555 6.37817V10.1115M7.24444 6.37817V10.1115M7.86666 3.88928V2.02261C7.86666 1.67897 7.58808 1.40039 7.24444 1.40039H4.75555C4.41191 1.40039 4.13333 1.67897 4.13333 2.02261V3.88928M1.02222 3.88928H10.9778"
            stroke="#00A160"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default Trash