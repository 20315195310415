/**
 * Component for displaying and managing assigned users for an event.
 * @component
 * @example
 * return (
 *   <AssignedUsers />
 * )
 */
import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import FormElemenWrapper from "../../../../../../commons/components/formElemenWrapper/FormElemenWrapper";
import SVG from 'react-inlinesvg';
import userPersonService from "../../../../../../services/userPerson";
import { useParams } from "react-router-dom";
import { usePaginator } from "../../../../../../commons/hooks/usePaginator";
import { TableListUser } from "../tables/TableListUser";

const pageSizes = [10, 20, 50];

const AssignedUsers = () => {
  // Get the event id from the URL
  const params = useParams();
  const { id } = params;
  // State variables
  const [search, setSearch] = useState<string>('');
  const [searchDebounced, setSearchDebounced] = useState<string>();
  const [notResult, setNotResult] = useState(false);
  // Paginator
  const {
    limit,
    setPage,
    paginationComponent,
    query: { data: users, refetch },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(1)!,
    pageSizes,
    search: searchDebounced,
    filters: {content_event_id: id},
    paginatorOptions: {
      queryKey: ['usersList'],
      fetch: userPersonService.list,
    },
  });
  // Fetch data
  useEffect(() => {
    // Reset the page to 1
    setPage(1);
    // Fetch the data
    const fetchData = async () => {
      const response = await refetch()
      setNotResult(response.data.data.result.data.length > 0 ? false : true)
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, refetch, searchDebounced]);

  return (
    <div className="row">
      <FormElemenWrapper label="Usuarios" inputClass="fv-row col-12 mb-xl-6 mb-sm-0 m-md-0">
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1'>
            <SVG
              src={toAbsoluteUrl('/media/svg/input/search.svg')}
              className='fs-1 position-absolute ms-4'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-14'
              placeholder='Buscar Usuario'
              value={search}
              onKeyDown={(e) =>
                 e.key === 'Enter' && setSearchDebounced(search)
                }
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
      </FormElemenWrapper>
      <div className='col-12 mt-6'>
        <div className='overflow-auto table-list'>
          <TableListUser
            data={users?.data.result.data}
            isNotResult={notResult}
          />
        </div>
      </div>
      {notResult ? null : paginationComponent()}
    </div>
  )
}

export default AssignedUsers