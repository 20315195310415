import useAuth from '../../app/modules/auth/hooks/useAuth'
import {IUserPersonCreate} from '../../commons/interfaces/userPerson/userPersonCreate'
import {IUserPersonList} from '../../commons/interfaces/userPerson/userPersonList'
import {IUserPersonShow} from '../../commons/interfaces/userPerson/userPersonShow'
import {IUserPersonState} from '../../commons/interfaces/userPerson/userPersonState'
import {IUserPersonUpdate} from '../../commons/interfaces/userPerson/userPersonUpdate'
import api from '../../config/axios'

const userPersonService = {
  list: async (page: number | string, limit: number | string, search: string, filters = {} as any) => {
    try {
      const response = await api.get<IUserPersonList>(
        `/api/user/person/list?page=${page}&limit=${limit}&search=${search ?? ''}&document_type=${filters.document_type ?? ''}&document_number=${filters.document_number ?? ''}&firstname=${filters.firstname ?? ''}&lastname=${filters.lastname ?? ''}&email=${filters.email ?? ''}&username=${filters.username ?? ''}&phone=${filters.phone ?? ''}&status=${filters.status ?? ''}&entity_supplier_id=${filters.entity_supplier_id ?? ''}&capability_roles=${filters.capability_roles ?? ''}&event_code=${filters.event_code ?? ''}&content_event_id=${filters.content_event_id ?? ''}`
      )
      return response
    } catch (error) {
      throw error
    }
  },
  create: async (data) => {
    try {
      const response = await api.post<IUserPersonCreate>('/api/user/person', data)
      return response
    } catch (error) {
      throw error
    }
  },
  update: async (data) => {
    try {
      const response = await api.put<IUserPersonUpdate>('/api/user/person', data)
      return response
    } catch (error) {
      throw error
    }
  },
  state: async (data: {id: number; status: number}) => {
    try {
      const response = await api.patch<IUserPersonState>('/api/user/person/state', data)
      return response
    } catch (error) {
      throw error
    }
  },
  show: async (id: number) => {
    try {
      const response = await api.get<IUserPersonShow>(`/api/user/person/show?id=${id}`)
      return response
    } catch (error) {
      throw error
    }
  },
  template: async () => {
    try {
      const response = await api.get(`/api/user/person/template`, {
        responseType: 'blob',
      })
      return response
    } catch (error) {
      throw error;
    }
  },
  import: async (data: FormData) => {
    try {
      const response = await api.post(`/api/user/person/import`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      return response
    } catch (error) {
      throw error;
    }
  }
}

export default userPersonService
