import {format} from 'date-fns';
import {es} from 'date-fns/locale';

// fuction to capitalize the first letter of a string
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Fuction to get the date label
export const getDateLabel = (date) => {
  // Format the date in Spanish locale with the day of the week, day, month, and year
  const formattedDate = format(date, 'EEEE, d MMMM yyyy', {locale: es});
  // Capitalize the formatted date for better presentation
  return capitalize(formattedDate);
};

export const getDeviceInfo = () => {
  // Saves in the userAgent variable the string containing information about the browser and operating system used to access the website
  const userAgent = navigator.userAgent;
  // Declare the browser and operating system variables to save the respective information according to the string obtained from the userAgent
  let browser, os;

  // Detect the browser based on the userAgent
  if (userAgent.includes('Firefox')) {
    browser = 'Firefox'
  } else if (userAgent.includes('Chrome')) {
    browser = 'Chrome'
  } else if (userAgent.includes('Safari')) {
    browser = 'Safari'
  } else if (userAgent.includes('Opera')) {
    browser = 'Opera'
  } else if (userAgent.includes('Edge')) {
    browser = 'Edge'
  } else {
    browser = 'Unknown'
  }

  // Detect the operating system based on the userAgent
  if (userAgent.includes('Windows')) {
    os = 'Windows'
  } else if (userAgent.includes('Macintosh')) {
    os = 'MacOS'
  } else if (userAgent.includes('Linux')) {
    os = 'Linux'
  } else if (userAgent.includes('Android')) {
    os = 'Android'
  } else if (userAgent.includes('iOS')) {
    os = 'iOS'
  } else {
    os = 'Unknown'
  }

  // Return an object with the detected browser, operating system, and user agent
  return {
    device_browser: browser,
    device_os: os,
    device_useragent: userAgent,
  }
};

export const debounce = <T extends (...args: any[]) => void>(func: T, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  }
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
