import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import useAuth from '../hooks/useAuth'
import { ModalExpired } from './ModalExpired'
import ReCAPTCHA from "react-google-recaptcha";

export function Login() {
  const TEST_SITE_KEY = process.env.REACT_APP_SITE_KEY || "6LczrMsiAAAAAE47XMqfJLQ5vMXhSaDtz9G__ewA";
  const [showPassword, setShowPassword] = useState(true)
  const [isFocused, setIsFocused] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const {
    mutations: { loginMutation },
    forms: { loginForm },
  } = useAuth()
  const onChangeCaptcha = (value: string | null) => {
    if (value !== null) loginForm.setValue('recaptcha', value);
  }
  useEffect(() => {
    if (location.state === 'expired') setShowModal(true)
    if (captchaRef.current) captchaRef.current.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      className='form w-100 '
      onSubmit={loginForm.handleSubmit(() => loginMutation.mutate({ ...loginForm.getValues() }))}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-left mb-10'>
        <div className='tw-neutral-500 fw-lighter fs-4'>Te damos la bienvenida</div>
        <h1 className='tw-neutral-900 fw-bolder mb-0 title '>Inicia sesión</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 tw-neutral-800'>Usuario</label>
        <div className='position-relative'>
          <input
            placeholder='Ingresa tu usuario'
            {...loginForm.register('username')}
            className={clsx(
              'form-control form-control-auth bg-transparent ps-10 custom-input',
              {
                'is-invalid': loginForm.formState.errors.username,
              },
              {
                'is-valid': isFocused === 'username' && !loginForm.formState.errors.username,
              }
            )}
            type='username'
            name='username'
            onFocus={() => setIsFocused('username')}
            onBlur={() => setIsFocused('')}
            autoComplete='off'
          />
          {loginForm.formState.errors.username && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger' role='alert'>
                {loginForm.formState.errors.username.message}
              </span>
            </div>
          )}
          <div style={{ top: '10px', left: '16px' }} className='position-absolute'>
            <SVG src={toAbsoluteUrl('/media/svg/login/user.svg')} />
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label tw-neutral-800 fs-6'>Contraseña</label>
        <div className='position-relative'>
          <input
            type={`${showPassword ? 'password' : 'text'}`}
            autoComplete='off'
            placeholder='Ingresa tu contraseña'
            {...loginForm.register('password')}
            onFocus={() => setIsFocused('password')}
            onBlur={() => setIsFocused('')}
            className={clsx(
              'form-control form-control-auth bg-transparent ps-10 custom-input',
              {
                'is-invalid': loginForm.formState.errors.password,
              },
              {
                'is-valid': isFocused === 'password' && !loginForm.formState.errors.password,
              }
            )}
          //onChange={handleUsernameChange}
          />
          <div style={{ top: '10px', left: '16px' }} className='position-absolute'>
            <SVG src={toAbsoluteUrl('/media/svg/login/key.svg')} />
          </div>
          <div
            onClick={() => setShowPassword(!showPassword)}
            style={{ top: '50%', right: '15px', transform: 'translateY(-50%)' }}
            className='position-absolute'
          >
            <SVG
              style={{
                cursor: 'pointer',
              }}
              src={toAbsoluteUrl('/media/svg/login/eye.svg')}
            />
          </div>
        </div>
        {loginForm.formState.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {loginForm.formState.errors.password.message}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mt-5 mb-8'>
        <div>
          {/* <div className='form-check form-check-sm form-check-custom form-check-'>
            <input
              className='form-check-input'
              type='checkbox'
              data-kt-check='true'
              data-kt-check-target='.widget-13-check'
              value='1'
            />
            <span className='ms-2 fw-light'>Recordarme</span>
          </div> */}
        </div>

        {/* begin::Link */}
        <Link to='/auth/recover-password' className='link-primary'>
          Olvidé mi contraseña
        </Link>
        {/* end::Link */}
      </div>

      <div className='content-captcha'>
        <ReCAPTCHA
          ref={captchaRef}
          size="normal"
          sitekey={TEST_SITE_KEY}
          onChange={onChangeCaptcha}
        />
      </div>
      {loginForm.formState.errors.recaptcha && (
        <div className='fv-plugins-message-container'>
          <span className='text-danger' role='alert'>
            {loginForm.formState.errors.recaptcha.message}
          </span>
        </div>
      )}
      <div className='d-grid mt-5 mb-6'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={loginMutation.isLoading}
        >
          {!loginMutation.isLoading && (
            <div className='d-flex gap-2 align-items-center justify-content-center'>
              <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
              <span className='indicator-label'>Ingresar</span>
            </div>
          )}
          {loginMutation.isLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Cargando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      {location.state === 'expired' && (
        <ModalExpired
          show={showModal}
          onHide={() => {
            navigate('/', { replace: true })
          }}
        />
      )}
    </form>
  )
}
