import {memo} from 'react'
import {EventCatalogueForm} from '../../interfaces/event/eventCatalogue.interface'
import FormElemenWrapper from '../formElemenWrapper/FormElemenWrapper'
import InputUploadFiles from '../../../_metronic/layout/components/input/InputUploadFiles'
import {FileUploaded} from '../../interfaces/managementFile/managementFileCreate'

/**
 * Represents the props for the Form component.
 */
interface FormProps {
  /**
   * The element to be displayed in the form.
   */
  element: EventCatalogueForm

  /**
   * Indicates whether the form is disabled or not.
   */
  formDisabled: boolean

  /**
   * The callback function to be called when a change occurs in the form.
   * @param code - The code of the form.
   * @param field - The field that has changed.
   * @param value - The new value of the field.
   */
  onChange: (code: string, field: string, value: string | FileUploaded[] | FileUploaded | null) => void
}
interface FormProps {
  element: EventCatalogueForm
  formDisabled: boolean
  onChange: (code: string, field: string, value: string | FileUploaded[] | FileUploaded | null) => void
}

/**
 * Renders a form for a catalogue.
 * The CatalogueForm component is a functional component.
 * Is the base component for the catalogue form that will be used in the DndForm.
 * @param element - The catalogue element.
 * @param formDisabled - Indicates if the form is disabled.
 * @param onChange - The function to handle form field changes.
 */
const CatalogueForm: React.FC<FormProps> = memo(({element, formDisabled, onChange}: FormProps) => {
  // The element is destructured to get the values of the catalogue.
  const {code, name, management_file_public, management_file_public_thumbnail} = element
  return (
    <>
      <div className='row p-4'>
        <div className='col-12'>
          <FormElemenWrapper label='Nombre' required>
            <input
              type='text'
              className='form-control'
              id={`name-${code}`}
              value={name}
              disabled={formDisabled}
              onChange={(e) => onChange(code, 'name', e.target.value)}
            />
          </FormElemenWrapper>
        </div>
        <div className='col-12'>
          <FormElemenWrapper label=''>
            <InputUploadFiles
              required
              id={`pdf-${code}`}
              formDisabled={formDisabled}
              file={management_file_public ? management_file_public[0] : null}
              handleFunction={(value: FileUploaded | null) =>
                onChange(code, 'management_file_public', value ? [value] : [])
              }
              titleForm='PDF adjunto'
              spanLabel='(max 10Mbs)'
              allowedTypes={['application/pdf']}
            />
          </FormElemenWrapper>
        </div>
        <div className='col-12'>
          <FormElemenWrapper label=''>
            <InputUploadFiles
              id={`image-${code}`}
              required
              formDisabled={formDisabled}
              file={management_file_public_thumbnail ? management_file_public_thumbnail : null}
              handleFunction={(value: FileUploaded | null) =>
                onChange(code, 'management_file_public_thumbnail', value ? value : null)
              }
            />
          </FormElemenWrapper>
        </div>
      </div>
    </>
  )
})

export default CatalogueForm
