import * as React from "react"
import { SVGProps } from "react"
const Download = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.69995 11.1498L1.69995 11.9373C1.69995 13.2421 2.75768 14.2998 4.06245 14.2998L11.9375 14.2998C13.2422 14.2998 14.3 13.2421 14.3 11.9373L14.3 11.1498M11.15 4.8498L7.99995 1.6998M7.99995 1.6998L4.84995 4.8498M7.99995 1.6998L7.99995 11.1498"
            stroke="#00A160"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default Download