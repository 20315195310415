export const orderStatesCode = {
  PENDING: 'pending',
  IN_PROCESS: 'in_process',
  COMPLETED: 'completed',
  IN_EVALUATION: 'in_evaluation',
  APPROVED: 'approved',
  OBSERVED: 'observed',
  REJECTED: 'rejected',
};

export const generalStatesCode = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const giftStatesCode = {
  PENDING: 'pending',
  REDEEMED: 'redeemed',
};

export const orderStatesListObject = {
  [orderStatesCode.PENDING]: 'Pendiente',
  [orderStatesCode.IN_PROCESS]: 'En proceso',
  [orderStatesCode.COMPLETED]: 'Completado',
  [orderStatesCode.IN_EVALUATION]: 'En evaluación',
  [orderStatesCode.APPROVED]: 'Aprobado',
  [orderStatesCode.OBSERVED]: 'Observado',
  [orderStatesCode.REJECTED]: 'Rechazado',
};

export const generalStatesListObject = {
  [generalStatesCode.ACTIVE]: 'Activo',
  [generalStatesCode.INACTIVE]: 'Inactivo',
};

export const giftStatesListObject = {
  [giftStatesCode.PENDING]: 'Pendiente',
  [giftStatesCode.REDEEMED]: 'Canjeado',
};

export const orderStatesColors = {
  [orderStatesListObject[orderStatesCode.PENDING]]: '#0A96ED',
  [orderStatesListObject[orderStatesCode.IN_PROCESS]]: '#F58220',
  [orderStatesListObject[orderStatesCode.COMPLETED]]: '#00A160',
  [orderStatesListObject[orderStatesCode.IN_EVALUATION]]: '#FFA500',
  [orderStatesListObject[orderStatesCode.APPROVED]]: '#008000',
  [orderStatesListObject[orderStatesCode.OBSERVED]]: '#FF0000',
  [orderStatesListObject[orderStatesCode.REJECTED]]: '#FF0000',
};

export const giftStatesColors = {
  [giftStatesListObject[giftStatesCode.PENDING]]: '#F59E0B',
  [giftStatesListObject[giftStatesCode.REDEEMED]]: '#00A160',
};

export const orderStatesList = [
  {
    value: orderStatesCode.PENDING,
    label: orderStatesListObject[orderStatesCode.PENDING],
  },
  {
    value: orderStatesCode.IN_PROCESS,
    label: orderStatesListObject[orderStatesCode.IN_PROCESS],
  },
  {
    value: orderStatesCode.COMPLETED,
    label: orderStatesListObject[orderStatesCode.COMPLETED],
  },
  {
    value: orderStatesCode.IN_EVALUATION,
    label: orderStatesListObject[orderStatesCode.IN_EVALUATION],
  },
  {
    value: orderStatesCode.APPROVED,
    label: orderStatesListObject[orderStatesCode.APPROVED],
  },
  {
    value: orderStatesCode.OBSERVED,
    label: orderStatesListObject[orderStatesCode.OBSERVED],
  },
  {
    value: orderStatesCode.REJECTED,
    label: orderStatesListObject[orderStatesCode.REJECTED],
  },
];

export const generalStatesList = [
  {
    value: generalStatesCode.ACTIVE,
    label: generalStatesListObject[generalStatesCode.ACTIVE],
  },
  {
    value: generalStatesCode.INACTIVE,
    label: generalStatesListObject[generalStatesCode.INACTIVE],
  },
];