
interface IProgressBar {
    bgcolor: string,
    progress: number,
    height: number
    progressText?: string
}

const ProgressBar = ({ bgcolor, progress, height, progressText }: IProgressBar) => {

    const Parentdiv = {
        height: height,
        width: '70%',
        backgroundColor: '#DCDCDC',
        borderRadius: 40,
    }

    const childDiv: any = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 40,
        textAlign: 'right'
    }

    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
    }

    return (
        <div className="d-flex align-items-center ">
            <div style={Parentdiv}>
                <div style={childDiv}>
                    <span style={progresstext}></span>
                </div>

            </div>
            <span className="text-label fw-light ps-6">{`${progress}%`}</span>
            {progressText && <span className="text-label fw-light ps-6">{progressText}</span>}
        </div>
    )
}

export default ProgressBar; 