import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'

import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { IconExit } from '../../../../../commons/icons'
import { DatePickerFilter } from '../../../../../commons/components/datePicker/DatePickerFilter'
import { useAuth } from '../../../auth'
import { useLocation } from 'react-router-dom'

export const FilterOffCanvas = (props) => {
  const { filter, setFilter, show, handleClose, handleShow, initFilter } = props;
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const arregloDocumentType = filter?.document_type?.split(',')?.map(String)
  const filterArregloDocumentType = arregloDocumentType ? arregloDocumentType?.slice(1) : []
  const { eventSelectedCtx } = useAuth()

  const [isFocused, setIsFocused] = useState<string>('')
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [documentType, setDocumentType] = useState<any>(filterArregloDocumentType)

  const handleApplyFilter = (data) => {
    console.log(data);

    const formatDocumentType = documentType.join(',')
    setFilter({
      ...data,
      document_type: formatDocumentType,
      content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx,
    })
    handleClose()
  }

  const handleResetFilter = () => {
    reset({
      document_number: '',
      firstname: '',
      content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx,
      event_code: '',
      content_event_name: '',
      start_date: '',
      end_date: '',
    })
    setFilter()
    handleClose()
    setDocumentType([])
  }

  const handleCheckboxChange = (e) => {
    const value = e.target.value
    if (e.target.checked) {
      // Agrega el valor a la lista si el checkbox se marca
      setDocumentType([...documentType, value])
    } else {
      // Elimina el valor de la lista si el checkbox se desmarca
      setDocumentType(documentType.filter((item) => item !== value))
    }
  }

  useEffect(() => {
    handleApplyFilter({
      event_code: code,
    })
    console.log(getValues());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    if (initFilter) {
      setValue('document_number', initFilter.document_number || '')
      setValue('firstname', initFilter.firstname || '')
      setValue('event_code', initFilter.event_code || '')
      setValue('content_event_name', initFilter.content_event_name || '')
      setValue('start_date', initFilter.start_date || '')
      setValue('end_date', initFilter.end_date || '')
      if (initFilter.entity_customer_document_type) {
        const documentTypes = initFilter.entity_customer_document_type?.split(',')
        setDocumentType(documentTypes)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFilter])

  return (
    <>
      <Button variant='primary' onClick={handleShow} className='me-2'>
        <IconExit />
      </Button>
      <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header className='bg-white' closeButton>
          <Offcanvas.Title>Filtros</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bg-gray-200'>
          <div className=' h-100'>
            <div className='fv-row col-12 mb-7'>
              <div className='form-label fs-6 text-dark mb-3'>Tipo de documento del cliente</div>
              <div className='d-flex align-items-center gap-5'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='DNI'
                    className='form-check-input'
                    checked={documentType?.includes('DNI')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    DNI
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='CE'
                    className='form-check-input'
                    checked={documentType?.includes('CE')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    CE
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='RUC'
                    className='form-check-input'
                    checked={documentType?.includes('RUC')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    RUC
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='PA'
                    className='form-check-input'
                    checked={documentType?.includes('PA')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    PASAPORTE
                  </label>
                </div>
              </div>
            </div>
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Número de documento</label>
              <input
                placeholder='Ingresar número de documento'
                {...register('document_number')}
                // defaultValue={result?.data?.result?.document_number}
                className={clsx(
                  'form-control custom-input',
                  /*  { 'bg-transparent': !formDisabled }, */
                  { 'is-invalid': errors.document_number },
                  {
                    'is-valid': isFocused === 'document_number' && !errors.document_number,
                  }
                )}
                onFocus={() => setIsFocused('document_number')}
                type='text'
                autoComplete='off'
              />
              {/*   {errors.document_number && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.document_number.message as string}</span>
                            </div>
                        )} */}
            </div>

            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Nombre del cliente</label>
              <input
                placeholder='Ingresar nombre'
                {...register('firstname')}
                // defaultValue={result?.data?.result?.document_number}
                className={clsx(
                  'form-control custom-input',
                  /*  { 'bg-transparent': !formDisabled }, */
                  { 'is-invalid': errors.firstname },
                  {
                    'is-valid': isFocused === 'firstname' && !errors.firstname,
                  }
                )}
                onFocus={() => setIsFocused('firstname')}
                type='text'
                autoComplete='off'
              />
            </div>

            {eventSelectedCtx == 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Fecha del evento</label>
                <DatePickerFilter
                  end_date={filter?.end_date}
                  start_date={filter?.start_date}
                  visible={false}
                />
              </div>
            )}

            {eventSelectedCtx == 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Código del evento</label>
                <input
                  placeholder='Ingresar código del evento'
                  {...register('event_code')}
                  // defaultValue={result?.data?.result?.document_number}
                  className={clsx(
                    'form-control custom-input',
                    /*  { 'bg-transparent': !formDisabled }, */
                    { 'is-invalid': errors.event_code },
                    {
                      'is-valid': isFocused === 'event_code' && !errors.event_code,
                    }
                  )}
                  onFocus={() => setIsFocused('event_code')}
                  type='text'
                  autoComplete='off'
                />
              </div>
            )}
            {/* {eventSelectedCtx == 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Código del evento</label>
                <input
                  placeholder='Ingresar código del evento'
                  {...register('content_event_id')}
                  // defaultValue={result?.data?.result?.document_number}
                  className={clsx(
                    'form-control custom-input',
               
                    { 'is-invalid': errors.content_event_id },
                    {
                      'is-valid': isFocused === 'content_event_id' && !errors.content_event_id,
                    }
                  )}
                  onFocus={() => setIsFocused('content_event_id')}
                  type='text'
                  autoComplete='off'
                />
              </div>
            )} */}

            {eventSelectedCtx == 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Nombre del evento</label>
                <input
                  placeholder='Ingresar nombre del evento'
                  {...register('content_event_name')}
                  // defaultValue={result?.data?.result?.document_number}
                  className={clsx(
                    'form-control custom-input',
                    /*  { 'bg-transparent': !formDisabled }, */
                    { 'is-invalid': errors.content_event_name },
                    {
                      'is-valid': isFocused === 'content_event_name' && !errors.content_event_name,
                    }
                  )}
                  onFocus={() => setIsFocused('content_event_name')}
                  type='text'
                  autoComplete='off'
                />
              </div>
            )}

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                padding: '20px',
                background: 'white',
              }}
            >
              {/* Contenido del footer */}
              <div className='d-flex '>
                <div className=' flex-grow-1 '></div>
                <div className=''>
                  <button
                    type='button'
                    className='btn btn-outline btn-outline-primary btn-sm  me-4'
                    onClick={handleResetFilter}
                  >
                    Limpiar
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary  btn-sm'
                    onClick={handleSubmit(handleApplyFilter)}
                  >
                    Aplicar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
