import {SVGProps} from 'react'
const EventBlockInformationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M23.1667 24.3327H6.83333C5.54467 24.3327 4.5 23.288 4.5 21.9994L4.5 7.99935C4.5 6.71068 5.54467 5.66602 6.83333 5.66602L18.5 5.66602C19.7887 5.66602 20.8333 6.71068 20.8333 7.99935V9.16602M23.1667 24.3327C21.878 24.3327 20.8333 23.288 20.8333 21.9994L20.8333 9.16602M23.1667 24.3327C24.4553 24.3327 25.5 23.288 25.5 21.9994V11.4993C25.5 10.2107 24.4553 9.16602 23.1667 9.16602L20.8333 9.16602M16.1667 5.66602L11.5 5.66602M9.16667 19.666H16.1667M9.16667 10.3327H16.1667V14.9994H9.16667V10.3327Z'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default EventBlockInformationIcon
