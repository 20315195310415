// Code for uploading and downloading files from Azure Blob Storage
import {BlobServiceClient, ContainerClient} from '@azure/storage-blob'

// Get the environment variables
const sasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN
const storageAccountName = process.env.REACT_APP_STORAGE_RESOURCE_NAME

// Interface for the BlobService
interface IBlobService {
  upload(containerName: string, file: any, name: string): Promise<void>
  downloadFileFromBlob(blobFile: string, fileName: string): Promise<void>
}

// BlobService object
const BlobService: IBlobService = {
  // Uploads a file to the Azure Blob Storage
  async upload(containerName: string, file: any, name: string) {
    try {
      // Create a new BlobServiceClient
      const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
      )
      // Get the container client
      const containerClient: ContainerClient = blobService.getContainerClient(containerName)
      // Get the block blob client
      const blockBlobClient = containerClient.getBlockBlobClient(name)
      // Upload the file
      await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      })
      // Log the upload
      console.log(`Blob "${name}" uploaded to container "${containerName}".`)
    } catch (err) {
      console.log('error', err)
      throw err
    }
  },
  // Downloads a file from the Azure Blob Storage
  async downloadFileFromBlob(blobFile: string, fileName: string) {
    try {
      // Check if the blob file exists
      if (!blobFile) return
      // Fetch the blob file
      const response = await fetch(blobFile, {
        method: 'GET',
        headers: {
          'Content-Type': 'blob',
        },
      })
      // Create a URL for the blob file
      const url = URL.createObjectURL(await response.blob())
      // Create a link element and download the file
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.target = '_blank'
      link.click()
    } catch (error) {
      console.log('[ downloadFileFromBlob ]', error)
    }
  },
}

export default BlobService
