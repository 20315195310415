import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { TaxonomyCategory } from '../../../../../commons/interfaces/auth/login'
import { useFormContext } from 'react-hook-form'
import { IconExit } from '../../../../../commons/icons'
import { useStateCallback } from '../../../../../commons/hooks/useStateCallback'

interface IProps {
  data: TaxonomyCategory[]
  suppliers?: any
  filterCategories?: any
}

export const Category = ({ data, suppliers, filterCategories }: IProps) => {
  const arreglo = filterCategories?.split(',').map(Number);

  const dataIdViewSuppliers = suppliers ? suppliers?.map((item) => item.id) : filterCategories ? arreglo : []
  const dataViewSuppliers = suppliers ? suppliers : []

  const {
    setValue,
  } = useFormContext()
  const [filterText, setFilterText] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useStateCallback<any>(dataIdViewSuppliers)
  const [selectedItemsList, setSelectedItemsList] = useStateCallback<any>(dataViewSuppliers)

  /*   useEffect(() => {
      // Verificar si todos los elementos han sido eliminados
      if (selectedItems.length === 0) {
        setSelectAll(false)
      }
    }, [selectedItems]) */

  const handleFilterChange = (e) => {
    setFilterText(e.target.value)
  }

  const handleItemToggle = (id, name) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(
        selectedItems.filter((item) => item !== id),
        (newRoles) => {
          const newRolesSplit = newRoles?.join(',')
          setValue('taxonomy_category_id', newRolesSplit)
        }
      )
      // setSelectedItemsList(selectedItemsList.filter((item) => item.id !== id))
    } else {
      setSelectedItems([...selectedItems, id], (newRoles) => {
        const newRolesSplit = newRoles?.join(',')
        setValue('taxonomy_category_id', newRolesSplit)
      })
      // setSelectedItemsList([...selectedItemsList, { id, business_name: name, ruc }])
    }
  }

  /*   const handleSelectAll = () => {
      if (!selectAll) {
        const allIds = data.map((item) => item.id)
        setSelectedItems(allIds, (newRoles) => {
          setValue('entity_suppliers', newRoles)
        })
        const allItems = data.map((item) => ({
          id: item.id,
          business_name: item.business_name,
          ruc: item.ruc,
        }))
        setSelectedItemsList(allItems)
      } else {
        setSelectedItems([], (newRoles) => {
          setValue('entity_suppliers', newRoles)
        })
        setSelectedItemsList([])
      }
      setSelectAll(!selectAll)
    }
   */

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  return (
    <>
      <div className='fv-row col-12'>
        <label className='form-label fs-6 text-dark'>Categoría del producto</label>
        <input

          type='text'
          placeholder='Buscar categoría'
          className={clsx('form-control')}
          value={filterText}
          onChange={handleFilterChange}
        />
        <div className='card card-stretch card-bordered bg-transparent p-4 mt-4 border-gray-300  h-250px overflow-auto'>
          {/*      <div className='form-check mb-2 '>
            <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
              <input

                type='checkbox'
                checked={selectAll}
                className={clsx('form-check-input')}
                onChange={handleSelectAll}
              />
              Seleccionar todo
            </label>
          </div> */}
          {filteredData.map((item) => (
            <div className='form-check mb-2' key={item.id}>
              <input
                type='checkbox'
                className='form-check-input'
                checked={selectedItems.includes(item.id)}
                onChange={() => handleItemToggle(item.id, item.name)}
                value={item.id}
              />
              <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                {item.name}
              </label>
            </div>
          ))}
        </div>
        {/*         {errors.entity_suppliers && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {errors.entity_suppliers.message as string}
            </span>
          </div>
        )} */}
      </div>
    </>
  )
}
