/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Navbar} from './Navbar'
import {
  IconConfig,
  IconGift,
  IconHeart,
  IconShoppingBag,
  IconTicket,
  IconUsers,
} from '../../../../commons/icons'

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  const segments = pathname.split('/')

  const textAfterFirstSlash = segments.length > 1 ? segments[1] : ''

  const iconDictionary = {
    pedidos: <IconHeart stroke='#989898' />,
    configuration: <IconConfig />,
    eventos: <IconTicket />,
    surtido: <IconShoppingBag />,
    clientes: <IconUsers stroke='#989898' />,
    wishlist: <IconHeart stroke='#989898' />,
    regalos: <IconGift stroke='#989898' />,
  }

  const icon = iconDictionary[textAfterFirstSlash] || null

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container  flex-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTIcon iconName='abstract-14' className=' fs-1' />
              </div>
              {/*  <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/dashboard' className='d-lg-none'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small.svg')}
                    className='h-30px'
                  />
                </Link>
              </div> */}
              <div className='d-flex gap-2 align-items-center '>
                {icon}
                {/* <img src={toAbsoluteUrl(selectedItem?.icon!)} className='bg-black color-primary' style={{ fill: '#00A160' }} alt="" /> */}
                <h5 style={{color: '#989898'}} className='text-capitalize fw-normal  my-0 fs-6'>
                  {textAfterFirstSlash === 'configuration' ? 'configuración' : textAfterFirstSlash}
                </h5>
              </div>
            </div>
          </>
        )}

        {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div className='d-none d-lg-flex gap-2 align-items-center '>
          {icon}
          {/* <img src={toAbsoluteUrl(selectedItem?.icon!)} className='bg-black color-primary' style={{ fill: '#00A160' }} alt="" /> */}
          <h5 style={{color: '#989898'}} className='text-capitalize fw-normal  my-0 fs-6'>
            {textAfterFirstSlash === 'configuration'
              ? 'configuración'
              : textAfterFirstSlash === 'wishlist'
              ? 'Pre-pedido'
              : textAfterFirstSlash}
          </h5>
        </div>

        {/*     <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div> */}
        <Navbar />
      </div>
    </div>
  )
}
