import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'

import { MainTitle } from '../../../../commons/components/Title/MainTitle'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import IconPencil from '../../../../commons/icons/IconPencil'
import userPersonService from '../../../../services/userPerson'
import { useMutation, useQuery, useQueryClient } from 'react-query'
/* import { GeneralInformationUpdate } from './components/tabs/GeneralInformationUpdate'
import { AdditionalInformationUpdate } from './components/tabs/AdditionalInformationUpdate' */
import { format, parseISO } from 'date-fns'


import { IconArrowDown, IconCancelCircle, IconHeart, IconShoppingBag, IconUsers } from '../../../../commons/icons'
import { ModalAlert } from '../../../../commons/components/modals/ModalAlert'
import { ModalConfirmation } from '../../../../commons/components/modals/ModalConfirmation'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import wishlistService from '../../../../services/whislist'
import eventService from '../../../../services/event'
import { GeneralData } from '../components/tabs/GeneralData'
import { WishlistProducts } from '../components/tabs/WishlistProducts'
import { datajson } from '../../../../commons/interfaces/wishList/wishListList'

/* import { GeneralDataUpdate } from '../components/tabCreate/GeneralDataUpdate'
import { RolesProvidersUpdate } from '../components/tabCreate/RolesProvidersUpdate' */

const updateEventSchema = Yup.object().shape({

    name: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('Nombre del evento es requerido'),
    // .matches(/^[ A-Za-záéíóúÁÉÍÓÚüÜñÑ]+$/, 'El name solo debe contener letras'),
    code: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('Codigo del evento es requerido')
        .test('no-espacios', 'El código no debe contener espacios en blanco', (value) => {
            if (value && /\s/.test(value)) {
                return false;
            }
            return true;
        }),
    code_as400: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    created_at: Yup.string(),
    event_society_id: Yup.number()
        .required('Sociedad del evento es requerido'),
    status: Yup.string()
        .required('Estado del evento es requerido'),
})


export const ViewEditWishList = () => {

    const params = useParams()
    const { id } = params;

    const queryClient = useQueryClient();
    const navigation = useNavigate()


    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [showModalCancel, setShowModalCancel] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [showModalAlert, setShowModalAlert] = useState(false)

    const [date, setDate] = useState('')
    const { userLogin } = useSelector((state: RootState) => state.auth)

    const { data: wishlist, isLoading, isError, error: errorQuery, isFetching } = useQuery(['wishlistView', id],
        () => wishlistService.show(+id!)
    );


    const [formDisabled, setFormDisabled] = useState(true)

    const methods = useForm({
        /*     resolver: yupResolver(updateEventSchema), */
        defaultValues: {
            document_type: wishlist?.data?.result?.document_type || '',
            document_number: wishlist?.data?.result?.document_number || '',
            firstname: wishlist?.data?.result?.firstname || '',
            lastname: wishlist?.data?.result?.lastname || '',
            name: wishlist?.data?.result?.content_event.name || '',
            start_date: wishlist?.data?.result?.content_event.start_date || '',
            end_date: wishlist?.data?.result?.content_event.end_date || '',
            status: wishlist?.data?.result?.content_event.status
            /*            status: wishlist?.data?.result?.status?.toString() || '', */
        }
    })
    const dataJson = datajson.result

    const { handleSubmit, reset, formState, setValue } = methods;

    useEffect(() => {
        if (!isLoading && wishlist) {
            // Establece los defaultValues con los datos recuperados
            /*         Object.keys(wishlist.data.result).forEach((e) => {
                        setValue(e, wishlist.data.result[e])
                    
                    }) */
            setValue('document_type', wishlist.data.result.document_type);
            setValue('document_number', wishlist.data.result.document_number);
            setValue('firstname', wishlist.data.result.firstname || '');
            setValue('lastname', wishlist.data.result.lastname);
            setValue('name', wishlist.data.result.content_event.name);
            setValue('start_date', wishlist.data.result.content_event.start_date);
            setValue('end_date', wishlist.data.result.content_event.end_date);
            setValue('status', wishlist.data.result.content_event.status);

            setDate(wishlist.data.result.content_event.start_date + '-' + wishlist.data.result.content_event.end_date)
        }
    }, [wishlist, isLoading]);

    /*  const { clearErrors, control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields, isSubmitting, isValid }, setError } = useForm(); */
    const userMutation = useMutation({
        mutationFn: eventService.update,
        onSuccess: () => {
            console.log("Se realizo los cambios");
            setShowModalSuccess(false)
            // Invalida la caché de la consulta de usuarios para forzar una recarga.
            queryClient.invalidateQueries('eventList');
            navigation('/eventos/listar')
        },
        onError: (e: any) => {
            setShowModalError(true)
            /* if (e.response.status) {
              
            } else {
               
            } */


        }
    })

    const onSubmitBack = () => {
        navigation(-1)
        reset()
    }

    const onSubmit = (data: any) => {
        userMutation.mutate({ ...data, id: id });
    };

    if (isLoading || isFetching) {
        return <div>Cargando...</div>;
    }

    if (isError) {
        return <div>Error al cargar los usuarios</div>;
    }



    const onHandleEdit = () => {
        setFormDisabled(false)
    }

    // console.log(wishlist?.data.result.id, "iddddd")
    return (
        <div className="">
            <div className="d-flex justify-content-between align-items-center">
                {/*    <MainTitle
                    disabled={formDisabled}
                    title='Ver detalle de wishlist'
                    editTitle='Editar evento'
                    icon /> */}


                <div className="d-flex gap-5 align-items-center">

                    <div className="p-2 cursor-pointer fa-rotate-270 " onClick={() => navigation('/wishlist/listar')}>
                        <IconArrowDown stroke='#00A160' width={25} height={25} />
                    </div>
                    <div className="title">
                    Ver detalle de pre-pedido

                    </div>
                </div >
            </div>

            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x ms-3  mt-5 mb-7 fs-6">
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_4"
                    >
                        Datos general
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_5"
                    >
                        Productos de pre-pedido
                    </a>
                </li>

            </ul>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_4"
                            role="tabpanel"
                        >
                            <GeneralData data={wishlist?.data?.result} />
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                            <WishlistProducts
                                data={dataJson}
                                entityCustomerId={wishlist?.data?.result?.id}
                                customer={wishlist?.data?.result}
                                contentEventId={wishlist?.data.result.content_event_id}
                            />
                        </div>

                    </div>

                    {!formDisabled && (
                        <div className="container mt-15 mb-5">
                            <div className="row justify-content-center">
                                <div className="col-auto">

                                    <button
                                        type='button'
                                        onClick={() => setShowModalCancel(true)}
                                        className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
                                        Cancelar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            const startDate = methods.getValues().start_date
                                            const endDate = methods.getValues().end_date
                                            /*  const name = methods.getValues().name */
                                            const data = methods.getValues()

                                            /* 
                                                                                        delete data.code_as400
                                                                                        delete data.created_at
                                             */
                                            /* 
                                                                                        if (
                                                                                            Object.values(data)
                                                                                                .map((e) => e.toString())
                                                                                                .includes('') ||
                                                                                            !endDate ||
                                                                                            !startDate
                                                                                        ) {
                                                                                            setShowModalAlert(true)
                                                                                        } else {
                                                                                            setShowModalSuccess(true)
                                                                                        } */
                                        }}
                                        type='button'
                                        className="btn btn-primary me-2  d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
                                        Guardar cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </form>
            </FormProvider>
            {/*  <ModalSuccess onSubmit={handleSubmit(onSubmit)} show={showModalSuccess} onHide={() => setShowModalSuccess(false)} /> */}
            <ModalConfirmation
                icon={<IconCancelCircle />}
                title='¿Seguro que deseas cancelar sin haber guardado los cambios?'
                textButton='Sí, cancelar'
                textButtonCancel='No, continuar'
                show={showModalCancel}
                onHide={() => setShowModalCancel(false)}
                onClick={onSubmitBack}
            />
            <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} />
            <ModalAlert
                title='¡Error!'
                description='Error al cargar la página, por favor intente nuevamente.'
                show={showModalError}
                onHide={() => setShowModalError(false)}
            />

        </div>
    )
}
