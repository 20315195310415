import * as React from 'react'
import {SVGProps} from 'react'
const IconAlertCircleModal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23 0C18.451 0 14.0042 1.34893 10.2219 3.8762C6.43956 6.40347 3.4916 9.99558 1.75078 14.1983C0.00996562 18.401 -0.445511 23.0255 0.441949 27.4871C1.32941 31.9486 3.51995 36.0468 6.73655 39.2634C9.95316 42.4801 14.0514 44.6706 18.5129 45.558C22.9745 46.4455 27.599 45.99 31.8017 44.2492C36.0044 42.5084 39.5965 39.5604 42.1238 35.7781C44.6511 31.9958 46 27.549 46 23C45.9928 16.9022 43.5673 11.0563 39.2555 6.74449C34.9437 2.43271 29.0978 0.00719371 23 0ZM23 41.8182C19.2781 41.8182 15.6398 40.7145 12.5452 38.6467C9.45055 36.579 7.03858 33.64 5.61428 30.2014C4.18997 26.7628 3.81731 22.9791 4.54341 19.3288C5.26952 15.6784 7.06177 12.3253 9.69354 9.69353C12.3253 7.06176 15.6784 5.26951 19.3288 4.5434C22.9791 3.8173 26.7628 4.18996 30.2014 5.61427C33.64 7.03857 36.579 9.45054 38.6467 12.5452C40.7145 15.6398 41.8182 19.2781 41.8182 23C41.8121 27.989 39.8275 32.772 36.2997 36.2997C32.772 39.8275 27.989 41.8121 23 41.8182ZM25.0909 35.5454H20.9091V31.3636H25.0909V35.5454ZM25.0909 27.1818H20.9091V10.4545H25.0909V27.1818Z'
      fill='#F79B40'
    />
  </svg>
)
export default IconAlertCircleModal
