/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {IconDot, IconMagnifier, IconNote, IconSearch2} from '../../../../commons/icons'
import useModal from '../../../../commons/hooks/useModal'
import {ModalAddProduct} from '../components/modals/ModalAddProduct'
import {ShoppingCartOffCanvas} from '../offCanvas/ShoppingCartOffCanvas'
import {Swiper, SwiperSlide} from 'swiper/react'
import {useMutation} from 'react-query'
import {ModalAlert} from '../../configuration/components/modals/ModalAlert'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store/store'
import Select from 'react-select'
import wishlistService from '../../../../services/whislist'
import 'swiper/css'
import 'swiper/css/pagination'
import {
  Contentproduct,
  IAddPurchare,
  IEntityCustomer,
  Taxonomycategory,
} from '../../../../commons/interfaces/wishList/wishListProduct'
// Component to select the view of the table
import TableViewSelector from '../components/TableViewSelector'
// Component to show the table of products
import ProductTable from '../components/tables/ProductTable'
import IconErrorCircle from '../../../../commons/icons/IconErrorCircle'
import {Product} from '../../../../commons/interfaces/wishList/wishListShowSumary'
import {ModalAlertProductInTheCart} from '../components/modals/ModalAlertProductInTheCart'
import ModalLoader from '../../clients/components/modals/ModalLoader'
import {set} from 'date-fns'
import SearchFilter from '../../../../commons/components/search/SearchFilter'

export interface IOption {
  value: any
  label: string
}
export const WishListClientCards = () => {
  const params = useParams()
  const {contentEventId, entityCustomerId} = params

  const [search, setSearch] = useState<string>('')
  const {handleClose, handleShow, show} = useModal()
  const [showLoader, setShowLoader] = useState(false)
  const closeLoader = () => setShowLoader(false)
  const {handleClose: closeOffCanva, handleShow: showOffCanva, show: offCanva} = useModal()
  // State to store the selected card
  const [selectedCard, setSelectedCard] = useState<Product>()
  // State to store the data of the products obtained from the API
  const [dataProducts, setDataProducts] = useState<Contentproduct[]>([])
  // State to compare the products in the cart with the products in the table
  const [addedProducts, setAddedProducts] = useState<Product[]>([])
  // State to products formated, this list is used to render the products in the table and cards
  const [productsFormated, setProductsFormated] = useState<Product[]>([])
  const [dataCategories, setDataCategories] = useState<Taxonomycategory[]>([])
  const [showModalError, setShowModalError] = useState(false)
  const [customer, setCustomer] = useState<IEntityCustomer>()
  const [notResult, setNotResult] = useState(false)
  const [idCategorySeletect, setIdCategorySelected] = useState<any>('')
  const [entitySupplierId, setEntitySupplierId] = useState<any>('')
  const [options, setOptions] = useState<IOption[]>([])
  const {userLogin} = useSelector((state: RootState) => state.auth)
  // State to store the view selected
  const [viewSelected, setViewSelected] = useState('table')
  // State to control the modal
  const [showAlert, setShowAlert] = useState(false)
  const searchFilterRef = useRef<any>(null) // Referencia para el componente SearchFilter

  const [filters, setFilters] = useState<any>({
    contentEventId: 0,
    taxonomy_category_id: '',
    name: '',
  })

  // Function to handle the click on a card
  const handleCardClick = (card: Product) => {
    // Set the value of the product
    setSelectedCard(card)
    // Conditional on whether you already have a registered amount
    if (card.quantity) {
      // If the product is already registered, an alert modal opens
      setShowAlert(true)
    } else {
      handleShow()
    }
  }

  useEffect(() => {
    if (userLogin) {
      let entitySuppliers: IOption[] = userLogin.entity_suppliers.map((item) => {
        return {
          value: item.id,
          label: item.business_name,
        }
      })
      if (entitySuppliers.length > 1)
        entitySuppliers.unshift({value: '', label: 'Todos los proveedores'})
      setOptions(entitySuppliers)
    }
  }, [])

  useEffect(() => {
    if (search !== '') setIdCategorySelected('')
    if (contentEventId) {
      getDataListProducts()
    }
  }, [filters, search, entitySupplierId])

  const getDataListProducts = async () => {
    setShowLoader(true)
    await getDataMutation.mutateAsync({
      ...filters,
      taxonomy_category_id: search !== '' ? '' : idCategorySeletect,
      contentEventId,
      entityCustomerId,
      entitySupplierId,
      name: search,
    })
  }

  const selectedCategory = (idCategory: number) => {
    setSearch('')
    const taxonomy_category_id = idCategorySeletect === idCategory ? '' : idCategory
    setFilters({
      ...filters,
      taxonomy_category_id,
      name: '',
    })
    setIdCategorySelected(taxonomy_category_id)
  }

  const handleSearch = () => {
    if (searchFilterRef) searchFilterRef.current.triggerSearch()
  }

  const getDataMutation = useMutation(wishlistService.getDataProductsByWhislist, {
    onSuccess: (resp) => {
      setDataProducts(resp.data.result.content_products)
      setDataCategories(resp.data.result.taxonomy_categories)
      setNotResult(resp.data.result.content_products.length > 0 ? false : true)
      setCustomer(resp.data.result.entity_customer)
      setTimeout(() => {setShowLoader(false)}, 300);
    },
    onError: (e: any) => {
      setShowLoader(false)
      setShowModalError(true)
    },
  })

  // Function to show error toast
  const showError = (message: string) => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: <IconErrorCircle />,
      bodyClassName: 'custom-toast-error',
    })
  }

  // Function to refresh the product in the cart
  const refreshProduct = ({
    dataToUpdate,
    product,
  }: {
    dataToUpdate: IAddPurchare
    product: Product
  }) => {
    // Check if the product is already in the cart
    const productInCart = addedProducts.find((item) => item.id === dataToUpdate.content_product_id)
    // If the product is not in the cart, add it
    if (!productInCart) {
      const newProductInCart = {
        ...product,
        quantity: dataToUpdate.quantity,
        quantityMaster: Math.round((dataToUpdate.quantity / product.conversion_unit!) * 100) / 100,
      }
      setAddedProducts([...addedProducts, newProductInCart])
    } else {
      // If the product is in the cart, update the quantity
      const updatedProducts = addedProducts.map((item) => {
        if (item.id === dataToUpdate.content_product_id) {
          return {
            ...item,
            quantity: dataToUpdate.quantity,
            quantityMaster: Math.round((dataToUpdate.quantity / item.conversion_unit!) * 100) / 100,
          }
        }
        return item
      })
      setAddedProducts(updatedProducts)
    }
  }

  // Function to delete a product from the cart
  const deleteProductFromCart = (product_id: number) => {
    if (addedProducts.length > 0 && product_id) {
      const updatedProducts = addedProducts.filter((item) => item.id !== product_id)
      setAddedProducts(updatedProducts)
    }
  }

  // Mutation to get the products in the cart
  const whislistMutation = useMutation(wishlistService.getSumaryPurchase, {
    onSuccess: (resp) => {
      const data = [...resp.data.result].map((item) => {
        return {
          ...item,
          quantityMaster: Math.round((item.quantity / item.conversion_unit!) * 100) / 100,
        }
      })
      setAddedProducts(data)
      setNotResult(data.length > 0 ? false : true)
    },
    onError: (e: any) => {
      showError('Al parecer ocurrio un error al obtener el resumen. Intenteló más tarde.')
      handleClose()
    },
  })

  // Function to get the products in the cart
  const getProductsFromCart = async () => {
    await whislistMutation.mutateAsync({entityCustomerId: Number(entityCustomerId)})
  }

  // Get the products in the cart when the component is mounted
  useEffect(() => {
    getProductsFromCart()
  }, [])

  // Update the products in the table when the products in the cart change
  useEffect(() => {
    // If there are products in the table
    if (dataProducts.length > 0) {
      // Format the products in the table with the quantity of the products in the cart
      const formattedProducts =
        addedProducts.length > 0
          ? JSON.parse(JSON.stringify(dataProducts)).map((product) => {
              // Check if the product is in the cart
              const productInCart = addedProducts.find(
                (addedProduct) => addedProduct.id === product.id
              )
              // If the product is in the cart, add the quantity to the product
              if (productInCart) {
                // Return the product with the quantity of the product in the cart
                return {
                  ...product,
                  quantity: productInCart.quantity,
                  quantityMaster:
                    Math.round((productInCart.quantity / productInCart.conversion_unit) * 100) /
                    100,
                }
              }
              // If the product is not in the cart, return the product without changes
              return product
            })
          : dataProducts
      setProductsFormated(formattedProducts)
    } else {
      setProductsFormated([])
    }
  }, [addedProducts, dataProducts])

  return (
    <div className=''>
      <div className='d-flex justify-content-between flex-md-row flex-sm-column flex-column'>
        <div className='title'>Pre-pedido de cliente</div>
        {customer && (
          <span className='label-customer'>
            <strong>PIN: </strong> {customer?.pin}
          </span>
        )}
      </div>

      <div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap border-0 pt-6'>
        <div className='card-title supplier-title'>
          {/* begin::Search */}
          <div className='d-flex gap-3'>
            <SearchFilter manageSearch={setSearch} ref={searchFilterRef} isAutomatic/>
            <div className='p-1 d-none'>
              <button type='button' className='btn btn-primary' onClick={handleSearch}>
                <IconMagnifier stroke='white' />
              </button>
            </div>
          </div>
          {/* end::Search */}
        </div>
        <div className='d-flex  flex-wrap   gap-3 mt-4 mt-sm-1'>
          {/* The TableViewSelector component is used to select the view of the table. */}
          <TableViewSelector setViewSelected={setViewSelected} viewSelected={viewSelected} />
          {options.length > 0 && (
            <div className='entity-supplier'>
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#00a16080',
                  },
                })}
                styles={{
                  indicatorSeparator: () => ({display: 'none'}),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#dcdcdc',
                    overflow: 'auto',
                    height: '44px',
                    backgroundColor: 'transparent',
                  }),
                  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                    return {
                      ...styles,
                      backgroundColor: isFocused || isSelected ? '#EFEFEF' : 'white',
                      fontWeight: isSelected ? 500 : 400,
                      color: isSelected ? '#00A160' : '#464646',
                      cursor: isDisabled ? 'not-allowed' : 'default',
                      '&:active': {
                        backgroundColor: '#EFEFEF',
                      },
                    }
                  },
                }}
                placeholder='Proveedor'
                onChange={(e) => {
                  if (e) setEntitySupplierId(e.value)
                }}
                isSearchable={options.length > 5 ? true : false}
                options={options}
                defaultValue={
                  options.length > 0
                    ? options.filter((e: IOption) => e.value === entitySupplierId)
                    : []
                }
              />
            </div>
          )}
          <button
            type='button'
            className='btn btn-outline btn-outline-primary h-44   w-[200px] '
            onClick={showOffCanva}
          >
            <IconNote className='me-3' />
            Resumen de pre-pedido
          </button>
        </div>
      </div>

      <div className='mt-5'>
        <Swiper spaceBetween={10} slidesPerView={'auto'} className='mySwiper'>
          {dataCategories.map((item, index) => (
            <SwiperSlide key={'category-' + item.id}>
              <div
                onClick={() => {
                  selectedCategory(item.id)
                }}
                style={{width: 'max-content', cursor: 'pointer'}}
                className={`px-4 py-3 rounded-2 text-white bg-color-${(index % 5) + 1}`}
              >
                {item.id === idCategorySeletect && (
                  <IconDot style={{marginRight: '7px'}} width={3} height={3} fill='#fff' />
                )}
                {item.name}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* If has result and view selected is card remder cards in list */}
      {!notResult && viewSelected === 'card' && (
        <div style={{paddingLeft: 0, paddingRight: 0}}>
          <div className='container-whislist mt-8 mb-8'>
            {productsFormated.map((item) => (
              <div key={'product-' + item.id} onClick={() => handleCardClick(item)}>
                <div className='card card-wishlist p-4'>
                  <div className='d-flex flex-column'>
                    <h5 className='card-wishlist-title'>{item.brand}</h5>
                    <p className='card-wishlist-content'>{item.name}</p>
                  </div>
                  <p className='card-wishlist-sku'>SKU: {item.sku}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* If has result and view selected is table remder table */}
      {!notResult && viewSelected === 'table' && (
        <div className='mt-6'>
          <div className='overflow-auto table-wishlist'>
            <ProductTable
              data={productsFormated}
              refetched={getDataMutation.isSuccess}
              refetch={getDataListProducts}
              isNotResult={notResult}
              clickAddProduct={handleCardClick}
            />
          </div>
        </div>
      )}

      {notResult && (
        <div className='mt-20 mb-8'>
          <div className='d-flex justify-content-center mt-5'>
            <IconSearch2 />
          </div>
          <div className='d-flex flex-column justify-content-center mt-5 text-center'>
            <p style={{fontWeight: 300}} className=' text-label mb-2'>
              No se han encontrado resultados en esta búsqueda.
            </p>
            <p className='text-label tw-green-700'>Por favor, intenta otra vez.</p>
          </div>
        </div>
      )}

      {selectedCard && (
        <ModalAddProduct
          card={selectedCard}
          show={show}
          onHide={handleClose}
          entityCustomerId={Number(entityCustomerId)}
          refreshProduct={refreshProduct}
        />
      )}

      <ModalAlert
        title='¡Error!'
        description='Error al cargar la página, por favor intente nuevamente.'
        show={showModalError}
        onHide={() => setShowModalError(false)}
      />

      <ModalAlertProductInTheCart
        product={selectedCard}
        show={showAlert}
        onHide={() => setShowAlert(false)}
        onClick={handleShow}
      />

      <ShoppingCartOffCanvas
        handleClose={closeOffCanva}
        handleShow={showOffCanva}
        show={offCanva}
        entityCustomerId={Number(entityCustomerId)}
        nameCustomer={
          customer
            ? customer.firstname +
              ' ' +
              customer.lastname +
              ' ' +
              (customer.middlename ? customer.middlename : ' ')
            : ''
        }
        pinCustomer={customer?.pin}
        refreshData={setAddedProducts}
        removeProductFromCart={deleteProductFromCart}
      />
      <ModalLoader show={showLoader} onClose={closeLoader} />
    </div>
  )
}
