import {useEffect, useState} from 'react';
import {ModalAlert} from '../../../../app/modules/configuration/components/modals/ModalAlert';
import {useStateCallback} from '../../../../commons/hooks/useStateCallback';
import {Download, IconAlertCircle, Trash} from '../../../../commons/icons';
import {
  ImageDataFromInput,
  FileUploaded,
} from '../../../../commons/interfaces/managementFile/managementFileCreate';
import BlobService from '../../../../services/managementFile/blobService';
import DownloadFile from '../../../../commons/icons/DownloadFile';

// The storageAccountName and containerName are the names of the storage account and container in Azure.
const storageAccountName = process.env.REACT_APP_STORAGE_RESOURCE_NAME
const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME || 'media'

/**
 * Interface for the properties of the InputUploadFiles component.
 */
interface IProps {
  /**
   * The unique identifier for the component.
   */
  id: string
  /**
   * Specifies whether the form is disabled or not.
   */
  formDisabled?: boolean
  /**
   * The text to be displayed on the button.
   */
  textButton?: string
  /**
   * The title of the form.
   */
  titleForm?: string
  /**
   * The label to be displayed below the title.
   */
  spanLabel?: string
  /**
   * The file data to be displayed.
   */
  file?: FileUploaded | null
  /**
   * The function to handle the file selection.
   */
  handleFunction: (file: FileUploaded | null) => void
  /**
   * Specifies whether the field is required or not.
   */
  required?: boolean
  /**
   * The allowed types of files.
   * (default is ['image/jpeg', 'image/png']).
   */
  allowedTypes?: string[]
  /**
   * The maxSize is the maximum size of the file in bytes
   * (default is 10MB).
   */
  maxSize?: number
}
interface IProps {
  id: string
  formDisabled?: boolean
  textButton?: string
  titleForm?: string
  spanLabel?: string
  file?: FileUploaded | null
  handleFunction: (file: FileUploaded | null) => void
  required?: boolean
  allowedTypes?: string[]
  maxSize?: number
  showIconTrash?:boolean
};

// The getFileData function receives a file and returns an object with the file data.
const getFileData = (fileParam: File) => {
  // If the file is null, the function returns null.
  if (fileParam === null) return null;
  // Generate a unique identifier for the file.
  const timestamp = Date.now().toString();
  const name = fileParam?.name;
  const uniqueId = Math.random().toString(36).substr(2, 9);
  const blobId = `${name}-${timestamp}-${uniqueId}`;
  // Generate the path for the file.
  const path = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobId}`;
  const title = fileParam?.name.split('.')[0];
  const size_bytes = fileParam?.size;
  const type = fileParam?.name.split('.').pop() || '';
  const file = fileParam;
  // Return the file data.
  const fileData: ImageDataFromInput = {
    title,
    name,
    size_bytes,
    type,
    path,
    blobId,
    file
  };
  return fileData;
}

/**
 * Component for uploading and displaying files.
 * @component
 * @param {IProps} props Properties for the component.
 * @param {string} props.id The unique identifier for the component.
 * @param {boolean} props.formDisabled Specifies whether the form is disabled or not.
 * @param {string} props.textButton The text to be displayed on the button.
 * @param {string} props.titleForm The title of the form.
 * @param {string} props.spanLabel The label to be displayed below the title.
 * @param {FileUploaded | null} props.file The file data to be displayed.
 * @param {(file: FileUploaded | null) => void} props.handleFunction The function to handle the file selection.
 * @param {boolean} props.required Specifies whether the field is required or not.
 * @param {string[]} props.allowedTypes The allowed types of files.
 * @param {number} props.maxSize The maximum size of the file.
 * @returns {React.FC<IProps>} The InputUploadFiles component.
 * @example
 * <InputUploadFiles
 *   id="uploadImage"
 *   formDisabled={false}
 *   textButton="Select File"
 *   titleForm="Image"
 *   spanLabel="(JPG or PNG format)"
 *   file={null}
 *   handleFunction={(file) => console.log(file)}
 *   required={true}
 * />
 */
const InputUploadFiles: React.FC<IProps> = (props) => {
  // Destructure the properties of the component.
  const {
    formDisabled,
    textButton = 'Seleccionar archivo',
    titleForm = 'Imagen',
    spanLabel = '(formato JPG o PNG)',
    file = null,
    id,
    required,
    showIconTrash=false,
    handleFunction,
    allowedTypes = ['image/jpeg', 'image/png'],
    maxSize = 10 * 1024 * 1024,
  } = props;
  // The managementFile state is used to store the file data.
  const [managementFile, setManagementFiles] = useStateCallback<FileUploaded | null>(file || null);
  // The showError state is used to display an error message.
  const [showError, setShowError] = useState({show: false, message: '', title: ''});
  // The setFile function is used to set the file data.
  const setFile = (file: FileUploaded | null) => {
    // If the file is not null, the handleFunction is called with the file data.
    if (file) {
      // The handleFunction is called with the file data.
      const bodyFile=  managementFile && managementFile?.id ? {
        path: file.path,
        name: file.name,
        id:managementFile?.id
      } :{
        path: file.path,
        name: file.name,
      }
      handleFunction(bodyFile);
    } else {
      handleFunction(null);
    }
    // The file data is stored in the managementFile state.
    setManagementFiles(file);
  };
  // The handleFileChange function is called when a file is selected.
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    // If the event target files are null, the function returns.
    if (!event.target.files) return;
    // The selectedFile is the first file in the event target files.
    const selectedFile = event.target.files[0];
    // The fileData is generated with the selected file.
    const fileData = getFileData(selectedFile);
    // If the selected file is not an file, an error message is displayed.
    if (!allowedTypes.includes(selectedFile.type)) {
      // La variable showError se establece con el mensaje de error.
      setShowError({
        show: true,
        message: 'Lo sentimos, tipo de archivo inválido.',
        title: 'Error en tipo de archivo',
      });
      return;
    };
    // If the selected file is an file and the size is less than the maximum size, the file is uploaded.
    if (selectedFile.size <= maxSize && fileData) {
      // The BlobService.upload function is called to upload the file.
      BlobService.upload(containerName, selectedFile, fileData.blobId)
        .then(() => {
          // The file data is stored in the managementFile state.
          setFile(fileData)
        })
        .catch((error) => {
          console.log(error)
          setShowError({
            show: true,
            message: 'Lo sentimos, ha ocurrido un error durante la carga del archivo.',
            title: 'Error en la carga',
          })
        });
    } else {
      setShowError({
        show: true,
        message: 'Por favor, seleccione un archivo que tenga un tamaño menor o igual a 10MB.',
        title: 'Limite excedido',
      });
    }
    event.target.value = '';
  }
  // Clear the input value when the user clicks on the input.
  const eventclick = (event: any) => {
    event.target.value = '';
  }

  useEffect(() => {
    if (file) setManagementFiles(file);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <>
      {/* The label and span elements are displayed. */}
      <label className='form-label fs-6  text-dark'>{titleForm}</label>
      {/* The span element is displayed with the required class if the field is required. */}
      <span className={`ms-2 text-gray-400 ${required ? 'required' : ''}`}>{spanLabel}</span>
      {/* The button is displayed with the textButton text when formDisabled is false */}
      {/* The label open the file section */}
      {!formDisabled && (
        <div className='d-flex gap-2 align-items-center'>
          <label
            htmlFor={id}
            className='button-output d-flex align-items-center font-bold gap-3 w-200px'
          >
            <Download />
            {textButton}
          </label>
          {!managementFile && <span className='ms-2'>Ningún archivo seleccionado.</span>}
        </div>
      )}
      {/* The input element is displayed with the d-none class */}
      <input
        type='file'
        id={id}
        className='d-none'
        onChange={handleFileChange}
        onClick={eventclick}
      />
      {/* The file is displayed if the managementFile is not null */}
      {managementFile && (
        <div className='d-flex justify-content-between align-items-center mt-3'>
          {managementFile.name && (<div className='d-flex gap-4 justify-content-between align-items-center'>
            <img
              src={`/media/imgs/${managementFile.name.slice(-3)}-type.png`}
              width='30'
              height='30'
              alt=''
            />
            <div className=''>{managementFile.name}</div>
          </div>)}
          <div className='d-flex gap-3'>
            {/* The download button download the file */}
            <button
              type='button'
              className='btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3'
              onClick={() => {
                BlobService.downloadFileFromBlob(managementFile.path, managementFile.name)
              }}
            >
              <DownloadFile />
            </button>
            {/* The trash button delete the file */}
            {(!formDisabled && showIconTrash) && (
              <button
                type='button'
                className='btn btn-outline btn-outline-primary d-flex align-items-center gap-3'
                onClick={() => {
                  setFile(null)
                }}
              >
                <Trash />
              </button>
            )}
          </div>
        </div>
      )}
      <ModalAlert
        show={showError.show}
        title={showError.title}
        description={showError.message}
        onHide={() => setShowError({show: false, message: '', title: ''})}
        icon={<IconAlertCircle />}
      />
    </>
  )
}

export default InputUploadFiles;
