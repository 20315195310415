import {SVGProps} from 'react'
const DownloadFile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M2.69922 12.1508L2.69922 12.9383C2.69922 14.2431 3.75695 15.3008 5.06172 15.3008L12.9367 15.3008C14.2415 15.3008 15.2992 14.2431 15.2992 12.9383L15.2992 12.1508M12.1492 9.00078L8.99922 12.1508M8.99922 12.1508L5.84922 9.00078M8.99922 12.1508L8.99922 2.70078'
        stroke='#00A160'
        strokeWidth={1.67}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DownloadFile
