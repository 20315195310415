import React from 'react'

interface IProps {
  icon: JSX.Element
  title: string
  background: string
  onClick?: () => void
}

export default function Card({icon, title, background, onClick}: IProps) {
  return (
    <div
      style={{
        background,
        borderRadius: 14,
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'end',
        gap: 16,
        minHeight: 150,
        width: 281,
        cursor:'pointer'
      }}
      onClick={onClick}
    >
      {icon}
      <span className='text-white' style={{fontWeight:400, fontSize:20}}>{title}</span>
    </div>
  )
}
