import { createSlice } from '@reduxjs/toolkit';

const defaultValue =  { filters: {}, currentPage: 1, limit: 20, search: '' }

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {},
  reducers: {
    setFiltersStore: (state, action) => {
      const { listName, filters, page, limit, search } = action.payload;
      if (!state[listName]) {
        state[listName] = defaultValue;
      }
      state[listName] = { filters, page, limit, search }
    },
    resetFilters: (state, action) => {
      const { listName } = action.payload;
      if (state[listName]) {
        state[listName] = defaultValue
      }
    },
  },
});

export const { setFiltersStore, resetFilters } = listingsSlice.actions;

export default listingsSlice.reducer;
