import Modal from 'react-bootstrap/Modal'
import { IconCheckCircle, IconDecrement, IconExit, IconIncrement } from '../../../../../commons/icons'
import Counter from '../Counter'
import { Result } from '../../../../../commons/interfaces/wishList/wishListItemGet'
import { useMutation, useQueryClient } from 'react-query'
import wishlistService from '../../../../../services/whislist'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ModalErrors } from '../../../../../commons/components/modals/ModalErrors'
import { useErrorHandling } from '../../../../../commons/hooks/useErrorHandling'

interface IProps {
    data: Result | undefined | null,
    setCard?: React.Dispatch<React.SetStateAction<string>>,
    show: boolean
    onHide: () => void

}
export const ModalUpdateItem = ({ data, setCard, show, onHide, }: IProps) => {
    const { errorType, handleApiError, clearError } = useErrorHandling();
    const [inputUND, setInputUND] = useState(data?.quantity!);
    const [inputMaster, setInputMaster] = useState(data?.quantity! / data?.conversion_unit!);
    const [convert, setConvert] = useState(data?.conversion_unit!);


    const queryClient = useQueryClient();
    const userMutation = useMutation({
        mutationFn: wishlistService.cartItemCreateUpdate,
        onSuccess: () => {
            toast.success('Cantidad del producto cambiada.', {
                position: "bottom-right",
                autoClose: 5000, // Tiempo de cierre automático en milisegundos
                hideProgressBar: true, // Mostrar barra de progreso
                closeOnClick: true, // Cerrar al hacer clic
                draggable: true, // Arrastrable
                progress: undefined, // Personalizar la barra de progreso
                icon: <IconCheckCircle />,
                bodyClassName: "custom-toast-success"
            });
            queryClient.invalidateQueries('listDetailWishlist');

        },
        onError: (e: any) => {
            handleApiError(e)
        }
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'inputUND') {
            setInputUND(value);
            setInputMaster(Math.round((value / convert) * 100) / 100);
        } else {
            if (inputMaster === 0) {
                setInputMaster(value)

            }
            setInputMaster(Math.round(value * 100) / 100);
            setInputUND(Math.round(value * convert));
        }
    };

    const handleIncrement = (event) => {
        if (event.target.name === 'inputUND') {
            //console.log("aca");

            setInputUND(Math.round(inputUND) + 1);
            setInputMaster(Math.round(((inputUND + 1) / convert) * 100) / 100);
        } else {
            setInputMaster(Math.round((inputMaster + 1) * 100) / 100);
            setInputUND(Math.round((inputMaster + 1) * convert));
        }
    };

    const handleDecrement = (event) => {
        if (event.target.name === 'inputUND') {
            if (inputUND > 1) {
                setInputUND(inputUND - 1);
                setInputMaster(Math.round(((inputUND - 1) / convert) * 100) / 100);
            }
        } else {
            if (inputMaster > 1) {
                setInputMaster(Math.round((inputMaster - 1) * 100) / 100);
                setInputUND(Math.round((inputMaster - 1) * convert));
            }
        }
    };




    const handleSubmit = () => {

        const sendBackend = {
            entity_customer_id: data?.entity_customer.id,
            content_product_id: data?.id,
            quantity: inputUND,
            selected: data?.selected
        }


        const payload = { shopping_list: [sendBackend] };
        userMutation.mutate(payload)
        onHide()
    }


    useEffect(() => {
        setInputUND(data?.quantity!)
        setInputMaster(Math.round((data?.quantity! / data?.conversion_unit!) * 100) / 100)
        setConvert(data?.conversion_unit!)
    }, [data])

    return (
      <>
        <Modal
          show={show}
          dialogClassName='bs-modal-width'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body>
            <div className='position-relative h-auto'>
              <div className='mt-2'>
                <div className='d-flex flex-column align-items-center'>
                  <h4 className='fs-4 px-14 text-center text-neutral-800 fw-medium mt-3'>
                    {data?.name}
                  </h4>
                  <p className='text-gray-2'>SKU: {data?.sku}</p>
                  <div className='w-100 px-8 mt-10'>
                    <div className='d-flex justify-content-between align-items-center mb-8'>
                      <label className='card-wishlist-title fs-6' htmlFor='inputUND'>
                        {data?.unit_measure} (UND)
                      </label>
                      <div className='counter-container'>
                        <button
                          className='decrement-button'
                          name='inputUND'
                          onClick={handleDecrement}
                        >
                          <IconDecrement stroke='#fff' />
                        </button>
                        <input
                          className='count'
                          type='number'
                          name='inputUND'
                          value={inputUND}
                          onChange={handleInputChange}
                          min='1'
                          step='1'
                          onKeyDown={(event) => {
                            if (event.key === '.') {
                              event.preventDefault() // Evita el carácter "." en el input
                            }
                          }}
                        />
                        <button
                          className='increment-button text-white px-3'
                          name='inputUND'
                          onClick={handleIncrement}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center mb-8'>
                      <label className='card-wishlist-title fs-6' htmlFor='inputMaster'>
                        {data?.master_package} (MASTER)
                      </label>
                      <div className='counter-container'>
                        <button
                          className='decrement-button'
                          name='inputMaster'
                          onClick={handleDecrement}
                        >
                          <IconDecrement stroke='#fff' />
                        </button>
                        <input
                          type='number'
                          name='inputMaster'
                          className='count'
                          value={inputMaster}
                          onChange={handleInputChange}
                          min='0'
                          step='1'
                        />
                        <button
                          className='increment-button text-white px-3'
                          name='inputMaster'
                          onClick={handleIncrement}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-grid gap-2 mt-5 '>
                  <button
                    type='button'
                    id='kt_sign_in_submit'
                    className='btn btn-primary '
                    onClick={handleSubmit}
                    // disabled={(inputUND == 0) ? true : false}
                  >
                    Confirmar
                  </button>
                </div>
              </div>

              <div onClick={onHide} className='position-absolute top-0 end-0 cursor-pointer'>
                <IconExit fill='#7C7C7C' width={14} height={14} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
      </>
    )
}