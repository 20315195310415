import React from 'react'
import { IconArrow } from '../../../../commons/icons'
import { useNavigate } from 'react-router-dom'

const Error403 = () => {
  const navigate = useNavigate()
  const redirectTo=()=>{
    navigate('/');
  }
  return (
    <>
      <div className='row gy-5'>
        <div className='col-md-12'>
          <h1 className='fw-bolder fs-6qx  text-white'>403</h1>
        </div>
        <div className='col-md-12 text-white' style={{margin:0}}>
          <div className='fs-3qx'>Acceso denegado.</div>
          <p className='fs-2'>Lo sentimos, no puedes acceder a esta página. 
          <br/>No tienes la autorización necesaria para ver su contenido.</p>
        </div>
        <div className='col-md-12'>
          <button type='submit' id='kt_sign_in_submit' className='btn mt-1 btn-secondary'  onClick={() => redirectTo()}>
            <div className='d-flex gap-2 align-items-center justify-content-center'>
             <IconArrow />
              <span className='indicator-label'>Ir a inicio</span>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default Error403
