
/**
 * Represents a TimePicker component.
 * @param props - The props for the TimePicker component.
 * @component
 * @example
 * ```tsx
 * <TimePicker
 *   id="timePicker"
 *   formDisabled={false}
 *   timeValue="12:00"
 *   handleChange={(value) => console.log(value)}
 *   minTime="00:00"
 *   maxTime="23:59"
 * />
 * ```
 */
import {format} from 'date-fns';
import {useState} from 'react';
import clsx from 'clsx';
import IconTime from '../../icons/IconTime';
import Flatpickr from 'react-flatpickr';

/**
 * Represents the props for the TimePicker component.
 */
interface IProps {
  /**
   * The unique identifier for the TimePicker component.
   */
  id: string;

  /**
   * Specifies whether the TimePicker component is disabled or not.
   */
  formDisabled?: boolean;

  /**
   * The initial value for the TimePicker component.
   */
  timeValue?: string;

  /**
   * The callback function that is called when the value of the TimePicker component changes.
   * @param value - The new value of the TimePicker component.
   */
  handleChange?: (value: string) => void;

  /**
   * The minimum time that can be selected.
   */
  minTime?: string;

  /**
   * The maximum time that can be selected.
   */
  maxTime?: string;
}
interface IProps {
  id: string;
  formDisabled?: boolean;
  timeValue?: string;
  handleChange?: (value: string) => void;
  minTime?: string;
  maxTime?: string;
}

export const TimePicker = (props: IProps) => {
  const { handleChange, timeValue, formDisabled, id, minTime='00:00', maxTime='23:59' } = props;
  const hour = timeValue ? timeValue : '00:00'
  const [timeState, setTimeState] = useState(hour)

  const handleTimeChange = (time) => {
    if (handleChange) handleChange(format(time, 'HH:mm'))
    setTimeState(time)
  }

  return (
    <div className='fv-row'>
      <div className='position-relative'>
        <Flatpickr
          id={id}
          value={timeState}
          onChange={([time]) => {
            handleTimeChange(time)
          }}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            minTime: minTime,
            maxTime: maxTime,
          }}
          className='form-control'
          disabled={formDisabled}
        />
        <label
          htmlFor={id}
          style={{
            border: 'none',
            right: '0px',
            top: '-1px',
            position: 'absolute',
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
          className={clsx('p-4 ', formDisabled ? 'bg-gray-400' : 'bg-primary')}
        >
          <IconTime />
        </label>
      </div>
    </div>
  )
}
