import {Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {RecoverPassword} from './components/RecoverPassword'
import {ChangePassword} from './components/ChangePassword'
import SeletecEvents from './components/SeletecEvents'

const AuthPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const user = searchParams.get('user')

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='recover-password' element={<RecoverPassword />} />
        <Route path='reset-password' element={<ChangePassword token={token} user={user} />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
