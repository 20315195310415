import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import DropdownIcon from '../../../../../commons/components/overlay/DropdownIcon'
import {Product} from '../../../../../commons/interfaces/wishList/wishListShowSumary'
import IconAdjustment from '../../../../../commons/icons/IconAdjustment'
import IconPlus from '../../../../../commons/icons/IconPlus'

// Props
interface IProps {
  data: Product[] | undefined
  refetched: boolean
  refetch: any
  isNotResult?: boolean
  isNotResultFilter?: boolean
  clickAddProduct: (product: Product) => void
}

/**
 * ProductTable component
 * This component is responsible for rendering the product table.
 * @param param {IProps} {data, refetched, isNotResult, isNotResultFilter}
 * @param param.data Data of the table
 * @param param.refetched Refetched data
 * @param param.isNotResult If there are no results
 * @param param.isNotResultFilter If there are no results with the filters applied
 * @param param.clickAddProduct Function to add a product
 * @returns
 * @example
 * <ProductTable data={data} refetched={refetched} isNotResult={isNotResult} isNotResultFilter={isNotResultFilter} />
 */
const ProductTable = ({
  data,
  refetched,
  isNotResult,
  isNotResultFilter,
  clickAddProduct,
}: IProps) => {
  const [expandedCells, setExpandedCells] = useState({})

  const toggleText = (id: number, field: string) => {
    setExpandedCells((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: !prevState[id]?.[field],
      },
    }))
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead className='bg-head-table'>
          <tr className='fw-medium text-label'>
            <th className='min-w-50px'> </th>
            <th className='min-w-125px'>Código</th>
            <th className='min-w-400px'>Descripción</th>
            <th className='min-w-125px'>Marca</th>
            <th className='min-w-150px'>Cantidad solicitada</th>
            {/* <th className='min-w-100px'>Linea</th> */}
            <th className='min-w-150px'>Master</th>
            <th className='min-w-150px'>Unidad de medida</th>
            <th className='min-w-175px'>Equiv. en unidad master</th>
            {/* <th className='min-w-350px'>Proveedor del SKU</th> */}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => {
            const isNameExpanded = expandedCells[item.id]?.name
            const isSupplierExpanded = expandedCells[item.id]?.supplier
            return (
              <tr key={item.id}>
                <td>
                  <div className='d-flex justify-content-center'>
                    <DropdownIcon>
                      <>
                        {!item.quantity ? (
                          <button
                            className='btn-non-style p-3 d-flex justify-content-center align-items-center gap-3'
                            onClick={() => clickAddProduct(item)}
                          >
                            <IconPlus />
                            <span className='text-primary'>Añadir</span>
                          </button>
                        ) : (
                          <button
                            className='btn-non-style p-3 d-flex justify-content-center align-items-center gap-3'
                            onClick={() => clickAddProduct(item)}
                          >
                            <IconAdjustment />
                            <span className='text-primary'>Cambiar cantidad</span>
                          </button>
                        )}
                      </>
                    </DropdownIcon>
                  </div>
                </td>
                <td>{item.sku}</td>
                <td>
                  {item.name.length > 60 ? (
                    <span className='cursor-pointer' onClick={() => toggleText(item.id, 'name')}>
                      {isNameExpanded ? item.name : `${item.name.substring(0, 60)} ...`}
                    </span>
                  ) : (
                    item.name
                  )}
                </td>
                <td>{item.brand}</td>
                <td>{item.quantity || 0}</td>
                {/* <td>{item.line}</td> */}
                <td>{item.master_package}</td>
                <td>{item.unit_measure}</td>
                <td>{item.quantityMaster || 0}</td>
                {/* <td>
                  {item.entity_supplier?.business_name.length > 60 ? (
                    <span
                      className='cursor-pointer'
                      onClick={() => toggleText(item.id, 'supplier')}
                    >
                      {isSupplierExpanded
                        ? item.entity_supplier?.business_name
                        : `${item.entity_supplier?.business_name.substring(0, 60)} ...`}
                    </span>
                  ) : (
                    item.entity_supplier?.business_name
                  )}
                </td> */}
              </tr>
            )
          })}
          {isNotResult && (
            <tr>
              <td colSpan={8}>
                <div className='text-center'>No se encontraron resultados</div>
              </td>
            </tr>
          )}
          {isNotResultFilter && (
            <tr>
              <td colSpan={8}>
                <div className='text-center'>
                  No se encontraron resultados con los filtros aplicados
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* begin::Table head */}
    </div>
  )
}

export default ProductTable
