/**
 * The `ipAddressService` is a service that provides a method to fetch the user's IP address.
 */
const ipAddressService = {
  /**
   * Fetches the user's IP address from the ipify.org API.
   * @returns {Promise<string>} The user's IP address as a string. If there is an error, an empty string is returned.
  */
  getIpAddres: async () => {
    let ip: string = ""; // Initialize the IP address variable as an empty string
    // Fetch the IP address from the ipify.org API
    await fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => ip = data.ip) // Assign the fetched IP address to the `ip` variable
    .catch(error => {
      // Log the error if there is a problem fetching the IP address
      console.error('Hubo un error al obtener la IP:', error)
      ip = ''; // Set the IP address to an empty string if there is an error
    });
    return ip; // Return the IP address
  },
};

export default ipAddressService; // Export the `ipAddressService` object
