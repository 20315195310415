import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'

import { MainTitle } from '../../../../commons/components/Title/MainTitle'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import IconPencil from '../../../../commons/icons/IconPencil'
import userPersonService from '../../../../services/userPerson'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import eventService from '../../../../services/event'
/* import { GeneralInformationUpdate } from './components/tabs/GeneralInformationUpdate'
import { AdditionalInformationUpdate } from './components/tabs/AdditionalInformationUpdate' */
import { format, parseISO } from 'date-fns'
import assortmentService from '../../../../services/assortment'
import { AssortmentDetail } from '../components/seccion/AssortmentDetail'
/* import { ModalSuccess } from './components/modals/ModalSuccess'
import { ModalCancel } from './components/modals/ModalCancel'
import { ModalAlert } from './components/modals/ModalAlert'
 */
/* import { GeneralDataUpdate } from '../components/tabCreate/GeneralDataUpdate'
import { RolesProvidersUpdate } from '../components/tabCreate/RolesProvidersUpdate' */

/* const updateAssortmentSchema = Yup.object().shape({

    sku: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('sku del surtido es requerido')
        .matches(/^[ A-Za-záéíóúÁÉÍÓÚüÜñÑ]+$/, 'El sku solo debe contener letras'),
    name: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('Nombre del surtido es requerido'),
    code_as400: Yup.string()
        .max(140, 'Máximo 140 caracteres'),
    // .required('El ID-AS400 es requerido')
    // .matches(/^[ A-Za-záéíóúÁÉÍÓÚüÜñÑ]+$/, 'El ID-AS400 solo debe contener letras')
    unit_measure_id: Yup.string(),
    end_date: Yup.string(),
    event_society_id: Yup.number()
        .required('Sociedad del surtido es requerido'),
    status: Yup.string()
        .required('Estado del surtido es requerido'),
}) */


export const ViewEditAssortments = () => {

    const params = useParams()
    const { id } = params;

    const queryClient = useQueryClient();
    const navigation = useNavigate()


    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [showModalCancel, setShowModalCancel] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [showModalAlert, setShowModalAlert] = useState(false)



    const { data: assortment, isLoading, isError, error: errorQuery } = useQuery(['assortmentView', id],
        () => assortmentService.show(+id!)
    );

    const [formDisabled, setFormDisabled] = useState(true)



    const methods = useForm({
        // resolver: yupResolver(updateAssortmentSchema),
        defaultValues: {
            sku: assortment?.data?.result?.sku || '',
            name: assortment?.data?.result?.name || '',
            brand: assortment?.data?.result?.brand,
            ruc: assortment?.data?.result?.entity_supplier.ruc,
            line: assortment?.data.result.line,
            master_package: assortment?.data?.result?.master_package,
            business_name: assortment?.data?.result?.entity_supplier.business_name,
            unit_measure_id: assortment?.data?.result?.unit_measure_id.toString(),
            code_as400: assortment?.data?.result?.content_event.code_as400,
            taxonomy_category_id: assortment?.data?.result?.taxonomy_category_id,
            status: assortment?.data?.result?.status?.toString() || '',
        }
    })

    const { handleSubmit, reset, formState, setValue } = methods;
    console.log(formState.errors);
    useEffect(() => {
        if (!isLoading && assortment) {
            setValue('sku', assortment.data.result.sku);
            setValue('name', assortment.data.result.name);
            setValue('brand', assortment.data.result.brand);
            setValue('ruc', assortment.data.result.entity_supplier.ruc);
            setValue('line', assortment.data.result.line);
            setValue('master_package', assortment.data.result.master_package);
            setValue('business_name', assortment.data.result.entity_supplier.business_name);
            setValue('unit_measure_id', assortment.data.result.unit_measure_id.toString());
            setValue('code_as400', assortment.data.result.content_event.code_as400);
            setValue('taxonomy_category_id', assortment.data.result.taxonomy_category_id);
            setValue('status', assortment.data.result.status.toString());

        }
    }, [assortment, isLoading]);

    /*  const { clearErrors, control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields, isSubmitting, isValid }, setError } = useForm(); */
    const userMutation = useMutation({
        mutationFn: eventService.update,
        onSuccess: () => {
            console.log("Se realizo los cambios");
            setShowModalSuccess(false)
            // Invalida la caché de la consulta de usuarios para forzar una recarga.
            queryClient.invalidateQueries('eventList');
            navigation('/surtidos/listar')
        },
        onError: (e: any) => {
            setShowModalError(true)
            /* if (e.response.status) {
              
            } else {
               
            } */


        }
    })

    const onSubmitBack = () => {
        navigation(-1)
        reset()
    }

    const onSubmit = (data: any) => {
        userMutation.mutate({ ...data, id: id });
    };

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (isError) {
        return <div>Error al cargar los usuarios</div>;
    }



    const onHandleEdit = () => {
        setFormDisabled(false)
    }


    return (
        <div className="">
            <div className="d-flex justify-content-between align-items-center">
                <MainTitle
                    disabled={formDisabled}
                    title='Ver detalle de surtido'
                    editTitle='Editar surtido'
                    icon />
                {/*    {
                    formDisabled && <button
                        onClick={onHandleEdit}
                        type='button'
                        className="btn btn-primary btn-outline-primary me-2 d-flex align-items-center gap-3">
                        <IconPencil />
                        Editar surtido
                    </button>
                } */}

            </div>

            {/*  <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x ms-3  mt-5 mb-7 fs-6">
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_4"
                    >
                        Información general
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_5"
                    >
                        Información adicional
                    </a>
                </li>

            </ul> */}
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {/*  <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_4"
                            role="tabpanel"
                        >
                              <GeneralInformationUpdate formDisabled={formDisabled} />
                            sd
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                            
                            <AdditionalInformationUpdate formDisabled={formDisabled} />
                            dsa
                        </div>

                    </div> */}

                    <AssortmentDetail />

                    {!formDisabled && (
                        <div className="container mt-15 mb-5">
                            <div className="row justify-content-center">
                                <div className="col-auto">

                                    <button
                                        type='button'
                                        onClick={() => setShowModalCancel(true)}
                                        className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
                                        Cancelar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            //const data = methods.getValues()

                                        }}
                                        type='button'
                                        className="btn btn-primary me-2  d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
                                        Guardar cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </form>
            </FormProvider>
            {/* <ModalSuccess onSubmit={handleSubmit(onSubmit)} show={showModalSuccess} onHide={() => setShowModalSuccess(false)} />
            <ModalCancel onSubmit={() => onSubmitBack} show={showModalCancel} onHide={() => setShowModalCancel(false)} />
            <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} /> */}

        </div>
    )
}
