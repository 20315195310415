import React, { useState, useRef } from 'react';


import Button from 'react-bootstrap/Button';

import { Popover, Overlay } from 'react-bootstrap';
import { IconDotsVertical } from '../../../../../commons/icons';

interface IProps {
  children: JSX.Element
}
const DropdownIcon = ({ children }: IProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef(null);

  const showDropdownMenu = () => {
    setShowDropdown(true);
  };

  const hideDropdownMenu = () => {
    setShowDropdown(false);
  };

  return (
    <div>
      <div className='btn btn-outline btn-outline-primary ' onClick={showDropdownMenu} ref={buttonRef}>
        <IconDotsVertical stroke='#00A160' />
      </div>
      <Overlay
        show={showDropdown}
        placement="bottom"
        container={document.body}
        target={() => buttonRef.current}
        onHide={hideDropdownMenu}
        rootClose
      >
        {({ placement, arrowProps, show: _show, ...props }) => (
          <Popover id="dropdown-popover" {...props}>
            <div className="dropdown-content  w-100 h-100 pb-4">
              {children}
            </div>
          </Popover>
        )}
      </Overlay>
    </div>
  );
}

export default DropdownIcon;