import clsx from 'clsx'
import React, { FC } from 'react'

interface IProps {
  page?: number | string
  currentPage: number | undefined
  setPage: (state: number, cb?: ((state: number) => void) | undefined) => void
}

export const PaginationItem: FC<IProps> = ({ currentPage, setPage, page }) => {
  const active = currentPage === page ? 'active' : ''

  return (
    <li
      onClick={() => typeof page !== 'string' && setPage(page!)}
      className={clsx('page-item', active)}
    >
      <div style={{ cursor: 'pointer' }} className='page-link'>
        {page}
      </div>
    </li>
  )
}
