import * as React from "react"
import { SVGProps } from "react"
const IconTrash = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={24}
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.1665 6.16667L18.1546 20.3329C18.0674 21.554 17.0514 22.5 15.8272 22.5H6.17245C4.94829 22.5 3.93226 21.554 3.84505 20.3329L2.83317 6.16667M8.6665 10.8333V17.8333M13.3332 10.8333V17.8333M14.4998 6.16667V2.66667C14.4998 2.02233 13.9775 1.5 13.3332 1.5H8.6665C8.02217 1.5 7.49984 2.02233 7.49984 2.66667V6.16667M1.6665 6.16667H20.3332"
            stroke="#EF4444"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconTrash
