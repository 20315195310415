import React, { useState } from 'react'
import Card from '../components/Card'
import { IconQr } from '../../../../commons/icons'
import { ModalSearchDocument } from '../components/modals/ModalSearchDocument'
import { useNavigate } from 'react-router-dom'

export default function QuickSearch() {
  const [showModalSearchDoc, setshowModalSearchDoc] = useState(false)
  const navigate = useNavigate()
  return (
    <div className=''>
      <div className='title'>Búsqueda rápida de pre-pedido</div>
      <p className='fs-3 ps-6 mt-7' style={{color:'#464646'}}>Selecciona la opción de búsqueda:</p>
    <div className='ps-6 mt-8' style={{display:'flex', gap:12, width:'100%', flexDirection:'column'}}>
        <Card title='Escanear QR' background='#0CB1EB' icon={<IconQr/>} onClick={()=>{navigate('/wishlist/scanerqr')}}/>
        <span className='text-qr'>O <span className='text-qr link-green' onClick={()=>{setshowModalSearchDoc(true)}}>
          ingresa los datos del cliente
        </span></span>
    </div>

    <ModalSearchDocument show={showModalSearchDoc} onHide={() => setshowModalSearchDoc(false)} />
    </div>
  )
}
