import {SVGProps} from 'react'

const EventScheduleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M10.3333 9.16667V4.5M19.6667 9.16667V4.5M9.16667 13.8333H20.8333M6.83333 25.5H23.1667C24.4553 25.5 25.5 24.4553 25.5 23.1667V9.16667C25.5 7.878 24.4553 6.83333 23.1667 6.83333H6.83333C5.54467 6.83333 4.5 7.878 4.5 9.16667V23.1667C4.5 24.4553 5.54467 25.5 6.83333 25.5Z'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EventScheduleIcon
