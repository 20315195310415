import {IOrderList, OrderListParameters} from '../../commons/interfaces/order/orderList'
import {IOrderShow} from '../../commons/interfaces/order/orderView'
import api from '../../config/axios'

const order_api_service = 'api/generated/order'

const orderService = {
  searchByDocument: async (filters = {} as any) => {
    try {
      const response = await api.get<any>(order_api_service, filters)
      return response
    } catch (error) {
      throw error
    }
  },
  list: async (
    page: number | string,
    limit: number | string,
    search: string,
    params: OrderListParameters = {}
  ) => {
    try {
      let paramsToquery = `?page=${page}&limit=${limit}&search=${search ?? ''}`
      if (params.entity_customer_document_type)
        paramsToquery =
          paramsToquery + `&entity_customer_document_type=${params.entity_customer_document_type}`
      if (params.entity_customer_document_number)
        paramsToquery =
          paramsToquery +
          `&entity_customer_document_number=${params.entity_customer_document_number}`
      if (params.entity_customer_name)
        paramsToquery = paramsToquery + `&entity_customer_name=${params.entity_customer_name}`
      if (params.content_event_id)
        paramsToquery = paramsToquery + `&content_event_id=${params.content_event_id}`
      if (params.content_event_code)
        paramsToquery = paramsToquery + `&content_event_code=${params.content_event_code}`
      if (params.content_event_code_as400)
        paramsToquery =
          paramsToquery + `&content_event_code_as400=${params.content_event_code_as400}`
      if (params.content_event_start_date)
        paramsToquery =
          paramsToquery + `&content_event_start_date=${params.content_event_start_date}`
      if (params.content_event_end_date)
        paramsToquery = paramsToquery + `&content_event_end_date=${params.content_event_end_date}`
      if (params.content_event_name)
        paramsToquery = paramsToquery + `&content_event_name=${params.content_event_name}`
      const response = await api.get<IOrderList>(`${order_api_service}/list${paramsToquery}`)
      return response
    } catch (error) {
      throw error
    }
  },
  show: async (id: any) => {
    try {
      const response = await api.get<IOrderShow>(`${order_api_service}/show?id=${id}`)
      return response
    } catch (error) {
      throw error
    }
  },
}

export default orderService
