export const GeneralDataDefault = {
  document_type: '',
  document_number: '',
  firstname: '',
  lastname: '',
  event_name: '',
  start_date: '',
  end_date: '',
  status: '',
}

export const columnCodes = {
  SKU: 'sku',
  NAME: 'name',
  BRAND: 'brand',
  QUANTITY: 'quantity',
  UNIT_MEASURE: 'unit_measure',
  CONVERSION_UNIT: 'conversion_unit',
  LINE: 'line',
  SUPPLIER: 'supplier',
}

export const columns = [
  {label: 'Código de SKU', code: columnCodes.SKU, width: 150},
  {label: 'Nombre del SKU', code: columnCodes.NAME, width: 166},
  {label: 'Marca del SKU', code: columnCodes.BRAND, width: 166},
  {label: 'Cantidad solicitada', code: columnCodes.QUANTITY, width: 166},
  {label: 'Unidad de Medida', code: columnCodes.UNIT_MEASURE, width: 166},
  {label: 'Equiv. en unidad master', code: columnCodes.CONVERSION_UNIT, width: 166},
  {label: 'Proveedor', code: columnCodes.SUPPLIER, width: 166},
  {label: 'Linea', code: columnCodes.LINE, width: 166},
]

export const headersPdf = [
  'Código de SKU',
  'Nombre del SKU',
  'Marca del SKU',
  'Cantidad solicitada',
  'Unidad de medida',
  'Equiv. en unidad master',
  'Proveedor del SKU',
  'Linea'
]