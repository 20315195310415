/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useNavigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { AuthLayout } from '../modules/auth/AuthLayout'
import { FirstPasswordChange } from '../modules/auth/components/FirstPasswordChange'
import { RecoverPassword } from '../modules/auth/components/RecoverPassword'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  // const { currentUser } = useAuth()

  const { userLogin, isAuthenticated } = useSelector((state: RootState) => state.auth)
  const renew_password = userLogin?.renew_password

  const getRouteInitial = () => {
    if (userLogin?.admin == 1) return 'configuration/users'
    return userLogin?.capability_permissions.some((item) => item == 'content_event_view')
      ? 'eventos/listar'
      : userLogin?.capability_permissions.some((item) => item == 'entity_customer_attendance')
        ? 'clientes/registro-de-asistencia'
        : userLogin?.capability_permissions.some((item) => item == 'purchase_cart_list')
          ? 'wishlist/listar'
          : userLogin?.capability_permissions.some((item) => item == 'purchase_cart_view')
            ? 'wishlist/busqueda'
            : '/error/403'
  }

  /* crear un store del auth */
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route
                index
                element={
                  <Navigate
                    to={getRouteInitial()}
                  />
                }
              />
              <Route path='auth/*' element={<Navigate to='/' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
