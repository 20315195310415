import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SVG from 'react-inlinesvg'
import { IconCheckCircle, IconExit } from '../../../../../../commons/icons';


export const ModalSuccess = (props) => {
    /*  onSubmit={methods.handleSubmit(onSubmit)} */

    const onSubmitModal = () => {
        props.onSubmit()
        props.onHide()
    }


    return (
        <Modal
            {...props}
            dialogClassName='bs-modal-width'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            {/*  <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="position-relative h-auto">
                    <div className="">
                        <div className="d-flex flex-column align-items-center">
                            <IconCheckCircle />
                            <h4 className='fs-2 px-15 text-center text-label my-9'>¿Estás seguro que deseas guardar los cambios?</h4>
                            {/*                             <p className='text-label fw-light  mt-1'>
                                Se volvió a enviar el enlace de recuperación.
                            </p> */}
                        </div>


                        <div className="d-grid gap-2 mt-5 ">
                            <button
                                type='button'
                                id='kt_sign_in_submit'
                                className='btn btn-primary '
                                onClick={onSubmitModal}
                            /*  disabled={isSubmitting || !isValid} */
                            >
                                Sí, guardar
                            </button>
                        </div>

                        <div className="d-grid gap-2 mt-5 ">
                            <button
                                type='button'
                                id='kt_sign_in_submit'
                                className='button-output'
                                onClick={props.onHide}
                            /*  disabled={isSubmitting || !isValid} */
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>

                    <div onClick={props.onHide} className="position-absolute top-0 end-0 cursor-pointer">
                        <IconExit />
                    </div>


                </div>

            </Modal.Body>

        </Modal >
    )
}




