import { SVGProps } from 'react'
import {Link} from 'react-router-dom'

interface IProps {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  title: string
  color: string
  link: string
}

const PageSetupCard = (props: IProps) => {
  const {icon, title, color, link} = props
  return (
    <Link className='card' style={{backgroundColor: color, minHeight: '150px'}} to={link}>
      <div className='card-body d-flex flex-column gap-4 justify-content-end'>
        {icon({})}
        <div
          style={{
            color: '#fff',
            fontSize: '20px',
            lineHeight: '28px',
          }}
        >
          {title}
        </div>
      </div>
    </Link>
  )
}

export default PageSetupCard
