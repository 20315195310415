/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
//import { useAuth } from '../core/Auth'
import SVG from 'react-inlinesvg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store/store'
import {ModalRecoverPassword} from './ModalRecoverPassword'
import useAuth from '../hooks/useAuth'
//import useAuth from '../hooks/useAuth'

export function RecoverPassword() {
  const {
    forms: {recoveryPasswordForm},
    mutations: {recoveryPasswordMutation},
  } = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [isFocused, setIsFocused] = useState<string>('')
  const {data} = useSelector((state: RootState) => state.password)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  const handleModalResend = () => {
    recoveryPasswordMutation.mutate(recoveryPasswordForm.getValues())

    setSeconds(50)
    setShowModal(true)
  }

  return (
    <div>
      {!recoveryPasswordMutation.isSuccess && seconds==0 ? (
        <form
          className='form w-100 '
          onSubmit={recoveryPasswordForm.handleSubmit(() =>
            recoveryPasswordMutation.mutate(recoveryPasswordForm.getValues())
          )}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-left mb-11'>
            <h1 className='tw-neutral-900 fw-bolder title '>Recupera tu contraseña</h1>
            <div className='tw-neutral-500 fw-lighter fs-4'>
              Ingresa tu usuario para validar tu cuenta.
            </div>
          </div>

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 tw-neutral-800'>Usuario</label>
            <div className='position-relative'>
              <input
                placeholder='Ingresa tu usuario'
                {...recoveryPasswordForm.register('username')}
                className={clsx(
                  'form-control bg-transparent ps-10  ps-10 ',

                  {
                    'is-invalid': recoveryPasswordForm.formState.errors.username,
                  },
                  {
                    'is-valid':
                      isFocused === 'username' && !recoveryPasswordForm.formState.errors.username,
                  }
                )}
                onFocus={() => setIsFocused('username')}
                onBlur={() => setIsFocused('')}
                type='username'
                name='username'
                autoComplete='off'
              />
              {recoveryPasswordForm.formState.errors.username && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {recoveryPasswordForm.formState.errors.username.message}
                  </span>
                </div>
              )}
              <div style={{top: '10px', left: '10px'}} className='position-absolute'>
                <SVG src={toAbsoluteUrl('/media/svg/login/user.svg')} />
              </div>
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='d-grid mt-5 mb-6'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={recoveryPasswordMutation.isLoading}
            >
              {!recoveryPasswordMutation.isLoading && (
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                  <span className='indicator-label'>Continuar</span>
                </div>
              )}
              {recoveryPasswordMutation.isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Cargando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      ) : (
        <>
          <form
            className='form w-100 '
            /*   onSubmit={handleSubmit(onSubmit)} */
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-left mb-5'>
              <h1 className='tw-neutral-900 fw-bolder title '>Enlace enviado</h1>
              <div className='tw-neutral-500 fw-lighter fs-4'>
                Hemos enviado un enlace de recuperación de contraseña a:
              </div>
            </div>

            <div className='text-left mb-5 '>
              <div className='tw-neutral-500 fw-medium fs-4'>{data?.email}</div>
            </div>

            <div
              onClick={() => {
                if (seconds === 0) {
                  handleModalResend()
                }
              }}
              className={clsx(
                'd-flex gap-2 align-items-center text-left mb-5  opacity-100 cursor-pointer'
              )}
              style={{width:'fit-contend'}}
            >
              <SVG src={toAbsoluteUrl('/media/svg/recovery-password/email.svg')} />
              {seconds > 0 ? (
                <div className='text-primary fw-medium fs-5 '>Volver a enviar en {seconds}</div>
              ) : (
                <div className='text-primary fw-medium fs-5 '>Volver a enviar</div>
              )}
            </div>

            {/* begin::Action */}
            <div className='d-grid mt-5'>
              <Link
                to={'/auth'}
                id='kt_sign_in_submit'
                className='btn btn-primary'
                /*  disabled={isSubmitting || !isValid} */
              >
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                  <span className='indicator-label'>Ir al inicio de sesión</span>
                </div>
              </Link>
            </div>
            {/* end::Action */}
          </form>

         <ModalRecoverPassword show={showModal} onHide={() => setShowModal(false)} />
        </>
      )}
    </div>
  )
}
