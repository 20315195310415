// wishlistconfirmSlice.js

import { createSlice } from '@reduxjs/toolkit';

const wishlistconfirmSlice = createSlice({
  name: 'wishlistconfirm',
  initialState: { response: null },
  reducers: {
    setResponse: (state, action) => {
      state.response = action.payload;
    },
  },
});

export const { setResponse } = wishlistconfirmSlice.actions;
export default wishlistconfirmSlice.reducer;
