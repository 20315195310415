import { EventBanner } from "../../../../commons/interfaces/event/eventBanner.interface";

export const defaultBannerForm: EventBanner = {
  management_file_public: [],
  url: '',
  content: '',
  milliseconds: '',
  status: 1,
}

export const bannerFormRules = {
  management_file_public: { required: true },
  content: { required: true },
}