import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResetNotify } from '../../commons/interfaces/password/resetNotify';

export interface IPasswordState {
  status: string  |null
  message: string | null
  data: Data | null
}

export interface Data {
  email: string 
}
const initialState: IPasswordState = {
  status: null,
  message:null,
  data: null 

};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    notify: (state, action:PayloadAction<IResetNotify>) => {
        state.data = action.payload.result
        state.message =action.payload.message
        state.status = action.payload.status

    }

  },
});




export const { notify } = passwordSlice.actions

export default passwordSlice.reducer;
