import { addDays, format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range';
import { useFormContext } from 'react-hook-form';
import eventService from '../../../services/event';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import * as locales from 'react-date-range/dist/locale';
import { Popover, Overlay } from 'react-bootstrap';

interface IProps {
    formDisabled?: boolean;
    start_date?: string;
    end_date?: string;
    visible: boolean
}
export const DatePickerFilter = ({ start_date, end_date, formDisabled, visible }: IProps) => {

    /*     const params = useParams()
    
        const { id } = params;
        const { data: event, isLoading, isError, error: errorQuery } = useQuery(['eventView', id],
            () => eventService.show(+id!)
        );
     */


    const fechaISOStart = start_date && parseISO(start_date);
    const fechaISOEnd = end_date && parseISO(end_date);

    const [dateRange, setDateRange] = useState({
        startDate: fechaISOStart ? fechaISOStart : new Date(),
        endDate: fechaISOEnd ? fechaISOEnd : new Date(),
        key: 'selection',
    });

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTextInput, setShowTextInput] = useState(visible);
    const [showDropdown, setShowDropdown] = useState(false);
    const buttonRef = useRef(null);
    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext()

    const handleDateChange = (ranges) => {
        const dateFormatInitialBackend = format(ranges.selection.startDate, 'yyyy-MM-dd');
        const dateFormatEndBackend = format(ranges.selection.endDate, 'yyyy-MM-dd');

        console.log(showTextInput);

        setValue('start_date', dateFormatInitialBackend)
        setValue('end_date', dateFormatEndBackend)
        if (start_date) {

            //  setShowTextInput(false)
        } else {
            setShowTextInput(true)

        }

        setDateRange(ranges.selection);
    };

    const handleInputClick = () => {
        setShowDatePicker(!showDatePicker);
    };


    const dateFormatInitial = format(dateRange.startDate, 'dd/MM/yyyy');
    const dateFormatEnd = format(dateRange.endDate, 'dd/MM/yyyy');

    // Agrega un event listener para cerrar el DateRange al hacer clic fuera del componente
    const showDropdownMenu = () => {
        setShowDropdown(true);
    };

    const hideDropdownMenu = () => {
        setShowDropdown(false);
    };

    useEffect(() => {
        if (!start_date) setShowTextInput(false)
        else setShowTextInput(true)
    }
        , [])

    return (
        <>
            <div className='row' onClick={showDropdownMenu} ref={buttonRef}>
                <div className="fv-row position-relative col-6">
                    <input
                        type="text"
                        className={clsx('form-control',
                            !showTextInput ? 'text-transparent' : ''
                        )}
                        value={dateFormatInitial}
                        onClick={handleInputClick}
                        readOnly
                        disabled={formDisabled}
                    />
                    <div style={{ top: '10px', right: '-3px' }} className="position-absolute ">-</div>
                </div>

                <div className="fv-row  col-6">
                    <input
                        type="text"
                        className={clsx('form-control',
                            !showTextInput ? 'text-transparent' : ''
                        )}
                        value={dateFormatEnd}
                        onClick={handleInputClick}
                        readOnly
                        disabled={formDisabled}
                    />
                </div>
                <Overlay
                    show={showDropdown}
                    placement="bottom"
                    container={document.body}
                    target={() => buttonRef.current}
                    onHide={hideDropdownMenu}
                    rootClose
                >
                    {({ placement, arrowProps, show: _show, ...props }) => (
                        <Popover className='' id="dropdown-popover" {...props}>
                            <DateRange
                                locale={locales['es']}
                                className="w-100"
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                ranges={[dateRange]}
                                editableDateInputs={true}
                            />
                        </Popover>
                    )}
                </Overlay>
            </div>




        </>
    )
}