/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center bg-primary flex-column-fluid  h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid  w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className=' d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}

          <div className='position-relative  bg-white rounded-4 w-sm-500px p-15'>
            <Outlet />
            <div style={{top: -100, right: -100}} className='position-absolute content-svg-auth'>
              <SVG src={toAbsoluteUrl('/media/svg/login/texture-circle.svg')} />
            </div>
          </div>

          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      <div className='position-absolute bottom-0 start-0 '>
        <SVG src={toAbsoluteUrl('/media/svg/login/dots.svg')} />
      </div>
      <div style={{top: 30, left: 30}} className='position-absolute  content-svg-auth-botoom'>
        <img src='/media/logos/logo.png' className='w-100 h-auto' alt='logo-tailoy' />
      </div>
    </div>
  )
}

export {AuthLayout}
