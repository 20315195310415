import Modal from 'react-bootstrap/Modal'
import { IconAlertCircle, IconExit } from '../../icons'

interface IProps {
  errorType: string,
  onClose: () => void

}
export const ModalErrors = ({ errorType, onClose }: IProps) => {
  const errorMessages: Record<string, { title: string, description: string }> = {
    error_422: {
      title: '¡Ups! Algo salió mal',
      description: 'Lo sentimos, ha habido un problema con su solicitud debido a datos incorrectos o una acción en conflicto.',
    },
    error_500: {
      title: 'Error de solicitud',
      description: 'Se ha producido un error interno en el servidor, Por favor, inténtalo de nuevo mas tarde o comunícate con el soporte técnico para obtener asistencia.',
    },
    error_409: {
      title: '¡Ups! Algo salió mal',
      description: 'Lo sentimos, ha habido un problema con su solicitud debido a datos incorrectos o una acción en conflicto.',
    },
    error_general: {
      title: 'Error',
      description: 'Error desconocido. Por favor, inténtalo de nuevo.',
    },
  };



  const { title, description } = errorMessages[errorType] || errorMessages.error_general;
  console.log(errorType);

  return (
    <Modal
      show={true}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <IconAlertCircle />
              <h4 className='fs-2 px-5 text-center text-neutral-800 fw-medium mt-8'>
                {title}
              </h4>
              <p className='tw-neutral-600 fw-light text-center mt-1'>
                {description}
              </p>
            </div>
            <div className='d-grid gap-2 mt-5 '>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={onClose}
              >
                Aceptar
              </button>
            </div>
          </div>

          <div onClick={onClose} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
