import { addDays, format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { useFormContext } from 'react-hook-form';
import eventService from '../../../services/event';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import IconCalendar from '../../icons/IconCalendar';
import { Popover, Overlay } from 'react-bootstrap';


interface IProps {
    formDisabled?: boolean;
    data?: any;
    update?: boolean;

}
export const DatePicker = ({ update, data, formDisabled }: IProps) => {


    const fechaISOStart = data?.data?.result?.start_date && parseISO(data?.data?.result?.start_date);
    const fechaISOEnd = data?.data?.result?.end_date && parseISO(data?.data?.result?.end_date);

    const [dateRange, setDateRange] = useState({
        startDate: fechaISOStart ? fechaISOStart : new Date(),
        endDate: fechaISOEnd ? fechaISOEnd : new Date(),
        key: 'selection',
    });

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTextInput, setShowTextInput] = useState(update ?? false);
    const [showDropdown, setShowDropdown] = useState(false);
    const buttonRef = useRef(null);

    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext()

    const handleDateChange = (ranges) => {
        const dateFormatInitialBackend = format(ranges.selection.startDate, 'yyyy-MM-dd');
        const dateFormatEndBackend = format(ranges.selection.endDate, 'yyyy-MM-dd');


        setValue('start_date', dateFormatInitialBackend)
        setValue('end_date', dateFormatEndBackend)
        if (!update) {
            setShowTextInput(true);
        }
        setDateRange(ranges.selection);
    };

    const handleInputClick = () => {
        setShowDatePicker(!showDatePicker);
    };


    const dateFormatInitial = format(dateRange.startDate, 'dd/MM/yyyy');
    const dateFormatEnd = format(dateRange.endDate, 'dd/MM/yyyy');



    const showDropdownMenu = () => {
        setShowDropdown(true);
    };

    const hideDropdownMenu = () => {
        setShowDropdown(false);
    };



    return (
        <>
            <div className='row' ref={buttonRef}>
                <div className="fv-row col-sm-6 col-6 position-relative">
                    <label className='form-label fs-6 required text-dark'>Fecha de inicio</label>
                    <div className="position-relative " onClick={showDropdownMenu}>
                        <input
                            type="text"
                            className={clsx('form-control  ', {
                                'text-transparent': !showTextInput
                            })}
                            value={dateFormatInitial}
                            onClick={handleInputClick}
                            readOnly
                            disabled={formDisabled}

                        />
                        <button
                            disabled
                            style={{ border: 'none', right: '0px', top: '-1px', position: 'absolute', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                            className={clsx('p-4 ', formDisabled ? 'bg-gray-400' : 'bg-primary')}>
                            <IconCalendar />
                        </button>
                    </div>
                </div>

                <div className="fv-row col-sm-6 col-6 ">
                    <label className='form-label fs-6 required text-dark'>Fecha de fin</label>
                    <div className="position-relative" onClick={showDropdownMenu}>
                        <input
                            type="text"
                            className={clsx('form-control',
                                showTextInput ? '' : 'text-transparent'
                            )}
                            value={dateFormatEnd}
                            onClick={handleInputClick}
                            readOnly
                            disabled={formDisabled}
                        />
                        <button
                            disabled
                            style={{ border: 'none', right: '0px', top: '-1px', position: 'absolute', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                            className={clsx('p-4 ', formDisabled ? 'bg-gray-400' : 'bg-primary')}>
                            <IconCalendar />
                        </button>
                    </div>

                </div>
                <Overlay
                    show={showDropdown}
                    placement="bottom"
                    container={document.body}
                    target={() => buttonRef.current}
                    onHide={hideDropdownMenu}
                    rootClose
                >
                    {({ placement, arrowProps, show: _show, ...props }) => (
                        <Popover className='' id="dropdown-popover" {...props}>
                            <DateRange
                                locale={locales['es']}
                                className="w-100"
                                onChange={handleDateChange}
                                moveRangeOnFirstSelection={false}
                                ranges={[dateRange]}
                                editableDateInputs={true}
                                minDate={new Date()}
                            />
                        </Popover>
                    )}
                </Overlay>
            </div>




        </>
    )
}
