import React from 'react';
import { Modal } from 'react-bootstrap';
import { IAssortmentSync } from '../../../../../commons/interfaces/assortment/AssortmentSync';
import { IconAlertCircle, IconCheckCircle, IconExit } from '../../../../../commons/icons';

interface IProps {
    show: boolean;
    loading: boolean;
    onClose: () => void;
    data?: IAssortmentSync
}
const ModalSync = ({ data, show, loading, onClose }: IProps) => {

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName='bs-modal-width'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >

            <Modal.Body>
                {loading ? (
                    <div className='position-relative h-auto'>
                        <div className='mt-2'>
                            <div className='d-flex flex-column align-items-center'>
                                <span className='spinner-border border-5 text-primary spinner-border align-middle ms-2'
                                    style={{
                                        width: '5rem',
                                        height: '5rem'
                                    }}
                                ></span>
                                <h4 className='fs-2 px-5 mb-5 text-center text-neutral-800 fw-medium mt-8'>
                                    Sincronizando productos
                                </h4>


                                <p className='tw-neutral-600 fw-light text-center mt-1 px-10'>
                                    Por favor, espera mientras se sincronizan los productos
                                </p>
                            </div>

                        </div>

                    </div>


                ) : data?.code === 200 ? (
                    <div className='position-relative h-auto'>
                        <div className='mt-2'>
                            <div className='d-flex flex-column align-items-center'>
                                <IconCheckCircle />
                                <h4 className='fs-2 px-5 text-center text-neutral-800 fw-medium mt-8'>
                                    Sincronización completada
                                </h4>
                                <p className='tw-neutral-600 fw-light text-center mt-1'>
                                    Los productos se han sincronizado correctamente
                                </p>
                            </div>
                            <div className='d-grid gap-2 mt-6 '>
                                <button
                                    type='button'
                                    id='kt_sign_in_submit'
                                    className='btn btn-primary'
                                    onClick={onClose}
                                >
                                    Aceptar
                                </button>
                            </div>
                        </div>

                        <div onClick={onClose} className='position-absolute top-0 end-0 cursor-pointer'>
                            <IconExit fill='#7C7C7C' width={14} height={14} />
                        </div>
                    </div>
                )
                    : <>
                        <div className='position-relative h-auto'>
                            <div className='mt-2'>
                                <div className='d-flex flex-column align-items-center'>
                                    <IconAlertCircle />
                                    <h4 className='fs-2 px-5 text-center text-neutral-800 fw-medium mt-8'>
                                        ¡Error!
                                    </h4>
                                    <p className='tw-neutral-600 fw-light text-center mt-1 px-2 '>
                                        Error al sincronizar los productos por favor intente nuevamente
                                    </p>
                                </div>
                                <div className='d-grid gap-2 mt-6 '>
                                    <button
                                        type='button'
                                        id='kt_sign_in_submit'
                                        className='btn btn-primary'
                                        onClick={onClose}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </div>

                            <div onClick={onClose} className='position-absolute top-0 end-0 cursor-pointer'>
                                <IconExit fill='#7C7C7C' width={14} height={14} />
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    );
};

export default ModalSync;
