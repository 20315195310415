import Modal from 'react-bootstrap/Modal'
import {IconAlertCircle, IconExit} from '../../../../commons/icons'

export const ModalPasswordExpired = (props) => {
  return (
    <Modal
      {...props}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <IconAlertCircle />
              <h4 className='fs-2 text-label tw-neutral-800 mt-9'>Contraseña caducada.</h4>
              <p className='tw-neutral-600 fs-6 fw-light text-center mt-2'>
                Tu contraseña ha caducado.
              </p>
            </div>

            <div className='d-grid gap-2 mt-8'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary '
                onClick={props.onHide}
              >
                Recuperar contraseña
              </button>
            </div>
          </div>

          <div onClick={props.onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
