/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useLayoutEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import useAuth from '../hooks/useAuth'
import {ModalPasswordExpired} from './ModalPasswordExpired'

export const ChangePassword = ({user, token}) => {
  const {
    forms: {changePasswordForm},
    mutations: {validateTokenMutation, resetPasswordMutation},
  } = useAuth()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(true)
  const [showNewPassword, setShowNewPassword] = useState(true)
  const [isFocused, setIsFocused] = useState<string>('')

  useEffect(() => {
    validateTokenMutation.mutate({username: user, token})
  }, [user, token])

  useLayoutEffect(() => {
    if (changePasswordForm.formState.isSubmitted) {
      if (
        changePasswordForm.watch('password') !== changePasswordForm.watch('password_confirmation')
      ) {
        changePasswordForm.setError('password_confirmation', {
          message: 'Las contraseñas no coinciden.',
        })
      } else {
        changePasswordForm.clearErrors('password_confirmation')
      }
    }
  }, [changePasswordForm.watch('password'), changePasswordForm.watch('password_confirmation')])

  return (
    <form
      className='form w-100 '
      onSubmit={changePasswordForm.handleSubmit(() =>
        resetPasswordMutation.mutate({...changePasswordForm.getValues(), token, username: user})
      )}
      noValidate
    >
      {/* begin::Heading */}

      {!resetPasswordMutation.isSuccess ? (
        <>
          <div className='text-left mb-11'>
            <h1 className='tw-neutral-900 fw-bolder mb-3 title '>Recupera tu contraseña</h1>
            <div className='tw-neutral-500 fw-lighter fs-4'>Ingresa una nueva contraseña.</div>
          </div>

          <div className='fv-row mb-6'>
            <label className='form-label fw-medium tw-neutral-800 fs-6 '>Nueva contraseña</label>
            <div className='position-relative'>
              <input
                type={`${showPassword ? 'password' : 'text'}`}
                autoComplete='off'
                placeholder='Ingresa tu nueva contraseña'
                {...changePasswordForm.register('password')}
                onFocus={() => setIsFocused('password')}
                onBlur={() => setIsFocused('')}
                className={clsx(
                  'form-control form-control-auth bg-transparent ps-10 ',
                  {
                    'is-invalid': changePasswordForm.formState.errors.password,
                  },
                  {
                    'is-valid':
                      isFocused === 'password' && !changePasswordForm.formState.errors.password,
                  }
                )}
              />

              <div style={{top: '12px', left: '15px'}} className='position-absolute'>
                <i className='bi bi-key text-primary fs-2'></i>{' '}
              </div>
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{top: '50%', right: '15px', transform: 'translateY(-50%)'}}
                className='position-absolute'
              >
                <SVG
                  style={{
                    cursor: 'pointer',
                  }}
                  src={toAbsoluteUrl('/media/svg/login/eye.svg')}
                />
              </div>
            </div>
            {changePasswordForm.formState.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-danger' role='alert'>
                    {changePasswordForm.formState.errors.password.message}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='form-label fw-medium tw-neutral-800 fs-6 '>
              Confirma tu nueva contraseña
            </label>
            <div className='position-relative'>
              <input
                type={`${showNewPassword ? 'password' : 'text'}`}
                placeholder='Confirma tu nueva contraseña'
                autoComplete='off'
                {...changePasswordForm.register('password_confirmation')}
                onFocus={() => setIsFocused('password_confirmation')}
                onBlur={() => setIsFocused('')}
                className={clsx(
                  'form-control form-control-auth bg-transparent ps-10 ',
                  {
                    'is-invalid': changePasswordForm.formState.errors.password_confirmation,
                  },
                  {
                    'is-valid':
                      isFocused === 'password_confirmation' &&
                      !changePasswordForm.formState.errors.password_confirmation,
                  }
                )}
              />

              <div style={{top: '12px', left: '15px'}} className='position-absolute'>
                <i className='bi bi-key text-primary fs-2'></i>{' '}
              </div>
              <div
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{top: '50%', right: '15px', transform: 'translateY(-50%)'}}
                className='position-absolute'
              >
                <SVG
                  style={{
                    cursor: 'pointer',
                  }}
                  src={toAbsoluteUrl('/media/svg/login/eye.svg')}
                />
              </div>
            </div>
            {changePasswordForm.formState.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {changePasswordForm.formState.errors.password_confirmation.message}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='mt-8 mb-10'>
            <span className='tw-neutral-500 fw-lighter'>
              Tu contraseña debe contener mínimo 8 caracteres, una mayúscula, una mínuscula y un
              número.
            </span>
          </div>

          <div className='d-grid mt-5'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={resetPasswordMutation.isLoading}
            >
              {!resetPasswordMutation.isLoading && (
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                  <span className='indicator-label'>Continuar</span>
                </div>
              )}
              {resetPasswordMutation.isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Cargando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='text-left mb-11'>
            <h1 className='tw-neutral-800 fw-bolder mb-4 title '>Recupera tu contraseña</h1>
            <div className='tw-neutral-500 fw-lighter fs-4'>
              Tu contraseña se ha actualizado correctamente. Intenta iniciar sesión con tus nuevas
              credenciales.
            </div>
          </div>

          <div className='d-grid mt-5'>
            <Link to={'/auth'} id='kt_sign_in_submit' className='btn btn-primary'>
              <div className='d-flex gap-2 align-items-center justify-content-center'>
                <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                <span className='indicator-label'>Continuar</span>
              </div>
            </Link>
          </div>
        </>
      )}
      {resetPasswordMutation.isError && (
        <ModalPasswordExpired
          show={true}
          onHide={() => {
            navigate('/auth/recover-password', {replace: true})
          }}
        />
      )}
      {/* end::Action */}
    </form>
  )
}
