import Card from '../components/Card'
import {IconQr} from '../../../../commons/icons'
import {useNavigate} from 'react-router-dom'

const QuickGiftSearch = () => {
  const navigate = useNavigate()
  return (
    <div className=''>
      <div className='title'>Canje de regalo</div>
      <div
        className='ps-6 mt-8'
        style={{display: 'flex', gap: 12, width: '100%', flexDirection: 'column'}}
      >
        <Card
          title='Escanear QR'
          background='#72BF44'
          icon={<IconQr />}
          onClick={() => {
            navigate('/regalos/scanerqr')
          }}
        />
        <span className='text-qr'>
          O{' '}
          <span
            className='text-qr link-green'
            onClick={() => {
              navigate('/regalos/busqueda-cliente')
            }}
          >
            ingresa los datos del cliente
          </span>
        </span>
      </div>
    </div>
  )
}

export default QuickGiftSearch
