import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import wishlistService from '../../../../../services/whislist'
import { useAuth } from '../../../auth'
import eventService from '../../../../../services/event'
import clsx from 'clsx'
import {
  IconAlertCircle,
  IconCheck,
  IconCheckCircle,
  IconSearch2,
} from '../../../../../commons/icons'
import { useNavigate } from 'react-router-dom'
import Toast from '../Toast'
import UniqueDatePicker from '../../../../../commons/components/datePicker/UniqueDatePicker'
import { validateCustumer } from '../../../../../services/validateCustumer'
import customerService from '../../../../../services/customer'
import { useDispatch, useSelector } from 'react-redux'
import { setResponse } from '../../../../../store/wishlish/wishlistconfirmSlice'
import {
  setEntityCustomerId,
  setPaymentCondition,
  setDispatchDate,
  setContentProducts,
} from '../../../../../store/wishlish/wishlistDataSlice'

interface IProps {
  show: boolean
  onHide: () => void
  onClick?: () => void
  idClient: any
  data?: any
  dataIdProducts?: any
}

const searchClientSchema = Yup.object().shape({
  content_products: Yup.array().default([]),
  payment_condition: Yup.string().required('Método de pago es requerido'),
  entity_customer_id: Yup.number().default(1),
  dispatch_date: Yup.string().required('Fecha es requerida de pago es requerido'),
})
interface IputForm {
  content_products: any[]
  payment_condition: ''
  entity_customer_id: number
  dispatch_date: string
}

export const ModalsConfirmWishlish = ({
  show,
  onHide,
  onClick,
  idClient,
  data,
  dataIdProducts,
}: IProps) => {
  const { eventSelectedCtx } = useAuth()
  const dispatch = useDispatch()
  const response = useSelector((state: any) => state.wishlistconfirm.response)

  const { data: wishlistView } = useQuery(['wishlistView'], () => wishlistService.show(idClient))
  console.log(wishlistView);

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSuccessRedirect, setIsSuccessRedirect] = useState(false)
  const [contentEventId, setContentEventId] = useState('')
  const navigate = useNavigate()
  const onSubmitModal = () => {
    onClick && onClick()
  }
  const onNavigate = () => { }
  useEffect(() => {
    resetForm()
  }, [show])
  useEffect(() => {
    if (isError || isSuccess)
      setTimeout(() => {
        setIsError(false)
        setIsSuccess(false)
      }, 3000)
  }, [isError, isSuccess])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({})

  const onSubmit = async (data) => {
    const selectedIds: number[] = []

    dataIdProducts.result.forEach((item: any) => {
      if (item.selected === 1) {
        selectedIds.push(item.id)
      }
    })
    try {
      setLoading(true)
      const response = await wishlistService.cardConfirmWislist(
        selectedIds,
        idClient,
        watch('payment_condition')
      )
      dispatch(setEntityCustomerId(idClient))
      dispatch(setPaymentCondition(watch('payment_condition')))
      dispatch(setDispatchDate(watch('dispatch_date')))
      dispatch(setContentProducts(selectedIds))

      if (response) {
        dispatch(setResponse(response))
        setLoading(false)
      }
      navigateRouting()

      console.log(response.data.result)
    } catch (error) {
      setLoading(false)
    }
  }
  function getTomorrowDate() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const year = tomorrow.getFullYear()
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0')
    const day = String(tomorrow.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const resetForm = () => {
    reset()
    setIsSuccess(false)
    setIsError(false)
    setLoading(false)
    setIsSuccessRedirect(false)
    setContentEventId('')
  }
  const navigateRouting = () => {
    navigate(`/wishlist/detalle/${idClient}/confirmar-pedido`)
  }
  return (
    <Modal
      show={show}
      aria-labelledby='example-modal-sizes-title-lg'
      centered
      dialogClassName='bs-modal-width-md'
    >
      <Modal.Header onClick={onHide} closeButton className='border-0 pb-0 mb-0'></Modal.Header>
      <Modal.Title id='example-modal-sizes-title-lg'>
        <div className='row container m-4'>
          <h3 className='fs-1'> Buscar pre-pedido</h3>
        </div>
      </Modal.Title>
      <Modal.Body className='pt-3'>
        <div className='container'>
          <form onSubmit={onSubmit}>
            <div className='row'>
              <div className='col-6 mb-6'>
                <label className='form-label fs-6 required text-dark'>Tipo de documento</label>
                <input
                  type='text'
                  id='document_type'
                  required
                  value={data ? wishlistView?.data.result.document_type : ''}
                  disabled
                  autoComplete='off'
                  className={clsx('form-control')}
                />
              </div>
              <div className='col-6  mb-6'>
                <label className='form-label fs-6 required text-dark'>Número de documento</label>
                <input
                  type='text'
                  id='document_number'
                  required
                  value={data ? wishlistView?.data.result.document_number : ''}
                  disabled
                  autoComplete='off'
                  className={clsx('form-control')}
                />
              </div>
            </div>
            <div className='row d-flex'>
              <div className='col-6 mb-6'>
                <label className='form-label fs-6 required text-dark'>Condición de pago</label>
                <select
                  id='payment_condition'
                  {...register('payment_condition')}
                  required
                  className={clsx('form-select bg-transparent', {
                    'is-invalid': errors.payment_condition,
                  })}
                >
                  <option value='CON'>Al contado</option>
                  <option value='CRE'>Crédito</option>
                </select>
              </div>{' '}
              <div className='col-6'>
                <label className='form-label fs-6 required text-dark'>Fecha de despacho </label>
                <input
                  type='date'
                  className='form-control'
                  {...register('dispatch_date')}
                  min={getTomorrowDate()}
                />

                {errors.dispatch_date && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {errors.dispatch_date.message as string}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {isError && (
              <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
                <Toast
                  title='Cliente no registrado y/o inactivo.'
                  type='warning'
                  icon={<IconAlertCircle width={24} height={24} />}
                />
              </div>
            )}

            {isSuccess && (
              <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
                <Toast
                  title='Se encontró el pre-pedido del cliente.'
                  type='success'
                  icon={<IconCheckCircle width={24} height={24} />}
                />
              </div>
            )}
          </form>
          <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
            <button
              className=' btn btn-primary'
              onClick={onSubmit}
              disabled={!watch('dispatch_date') || loading}
            >
              {loading ? (
                <span className='ml-10'>Cargando ...</span>
              ) : (
                <>
                  <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} className='me-2' />
                  <span className=''>Validar </span>
                </>
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
