import { EventScheduleGet, EventScheduleUpsert, IEventActivityPrepared } from "../../commons/interfaces/event/eventSchedule.interface";
import api from "../../config/axios";

const catalog_api_base = '/api/content/event/schedule';

const eventScheduleService = {
  show: async (id: number) => {
    try {
      const response = await api.get<EventScheduleGet>(`${catalog_api_base}/get?content_event_id=${id}`)
      // The response.data.result is an array of objects.
      if (response.data?.result?.length > 0) {
        // The map function is used to iterate over the array of objects.
        response.data.result = response.data.result.map((item) => {
          // The spread operator is used to copy the object.
          let activity: IEventActivityPrepared = {...item};
          // The split function is used to separate the date and time.
          const fragmentDateInit = item.date_init?.split(' ');
          const fragmentDateEnd = item.date_end?.split(' ');
          // The date_init and date_end properties are updated with the date and time.
          activity.date_init = fragmentDateInit?.[0];
          activity.hour_init = fragmentDateInit?.[1];
          activity.date_end = fragmentDateEnd?.[0];
          activity.hour_end = fragmentDateEnd?.[1];
          return activity;
        });
      }
      return response;
    } catch (error) {
        throw(error);
    }
  },
  upsert: async (data: EventScheduleUpsert) => {
    try {
      const response = await api.post<any>(catalog_api_base, data);
      return response;
    } catch (error) {
        throw(error);
    }
  }
}

export default eventScheduleService;