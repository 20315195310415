import * as React from "react"
import { SVGProps } from "react"
const IconTicket = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={15}
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.6826 1.23828V3.02717M11.6826 6.60495V8.39384M11.6826 11.9716V13.7605M2.73811 1.23828C1.75013 1.23828 0.949219 2.03919 0.949219 3.02717V5.7105C1.93719 5.7105 2.73811 6.51142 2.73811 7.49939C2.73811 8.48737 1.93719 9.28828 0.949219 9.28828V11.9716C0.949219 12.9596 1.75013 13.7605 2.73811 13.7605H15.2603C16.2483 13.7605 17.0492 12.9596 17.0492 11.9716V9.28828C16.0612 9.28828 15.2603 8.48737 15.2603 7.49939C15.2603 6.51142 16.0612 5.7105 17.0492 5.7105V3.02717C17.0492 2.03919 16.2483 1.23828 15.2603 1.23828H2.73811Z"
            stroke={props.stroke ?? "#989898"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconTicket