import { useParams } from 'react-router-dom'
import {
  IconEye,
  IEventBlockInformation,
  IEventBanner,
  IEventSchedule,
  IEventGallery,
  IEventMap,
  IEventCatalogue,
} from '../../../../../../commons/icons'
import PageSetupCard from '../cards/PageSetupCard'
import { getAuthToken } from '../../../../../../config/axios';

interface IProps {
  url: string
}

const landingDomain = process.env.REACT_APP_LANDING_DOMAIN;

const EventPageSettings = ({url}: IProps) => {
  const params = useParams();
  //Get the token
  const authToken = getAuthToken();
  //Build the url of the landing page
  const landingUrl = `${landingDomain}/preview/home/${url}/${authToken}`;
  const { id } = params;
  const configurationCardData = [
    {
      title: 'Bloques informativos',
      icon: IEventBlockInformation,
      color: '#72BF44',
      link: `/eventos/${id}/bloques-informativos`,
    },
    {
      title: 'Banners',
      icon: IEventBanner,
      color: '#F58220',
      link: `/eventos/${id}/banners`,
    },
    {
      title: 'Agenda',
      icon: IEventSchedule,
      color: '#0076CB',
      link: `/eventos/${id}/agenda`,
    },
    {
      title: 'Galería',
      icon: IEventGallery,
      color: '#8542DB',
      link: `/eventos/${id}/galeria`,
    },
    {
      title: 'Mapa del evento',
      icon: IEventMap,
      color: '#0CB1EB',
      link: `/eventos/${id}/mapa`,
    },
    {
      title: 'Catálogo del evento',
      icon: IEventCatalogue,
      color: '#F73C43',
      link: `/eventos/${id}/catalogo`,
    },
  ]

  return (
    <div className='d-flex row mt-6 ms-md-4 mb-6'>
      <h2 className='title-form fw-bold mb-5'>Sobre la página</h2>
      <div className='row mb-5'>
        <div className='fv-row col-12 col-md-6  mb-6 m-md-0'>
          <label className='form-label fs-6 text-dark'>URL</label>
          <input
            placeholder='URL'
            disabled={true}
            value={url}
            className='form-control'
            type='text'
            autoComplete='off'
          />
        </div>
      </div>
      <div className='col col-md-6 col-lg-6 col-xl-4 col-xxl-3'>
        <a
          className='btn btn-primary w-100 d-flex align-items-center justify-content-center col-xl-justify-content-start gap-2'
          href={landingUrl}
          rel="noreferrer"
          target='_blank'
        >
          <IconEye color='#fff' />
          Previsualizar página de evento
        </a>
      </div>
      <hr className='hr mt-6 mb-6' />
      <h2 className='title-form fw-bold mb-5'>Contenido de la página</h2>
      <div className='row'>
      {configurationCardData.map((card, index) => (
          <div className='col-12 col-md-4 mb-6' key={`card_${index}`}>
            <PageSetupCard
              title={card.title}
              icon={card.icon}
              color={card.color}
              link={card.link}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default EventPageSettings
