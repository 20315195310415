import * as React from "react"
import { SVGProps } from "react"
const IconSave = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.5 0.500021L13.5029 0.500004C13.5358 0.499814 13.5684 0.50612 13.5989 0.518561C13.6291 0.530905 13.6566 0.549048 13.6798 0.571965C13.68 0.572144 13.6802 0.572324 13.6804 0.572504L17.4275 4.31962C17.4277 4.31981 17.4279 4.31999 17.428 4.32017C17.451 4.34342 17.4691 4.37093 17.4814 4.40114C17.4939 4.4316 17.5002 4.46421 17.5 4.49712V4.50001V16.5C17.5 16.7652 17.3946 17.0196 17.2071 17.2071C17.0196 17.3946 16.7652 17.5 16.5 17.5H1.5C1.23478 17.5 0.980429 17.3946 0.792893 17.2071L0.439339 17.5607L0.792892 17.2071C0.605357 17.0196 0.5 16.7652 0.5 16.5V1.50001C0.5 1.2348 0.605357 0.980441 0.792893 0.792905C0.980429 0.605369 1.23478 0.500013 1.5 0.500013L13.5 0.500021ZM6 1.00001H5.5V1.50001V4.50001V5.00001H6H12H12.5V4.50001V1.50001V1.00001H12H6ZM12 17H12.5V16.5V10.5V10H12H6H5.5V10.5V16.5V17H6H12ZM13 16.5V17H13.5H16.5H17V16.5V4.80751V4.6004L16.8535 4.45396L13.8535 1.45396L13 0.600404V1.80751V4.50001C13 4.76523 12.8946 5.01958 12.7071 5.20712C12.5196 5.39465 12.2652 5.50001 12 5.50001H6C5.73478 5.50001 5.48043 5.39465 5.29289 5.20712C5.10535 5.01958 5 4.76522 5 4.50001V1.50001V1.00001H4.5H1.5H0.999999V1.50001V16.5V17H1.5H4.5H5V16.5V10.5C5 10.2348 5.10535 9.98044 5.29289 9.7929C5.48043 9.60536 5.73478 9.50001 6 9.50001H12C12.2652 9.50001 12.5196 9.60536 12.7071 9.7929C12.8946 9.98044 13 10.2348 13 10.5V16.5Z"
            fill="black"
            stroke={props.stroke ?? "#DCDCDC"}
        />
    </svg>
)
export default IconSave
