import {SVGProps} from 'react'

const EventCatalogueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M15 8.14712V23.3138M15 8.14712C13.6375 7.24189 11.7876 6.68555 9.75 6.68555C7.71243 6.68555 5.86254 7.24189 4.5 8.14712V23.3138C5.86254 22.4086 7.71243 21.8522 9.75 21.8522C11.7876 21.8522 13.6375 22.4086 15 23.3138M15 8.14712C16.3625 7.24189 18.2124 6.68555 20.25 6.68555C22.2876 6.68555 24.1375 7.24189 25.5 8.14712V23.3138C24.1375 22.4086 22.2876 21.8522 20.25 21.8522C18.2124 21.8522 16.3625 22.4086 15 23.3138'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EventCatalogueIcon
