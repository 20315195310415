import { EventBannerGet, EventBannerUpsert } from "../../commons/interfaces/event/eventBanner.interface";
import api from "../../config/axios";

const banner_api_base = 'api/content/event/banner';

const eventBannerService = {
  show: async (id: number) => {
    try {
      const response = await api.get<EventBannerGet>(`${banner_api_base}/get?content_event_id=${id}`)
      return response;
    } catch (error) {
        throw(error);
    }
  },
  upsert: async (data: EventBannerUpsert) => {
    try {
      const response = await api.post<any>(banner_api_base, data);
      return response;
    } catch (error) {
        throw(error);
    }
  }
}

export default eventBannerService;