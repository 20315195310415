import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IManagementFilesCreate } from '../../commons/interfaces/managementFile/managementFileCreate';

  export interface IManagementFile {
    image :number[]
  }
  
const initialState: IManagementFile = {
    image: []
};

const managementFileSlice = createSlice({
  name: 'managementFile',
  initialState,
  reducers: {
    addImage: (state, action:PayloadAction<IManagementFilesCreate>) => {
        state.image =  [...state.image,action.payload.result.id]
    },
    resetImage: (state) => {
      state.image = []
    }
  }
})

export const { addImage,resetImage} = managementFileSlice.actions

export default managementFileSlice.reducer