import * as React from "react"
import { SVGProps } from "react"
const IconCancelCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={46}
        height={46}
        fill="none"
        {...props}
    >
        <path
            fill="#EF4444"
            d="M23 46A23 23 0 1 0 0 23a23.027 23.027 0 0 0 23 23Zm0-41.818A18.818 18.818 0 1 1 4.182 23 18.839 18.839 0 0 1 23 4.182Zm-7.751 23.612L20.043 23l-4.794-4.794a2.091 2.091 0 0 1 2.957-2.957L23 20.044l4.794-4.795a2.091 2.091 0 0 1 2.957 2.957L25.956 23l4.795 4.794a2.09 2.09 0 1 1-2.957 2.957L23 25.956l-4.794 4.795a2.091 2.091 0 0 1-2.957-2.957Z"
        />
    </svg>
)
export default IconCancelCircle
