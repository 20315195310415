import { EventGalleryGet, EventGalleryUpsert } from "../../commons/interfaces/event/eventGallery.interface";
import api from "../../config/axios";

const gallery_api_base = 'api/content/event/gallery';

const eventGalleryService = {
  show: async (id: number) => {
    try {
      const response = await api.get<EventGalleryGet>(`${gallery_api_base}/get?content_event_id=${id}`)
      return response;
    } catch (error) {
        throw(error);
    }
  },
  upsert: async (data: EventGalleryUpsert) => {
    try {
      const response = await api.post<any>(gallery_api_base, data);
      return response;
    } catch (error) {
        throw(error);
    }
  }
}

export default eventGalleryService;