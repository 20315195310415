import {SVGProps} from 'react'

const IconAdjustment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      d='M8 2.75V1M8 2.75C7.0335 2.75 6.25 3.5335 6.25 4.5C6.25 5.4665 7.0335 6.25 8 6.25M8 2.75C8.9665 2.75 9.75 3.5335 9.75 4.5C9.75 5.4665 8.9665 6.25 8 6.25M2.75 13.25C3.7165 13.25 4.5 12.4665 4.5 11.5C4.5 10.5335 3.7165 9.75 2.75 9.75M2.75 13.25C1.7835 13.25 1 12.4665 1 11.5C1 10.5335 1.7835 9.75 2.75 9.75M2.75 13.25V15M2.75 9.75V1M8 6.25V15M13.25 13.25C14.2165 13.25 15 12.4665 15 11.5C15 10.5335 14.2165 9.75 13.25 9.75M13.25 13.25C12.2835 13.25 11.5 12.4665 11.5 11.5C11.5 10.5335 12.2835 9.75 13.25 9.75M13.25 13.25V15M13.25 9.75V1'
      stroke='#00A160'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default IconAdjustment
