/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'
import { useDispatch } from 'react-redux'
import authService from '../../../../services/auth/auth'
import { logout } from '../../../../store/auth/authSlice'
import { IconDoubleArrow } from '../../../../commons/icons'

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { eventSelectedCtx, setSelectedEventCtx } = useAuth()

  const handleLogout = async () => {
    try {
      const response = await authService.logout()
      if (response.status === 200) {
        dispatch(logout())
        navigate('/auth')
        setSelectedEventCtx('')
        localStorage.removeItem('eventSelectedCtx');
      }
      // Realiza cualquier otra acción después del inicio de sesión
      return response
    } catch (error: any) {
        dispatch(logout())
        navigate('/auth')
        setSelectedEventCtx('')
        localStorage.removeItem('eventSelectedCtx');
    }
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px cursor-pointer'
        data-kt-menu='true'
      >
        <div className='menu-item ps-6 pe-4' onClick={handleLogout}>
          <SVG src={toAbsoluteUrl('/media/svg/general/logout.svg')} />
          <a className='text-label ps-3' style={{ fontWeight: 300 }}>Cerrar Sesión</a>
        </div>
        {eventSelectedCtx !== '' && (
          <div className='menu-item ps-6 pe-4 mt-6' onClick={() => { navigate('select-event') }}>
            <IconDoubleArrow />
            <a className='text-label ps-3' style={{ fontWeight: 300 }}>Cambiar evento</a>
          </div>
        )}
      </div>
    </>
  )
}

export { HeaderUserMenu }
