import {SVGProps} from 'react'

const EventMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M11.5 24.3327L5.14492 21.1551C4.74967 20.9575 4.5 20.5535 4.5 20.1116V7.55372C4.5 6.68644 5.4127 6.12236 6.18842 6.51022L11.5 9.16602M11.5 24.3327L18.5 20.8327M11.5 24.3327V9.16602M18.5 20.8327L23.8116 23.4885C24.5873 23.8763 25.5 23.3123 25.5 22.445V9.88706C25.5 9.44515 25.2503 9.04118 24.8551 8.84356L18.5 5.66602M18.5 20.8327V5.66602M18.5 5.66602L11.5 9.16602'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EventMapIcon
