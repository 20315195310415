import React from 'react'
import { IconArrow } from '../../../../commons/icons'
import { useNavigate } from 'react-router-dom'

const Error404 = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='row gy-5'>
        <div className='col-md-12'>
          <h1 className='fw-bolder fs-6qx  text-white'>404</h1>
        </div>
        <div className='col-md-12 text-white' style={{margin:0}}>
          <div className='fs-3qx'>Página no encontrada.</div>
          <p className='fs-2'>El enlace que seguiste no existe o fue eliminado.</p>
        </div>
        <div className='col-md-12'>
          <button type='button' id='kt_sign_in_submit' className='btn mt-1 btn-secondary'   onClick={() => navigate('/')}>
            <div className='d-flex gap-2 align-items-center justify-content-center'>
             <IconArrow />
              <span className='indicator-label'>Ir a inicio</span>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default Error404
