import { FilterToGetGifts, GiftListResponse, MassiveUpdateStatesGifts } from '../../commons/interfaces/gifts/giftList.interface'
import api from '../../config/axios'

const gift_api_service = 'api/deal/gift'

const giftService = {
  getGiftsByEventAndCustomer: async (filters = {} as FilterToGetGifts) => {
    try {
      const response = await api.get<any>(`${gift_api_service}/get`, { params: filters })
      return response;
    } catch (error) {
      throw error
    }
  },
  list: async (
    page: number | string,
    limit: number | string,
    search: string,
    params: any = {}
  ) => {
    try {
      let paramsToquery = `?page=${page}&limit=${limit}&search=${search ?? ''}`;
      if (params.entity_customer_code)
        paramsToquery = paramsToquery + `&entity_customer_code=${params.entity_customer_code}`;
      if (params.entity_customer_code_as400)
        paramsToquery = paramsToquery + `&entity_customer_code_as400=${params.entity_customer_code_as400}`;
      if (params.entity_customer_document_type)
        paramsToquery = paramsToquery + `&entity_customer_document_type=${params.entity_customer_document_type}`;
      if (params.entity_customer_document_number)
        paramsToquery = paramsToquery + `&entity_customer_document_number=${params.entity_customer_document_number}`;
      if (params.content_event_id)
        paramsToquery = paramsToquery + `&content_event_id=${params.content_event_id}`;
      if (params.content_event_code)
        paramsToquery = paramsToquery + `&content_event_code=${params.content_event_code}`;
      if (params.content_event_name)
        paramsToquery = paramsToquery + `&content_event_name=${params.content_event_name}`;
      if (params.code)
        paramsToquery = paramsToquery + `&code=${params.code}`;
      if (params.name)
        paramsToquery = paramsToquery + `&name=${params.name}`;
      if (params.quantity)
        paramsToquery = paramsToquery + `&quantity=${params.quantity}`;
      if (params.expiration_date)
        paramsToquery = paramsToquery + `&expiration_date=${params.expiration_date}`;
      const response = await api.get<GiftListResponse>(`${gift_api_service}/list${paramsToquery}`)
      return response;
    } catch (error) {
      throw error
    }
  },
  show: async (id: any) => {
    try {
      const response = await api.get<any>(`${gift_api_service}/show?id=${id}`)
      return response;
    } catch (error) {
      throw error
    }
  },
  redeemGifts: async (data: MassiveUpdateStatesGifts) => {
    try {
      const response = await api.put<any>(`${gift_api_service}/massive/state`, data)
      return response;
    } catch (error) {
      throw error
    }
  }
}

export default giftService