/**
 * Component for displaying and updating general information of an event.
 *
 * @component
 * @example
 * return (
 *  <GeneralInformationUpdate
 *     hasBeenSync={true}
 *     formDisabled={true}
 *     name="Event Name"
 *     assistanceLevel={50}
 *  />
 * )
 */
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ProgressBar from '../../../../../../commons/components/progressBar/ProgressBar';
import { RootState } from '../../../../../../store/store';
import FormElemenWrapper from '../../../../../../commons/components/formElemenWrapper/FormElemenWrapper';
import { FileUploaded } from '../../../../../../commons/interfaces/managementFile/managementFileCreate';
import InputUploadFiles from '../../../../../../_metronic/layout/components/input/InputUploadFiles';


interface IProps {
    hasBeenSync?: boolean
    formDisabled?: boolean
    name?: string
    assistanceLevel?: number
}
export const GeneralInformationUpdate = ({ hasBeenSync, name, formDisabled, assistanceLevel=0 }: IProps) => {
    const { userLogin } = useSelector((state: RootState) => state.auth)
    const { register, getValues, formState: { errors, touchedFields }, setValue } = useFormContext();
    const progressText = `( ${getValues('total_attending_users')} / ${getValues('total_register_users')} )`
    const management_file_public = getValues('management_file_public') as FileUploaded

    return (
        <div className="">
            <div className="mt-6 ms-md-4">

                <h2 className='title-form fw-bold mb-5'>Información del evento</h2>

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6 required text-dark'>Código del evento</label>
                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            {...register('code')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.code },
                                {
                                    'is-valid': touchedFields.code && !errors.code,
                                }
                            )}
                            type='text'
                            autoComplete='off'

                        />
                        {errors.code && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.code.message as string}</span>
                            </div>
                        )}


                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6 required text-dark'>Nombre del evento</label>
                        <input
                            maxLength={140}
                            placeholder=''
                            disabled={formDisabled}
                            {...register('name')}
                            className={clsx(
                                'form-control',
                                { 'bg-transparent': !formDisabled },
                                { 'is-invalid': errors.name },
                                {
                                    'is-valid': touchedFields.name && !errors.name,
                                }
                            )}
                            type='text'
                            name='name'
                            autoComplete='off'
                        />
                        {errors.name && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.name.message as string}</span>
                            </div>
                        )}

                    </div >
                </div>

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6 required text-dark'>Fecha de creación del evento</label>

                        <input
                            {...register('created_at')}
                            placeholder=''
                            disabled
                            className={clsx(
                                'form-control form-control-solid',
                            )}
                            type='text'
                            name='lastname'
                            autoComplete='off'
                        />
                        {errors.lastname && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.lastname.message as string}</span>
                            </div>
                        )}

                    </div >
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>ID-AS400</label>

                        <input
                            maxLength={140}
                            placeholder=''

                            disabled={formDisabled ? true : hasBeenSync}

                            {...register('code_as400')}
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.code_as400 },
                                {
                                    'is-valid': touchedFields.code_as400 && !errors.code_as400,
                                }
                            )}
                            type='text'
                            name='code_as400'
                            autoComplete='off'
                        />
                        {errors.code_as400 && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.code_as400.message as string}</span>
                            </div>
                        )}

                    </div >
                </div>

                <div className='row  mb-6'>


                    <div className='fv-row col-md-6  mb-6 m-md-0' >
                        <label className='form-label fs-6  required text-dark'>Estado del evento </label>
                        <select
                            disabled={formDisabled}

                            className={clsx(
                                'form-select',
                                { 'bg-transparent': !formDisabled },
                            )}
                            aria-label="Disabled select example"
                            {...register("status")}
                        //defaultValue={result?.data?.result?.phone}
                        >
                            <option value="1">Activo</option>
                            <option value="2">Inactivo</option>
                        </select>

                    </div >

                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Usuario creador del evento</label>

                        <input
                            placeholder=''
                            disabled
                            defaultValue={name}
                            className={clsx(
                                'form-control form-control-solid',
                                { 'is-invalid': errors.user_person },
                                {
                                    'is-valid': touchedFields.user_person && !errors.user_person,
                                }
                            )}
                            type='text'
                            name='user_person'
                            autoComplete='off'
                        />
                        {errors.user_person && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.user_person.message as string}</span>
                            </div>
                        )}

                    </div >

                </div>
                {((management_file_public && Object.keys(management_file_public).length !== 0)|| !formDisabled) &&
                    <div className="row mb-6">
                        <FormElemenWrapper>
                            <InputUploadFiles
                                id={`image-logo-update-event`}
                                required
                                spanLabel="(formato PNG) Max. 10MB"
                                allowedTypes={['image/png']}
                                formDisabled={formDisabled}
                                file={management_file_public ?? null}
                                handleFunction={(value: FileUploaded | null) =>
                                    setValue('management_file_public', value ?? null)
                                }
                            />
                        </FormElemenWrapper>
                    </div>
                }


            </div>

            <hr className='hr' />

            <div className="mt-6 ms-md-4">

                <h2 className='title-form fw-bold mb-5'>Asistencia</h2>

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6  text-dark'>Nivel de asistencia</label>
                        <div className="position-relative">
                            <ProgressBar bgcolor="#00A160" height={5} progress={assistanceLevel} progressText={progressText}/>
                        </div>

                    </div >
                    <div className='fv-row col-md-6' >
                    </div >
                </div>

                <div className='row  mb-6'>
                    <div className='fv-row col-md-6' >
                        <label className='form-label fs-6 required text-dark'>Sociedad del evento</label>
                        <select
                            disabled={formDisabled}
                            className="form-select bg-transparent "
                            aria-label="Disabled select example"
                            {...register("event_society_id")}
                        // defaultValue={result?.data?.result?.status}
                        >
                            {
                                userLogin?.event_societies.map(item => (
                                    <option key={`event_society_${item.name}`} value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>

                    </div >
                </div>

            </div>
        </div >
    )
}
