import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IconExit } from '../../../../../../commons/icons';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { DatePickerFilter } from '../../../../../../commons/components/datePicker/DatePickerFilter';

export const FilterOffCanvas = ({ filter, setFilter, show, handleClose, handleShow }) => {
    const [isFocused, setIsFocused] = useState<string>('')
    const { clearErrors, reset, control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields, isSubmitting, isValid }, setError } = useFormContext();

    const arregloStatus = filter?.status?.split(',').map(Number);
    const filterStatus = arregloStatus ? arregloStatus?.slice(1) : [];
    const [status, setStatus] = useState<number[]>(filterStatus);
    console.log(filter);


    const handleApplyFilter = (data) => {

        const formatUnitMeasure = data?.unit_measure?.map(option => option.value).join(',');
        const formatStatus = status.join(',')
        setFilter({ ...data, unit_measure: formatUnitMeasure, status: formatStatus })
        handleClose()

    };

    const handleResetFilter = () => {
        reset({
            code: '',
            name: '',
            start_date: '',
            end_date: '',
            code_as400: '',
            user_creator: '',
            status: "",
        })
        setFilter()
        handleClose()
        setStatus([])
    };

    const handleStatusCheckboxChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (e.target.checked) {
            // Agrega el valor a la lista si el checkbox se marca
            setStatus([...status, value]);
        } else {
            // Elimina el valor de la lista si el checkbox se desmarca
            setStatus(status.filter((item) => item !== value));
        }
    };


    const onSubmit = (data) => {

        // Aquí puedes enviar selectedValues al backend, que contendrá los valores seleccionados separados por comas.
        console.log(data);
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="me-2">
                <IconExit />
            </Button>
            <Offcanvas placement='end' show={show} onHide={handleClose} >
                <Offcanvas.Header className='bg-white' closeButton>
                    <Offcanvas.Title>Filtros</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='bg-gray-200'>
                    <div className=" h-100">
                        <div className='fv-row col-12 mb-7' >
                            <label className='form-label fs-6 text-dark'>Código del evento</label>
                            <input
                                placeholder='Ingresar código del evento'
                                {...register('code')}
                                // defaultValue={result?.data?.result?.document_number}
                                className={clsx(
                                    'form-control custom-input',
                                    /*  { 'bg-transparent': !formDisabled }, */
                                    { 'is-invalid': errors.code },
                                    {
                                        'is-valid': isFocused === 'code' && !errors.code,
                                    }
                                )}
                                onFocus={() => setIsFocused('code')}
                                type='text'
                                autoComplete='off'

                            />
                            {/*   {errors.code && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert'>{errors.code.message as string}</span>
                            </div>
                        )} */}


                        </div >

                        <div className='fv-row col-12 mb-7' >
                            <label className='form-label fs-6 text-dark'>Nombre del evento</label>
                            <input
                                placeholder='Ingresar nombre del evento'
                                {...register('name')}
                                // defaultValue={result?.data?.result?.document_number}
                                className={clsx(
                                    'form-control custom-input',
                                    /*  { 'bg-transparent': !formDisabled }, */
                                    { 'is-invalid': errors.name },
                                    {
                                        'is-valid': isFocused === 'name' && !errors.name,
                                    }
                                )}
                                onFocus={() => setIsFocused('name')}
                                type='text'
                                autoComplete='off'

                            />



                        </div >
                        <div className="fv-row col-12 mb-7" >
                            <label className='form-label fs-6 text-dark'>Fecha de creación del evento</label>
                            <DatePickerFilter end_date={filter?.end_date} start_date={filter?.start_date} visible={false} />
                        </div>
                        <div className='fv-row col-12 mb-7' >
                            <label className='form-label fs-6 text-dark'>ID-AS400</label>
                            <input
                                placeholder='Ingresar ID-AS400'
                                {...register('code_as400')}
                                // defaultValue={result?.data?.result?.document_number}
                                className={clsx(
                                    'form-control custom-input',
                                    /*  { 'bg-transparent': !formDisabled }, */
                                    { 'is-invalid': errors.code_as400 },
                                    {
                                        'is-valid': isFocused === 'code_as400' && !errors.code_as400,
                                    }
                                )}
                                onFocus={() => setIsFocused('code_as400')}
                                type='text'
                                autoComplete='off'

                            />
                        </div>

                        <div className='fv-row col-12 mb-7'>
                            <div className='form-label fs-6 text-dark'>Estado</div>
                            <div className="d-flex align-items-center gap-4">
                                <div className='form-check'>
                                    <input
                                        type="checkbox"
                                        value="1"
                                        className='form-check-input'
                                        checked={status.includes(1)}
                                        onChange={handleStatusCheckboxChange}
                                    />
                                    <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                                        Activo
                                    </label>
                                </div>
                                <div className='form-check'>
                                    <input
                                        type="checkbox"
                                        value="2"
                                        className='form-check-input'
                                        checked={status.includes(2)}
                                        onChange={handleStatusCheckboxChange}
                                    />
                                    <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                                        Inactivo
                                    </label>
                                </div>
                            </div>
                            {/*  <CheckboxGroup options={options} onChange={handleCheckboxGroupChange} /> */}

                        </div>


                        <div className='fv-row col-12 mb-7' >
                            <label className='form-label fs-6 text-dark'>Usuario creador del evento</label>
                            <input
                                placeholder='Ingresar usuario creador'
                                {...register('user_creator')}
                                // defaultValue={result?.data?.result?.document_number}
                                className={clsx(
                                    'form-control custom-input',
                                    /*  { 'bg-transparent': !formDisabled }, */
                                    { 'is-invalid': errors.user_creator },
                                    {
                                        'is-valid': isFocused === 'user_creator' && !errors.user_creator,
                                    }
                                )}
                                onFocus={() => setIsFocused('user_creator')}
                                type='text'
                                autoComplete='off'

                            />
                        </div >

                        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', padding: '20px', background: 'white' }}>
                            {/* Contenido del footer */}
                            <div className="d-flex ">
                                <div className=" flex-grow-1 ">

                                </div>
                                <div className="">
                                    <button
                                        type='button'
                                        className='btn btn-outline btn-outline-primary btn-sm  me-4'
                                        onClick={handleResetFilter}>
                                        Limpiar
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-primary  btn-sm'
                                        onClick={handleSubmit(handleApplyFilter)}>
                                        Aplicar
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}