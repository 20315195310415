import * as React from "react"
import { SVGProps } from "react"
const IconSync = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.69995 1.7002V5.6377H2.1579M14.2512 7.2127C13.8637 4.10497 11.2127 1.7002 7.99995 1.7002C5.35602 1.7002 3.09248 3.32888 2.1579 5.6377M2.1579 5.6377H5.63745M14.3 14.3002V10.3627H13.842M13.842 10.3627C12.9074 12.6715 10.6439 14.3002 7.99995 14.3002C4.78725 14.3002 2.13622 11.8954 1.74869 8.7877M13.842 10.3627H10.3625"
            stroke={props.stroke ?? '#fff'}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconSync