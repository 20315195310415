import Modal from 'react-bootstrap/Modal'
import {IconAlertCircle, IconExit} from '../../../../../commons/icons'
import {Product} from '../../../../../commons/interfaces/wishList/wishListShowSumary'

// Props of the components
interface IProps {
  product?: Product
  show: boolean
  onHide: () => void
  onClick: () => void
}
/**
 * Component that renders the alert modal for products in the cart
 * @param param {IProps} {product, show, onHide, onClick}
 * @param param.product Selected product
 * @param param.show Param to show the modal
 * @param param.onHide Function to close the modal
 * @param param.onClick Function to execute
 * @returns
 * @example
 * <ModalAlertProductInTheCart
 * product={product}
 * show={show}
 * onHide={onHide}
 * onClick={onClick}
 * />
 */
export const ModalAlertProductInTheCart = ({product, show, onHide, onClick}: IProps) => {

  // Function that is executed at
  const handleClick = () => {
    onClick();
    onHide();
  }

  return (
    <Modal
      show={show}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <IconAlertCircle />
              <h4 className='fs-2 px-5 text-center text-neutral-800 fw-medium mt-8'>
                Artículo ya ingresado
              </h4>
              <p className='tw-neutral-600 fw-light text-center mt-1'>
                {`Se intento ingresar el artículo "${product?.name}" con la cantidad de "${product?.quantity}". Por favor intente ingresar otro artículo.`}
              </p>
            </div>
            <div className='d-grid gap-2 mt-5 '>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={handleClick}
              >
                Aceptar
              </button>
            </div>
          </div>

          <div onClick={onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
