import Modal from 'react-bootstrap/Modal'
import { IconAlertCircle, IconExit } from '../../icons'

interface IProps {
  title?: string,
  description?: string
  show: boolean
  onHide: () => void

}
export const ModalAlert = ({ title, description, show, onHide, }: IProps) => {
  return (
    <Modal
      show={show}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <IconAlertCircle />
              <h4 className='fs-2 px-5 text-center text-neutral-800 fw-medium mt-8'>
                {title ?? 'Campos Incompletos'}
              </h4>
              <p className='tw-neutral-600 fw-light text-center mt-1'>
                {description ??
                  'Por favor, complete todos los campos obligatorios antes de continuar. Se requiere la información en ciertos campos para procesar su solicitud.'}
              </p>
            </div>
            <div className='d-grid gap-2 mt-5 '>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                onClick={onHide}
              >
                Aceptar
              </button>
            </div>
          </div>

          <div onClick={onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
