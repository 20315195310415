import * as React from "react"
import { SVGProps } from "react"
const IconDotsVertical = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={4}
        height={16}
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 14.125L2 14.1162M2 8L2 7.99125M2 1.875L2 1.86625M2 13.25C2.48325 13.25 2.875 13.6418 2.875 14.125C2.875 14.6082 2.48325 15 2 15C1.51675 15 1.125 14.6082 1.125 14.125C1.125 13.6418 1.51675 13.25 2 13.25ZM2 7.125C2.48325 7.125 2.875 7.51675 2.875 8C2.875 8.48325 2.48325 8.875 2 8.875C1.51675 8.875 1.125 8.48325 1.125 8C1.125 7.51675 1.51675 7.125 2 7.125ZM2 1C2.48325 1 2.875 1.39175 2.875 1.875C2.875 2.35825 2.48325 2.75 2 2.75C1.51675 2.75 1.125 2.35825 1.125 1.875C1.125 1.39175 1.51675 1 2 1Z"
            stroke={props.stroke ?? "#464646"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconDotsVertical
