import * as React from "react"
import { SVGProps } from "react"
const IconHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={17}
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.12811 2.8078C0.556254 4.37966 0.556254 6.92815 2.12811 8.50001L8.99926 15.3712L15.8703 8.50001C17.4422 6.92815 17.4422 4.37966 15.8703 2.8078C14.2985 1.23594 11.75 1.23594 10.1781 2.8078L8.99926 3.98674L7.82032 2.8078C6.24846 1.23594 3.69997 1.23594 2.12811 2.8078Z"
            stroke={props.stroke ? props.stroke : "#fff"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconHeart