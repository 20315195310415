import {orderStatesColors} from '../../enums/states.enums'
import {IconDot} from '../../icons'

interface StatusBadgesProps {
  status?: string
}

const OrderStatusBadges = ({status}: StatusBadgesProps) => {
  return (
    <>
      {status ? (
        <div
          className='state-badge fw-bold d-flex'
          style={{
            border: `1px solid ${orderStatesColors[status]}`,
            color: orderStatesColors[status],
          }}
        >
          <div className='d-flex align-items-center gap-2'>
            <IconDot fill={orderStatesColors[status]} height={7} width={7} />
            <div className='ms-2'> {status}</div>
          </div>
        </div>
      ) : (
        <span>Sin estado</span>
      )}
    </>
  )
}

export default OrderStatusBadges
