import {EventMapGet, EventMapUpdate} from '../../commons/interfaces/event/eventMap.interface'
import api from '../../config/axios'

const map_api_base = '/api/content/event/map'

const eventMapService = {
  show: async (id: number) => {
    try {
      const response = await api.get<EventMapGet>(`${map_api_base}/show?content_event_id=${id}`)
      return response
    } catch (error) {
      throw error
    }
  },
  update: async (data: EventMapUpdate) => {
    try {
      const response = await api.put<any>(map_api_base, data)
      return response
    } catch (error) {
      throw error
    }
  },
}

export default eventMapService
