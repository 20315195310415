import * as React from "react"
import { SVGProps } from "react"
const IconCheckCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={46}
        height={46}
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M33.1789 14.6166C32.77 14.2333 32.3355 14.0799 31.6711 14.0288C31.1088 14.0288 30.5722 14.2844 30.1888 14.6933L19.5575 25.8613L15.0085 21.3123C14.2163 20.5201 12.8362 20.5201 12.044 21.3123C11.6351 21.6956 11.4307 22.2579 11.4307 22.7945C11.4307 23.3312 11.6607 23.8935 12.044 24.2768L18.1008 30.3336C18.4841 30.7169 19.0464 30.9469 19.6086 30.9469C19.8897 30.9469 20.1708 30.8958 20.4264 30.768C20.682 30.6658 20.912 30.4869 21.1164 30.2825L33.2556 17.5555C33.6389 17.1467 33.8433 16.61 33.8433 16.0477C33.8433 15.4855 33.5878 14.9744 33.1789 14.5655V14.6166Z"
            fill="#00A160"
        />
        <path
            d="M23.0073 46C27.5563 46 32.003 44.6455 35.7853 42.1155C39.5676 39.5854 42.5066 36.0076 44.2444 31.7908C45.9822 27.5996 46.4422 22.974 45.5478 18.5017C44.6533 14.0293 42.481 9.94037 39.261 6.72031C36.0665 3.5258 31.9519 1.32797 27.5052 0.433511C23.0329 -0.435395 18.4072 -0.000941985 14.216 1.76243C10.0248 3.50024 6.42141 6.43919 3.89136 10.2215C1.36131 14.0038 0.00683594 18.4505 0.00683594 22.9995C0.00683594 29.1074 2.43466 34.9342 6.75364 39.2532C11.0726 43.5722 16.8994 46 23.0073 46ZM23.0073 4.19025C26.7385 4.19025 30.3675 5.28917 33.4597 7.35921C36.552 9.42925 38.9543 12.3682 40.3854 15.7927C41.8166 19.2428 42.1744 23.0251 41.4588 26.654C40.7432 30.3086 38.9287 33.6564 36.2965 36.2887C33.6642 38.921 30.3163 40.7099 26.6618 41.451C23.0073 42.1666 19.225 41.8088 15.8005 40.3777C12.3504 38.9465 9.41147 36.5443 7.36699 33.452C5.29694 30.3597 4.19803 26.7307 4.19803 22.9995C4.19803 18.0161 6.19141 13.2371 9.71815 9.71037C13.2449 6.18363 18.0239 4.19025 23.0073 4.19025Z"
            fill="#00A160"
        />
    </svg>
)
export default IconCheckCircle
