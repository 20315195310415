import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import IconChevronDown from '../../../../../commons/icons/IconChevronDown';
import { Result } from '../../../../../commons/interfaces/wishList/wishListShow';
import { format } from 'date-fns';

interface IProps {
    data?: Result
    formDisabled?: boolean
}
export const GeneralData = ({ data, formDisabled }: IProps) => {
  const { userLogin } = useSelector((state: RootState) => state.auth)
  if(data)  
  localStorage.setItem('DataShow', JSON.stringify(data));
  

    const { clearErrors, control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields, isSubmitting, isValid }, setError } = useFormContext();

    /*     useEffect(() => {
            setValue('state', '1');
        }, [setValue]); */







    return (
        <div className="">
            <div className="mt-6 ms-md-4">

                <h2 className='title-form fw-bold mb-5'>Información Personal</h2>

                <div className='row  mb-6 '>
                    <div className='fv-row position-relative col-md-6 mb-6 m-md-0'>
                        <label className='form-label fs-6  text-dark'>Tipo de documento</label>
                        <select
                            className={clsx(
                                'form-select form-select-solid form-select-solid-disabled'
                            )}
                            aria-label='Disabled select example'

                            /* {...register('document_type')} */
                            defaultValue={data?.document_type}
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            disabled
                        >
                            <option value='DNI' >DNI</option>
                            <option value='CE'>Carnet de extranjería</option>
                            <option value='PA'>Pasaporte</option>
                            <option value='RUC'>RUC</option>
                        </select>
                        <div style={{ right: '25px', top: '38px' }} className='position-absolute'>
                            <IconChevronDown />
                        </div>
                        {errors.document_type && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {errors.document_type.message as string}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row col-md-6 ' >
                        <label className='form-label fs-6  text-dark'>Número de documento</label>

                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            defaultValue={data?.document_number}
                            /* {...register('document_number')} */
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.document_number },
                                {
                                    'is-valid': touchedFields.document_number && !errors.document_number,
                                }
                            )}
                            type='text'
                            name='document_number'
                            autoComplete='off'
                        />
                        {errors.document_number && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.document_number.message as string}</span>
                            </div>
                        )}

                    </div >
                </div>


                <div className='row  mb-6'>
                    <div className='fv-row col-md-6 mb-6 m-md-0' >
                        <label className='form-label fs-6  text-dark'>Nombres</label>

                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            defaultValue={data?.firstname}
                            /* {...register('firstname')} */
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.firstname },
                                {
                                    'is-valid': touchedFields.firstname && !errors.firstname,
                                }
                            )}
                            type='text'
                            name='firstname'
                            autoComplete='off'
                        />
                        {errors.firstname && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.firstname.message as string}</span>
                            </div>
                        )}

                    </div >

                    <div className='fv-row col-md-6 ' >
                        <label className='form-label fs-6  text-dark'>Apellidos</label>

                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            defaultValue={`${data?.lastname} ${data?.middlename}`}
                            /* {...register('lastname')} */
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.lastname },
                                {
                                    'is-valid': touchedFields.lastname && !errors.lastname,
                                }
                            )}
                            type='text'
                            name='lastname'
                            autoComplete='off'
                        />
                        {errors.lastname && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.lastname.message as string}</span>
                            </div>
                        )}

                    </div >
                </div>

                <div className='row  mb-6 '>

                    <div className='fv-row col-md-6 mb-6 m-md-0' >
                        <label className='form-label fs-6  text-dark'>Nombre del evento</label>

                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            defaultValue={data?.content_event.name}
                            /* {...register('name')} */
                            className={clsx(
                                'form-control ',
                                { 'is-invalid': errors.name },
                                {
                                    'is-valid': touchedFields.name && !errors.name,
                                }
                            )}
                            type='text'
                            name='name'
                            autoComplete='off'
                        />
                        {errors.name && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.name.message as string}</span>
                            </div>
                        )}

                    </div >

                    <div className='fv-row col-md-6 ' >
                        <label className='form-label fs-6  text-dark'>Fechas del evento</label>

                        <input
                            maxLength={140}
                            placeholder=''
                            disabled
                            defaultValue={`${format(new Date(data?.content_event?.start_date!), 'dd/MM/yyyy')} - ${format(new Date(data?.content_event?.end_date!), 'dd/MM/yyyy')}`}

                            className={clsx(
                                'form-control form-control-solid',
                                { 'is-invalid': errors.date },
                                {
                                    'is-valid': touchedFields.date && !errors.date,
                                }
                            )}
                            type='text'
                            name='date'
                            autoComplete='off'
                        />
                        {errors.date && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.date.message as string}</span>
                            </div>
                        )}

                    </div >

                </div>

                <div className='row  mb-6 '>


                    <div className='fv-row position-relative col-md-6 mb-6 m-md-0'>
                        <label className='form-label fs-6 text-dark'>Estado</label>
                        <select
                            className={clsx(
                                'form-select form-select-solid form-select-solid-disabled'
                            )}
                            aria-label='Disabled select example'

                            /* {...register('document_type')} */
                            defaultValue={data?.content_event?.status}
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            disabled
                        >
                            <option value='1' >Activo</option>
                            <option value='2'>Inactivo</option>
                            <option value='3'>En proceso</option>
                        </select>
                        <div style={{ right: '25px', top: '38px' }} className='position-absolute'>
                            <IconChevronDown />
                        </div>
                        {errors.document_type && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {errors.document_type.message as string}
                                </span>
                            </div>
                        )}
                    </div>



                </div>


            </div>


        </div >
    )
}
