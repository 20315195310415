import * as React from "react"
import { SVGProps } from "react"
const IconPrint = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={17}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.8889 12.2326H13.4444C14.3036 12.2326 15 11.5362 15 10.6771V7.56597C15 6.70686 14.3036 6.01042 13.4444 6.01042H2.55556C1.69645 6.01042 1 6.70686 1 7.56597V10.6771C1 11.5362 1.69645 12.2326 2.55556 12.2326H4.11111M5.66667 15.3438H10.3333C11.1924 15.3438 11.8889 14.6473 11.8889 13.7882V10.6771C11.8889 9.81797 11.1924 9.12153 10.3333 9.12153H5.66667C4.80756 9.12153 4.11111 9.81797 4.11111 10.6771V13.7882C4.11111 14.6473 4.80756 15.3438 5.66667 15.3438ZM11.8889 6.01042V2.89931C11.8889 2.0402 11.1924 1.34375 10.3333 1.34375H5.66667C4.80756 1.34375 4.11111 2.0402 4.11111 2.89931V6.01042H11.8889Z"
            stroke={props.stroke ?? "#fff"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconPrint