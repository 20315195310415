import Modal from 'react-bootstrap/Modal'
import { IconCheckCircle, IconExit } from '../../icons'

interface IProps {
  title: string
  textButton?: string
  textButtonCancel?: string
  show: boolean
  onHide?: () => void
  onClick?: () => void
  icon?: React.ReactNode
}
export const ModalConfirmation = ({
  icon,
  title,
  textButton,
  textButtonCancel,
  show,
  onHide,
  onClick,
}: IProps) => {

  const onSubmitModal = () => {
    onClick && onClick();
    onHide && onHide();
  }
  return (
    <Modal
      show={show}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              {icon ?? <IconCheckCircle />}
              <h4 className='fs-3 px-5 text-center fw-medium tw-neutral-800 mt-8'>
                {title ?? '¿Seguro que deseas guardar los cambios?'}
              </h4>
            </div>

            <div className='d-grid gap-2 mt-7'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary '
                onClick={onSubmitModal}
              /*  disabled={isSubmitting || !isValid} */
              >
                {textButton ?? 'Si, guardar'}
              </button>
            </div>

            <div className='d-grid gap-2 mt-7'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='button-output'
                onClick={onHide}
              /*  disabled={isSubmitting || !isValid} */
              >
                {textButtonCancel ?? 'Cancelar'}
              </button>
            </div>
          </div>

          <div onClick={onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
