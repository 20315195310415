import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {AuthLayout} from '../modules/auth/AuthLayout'
import {FirstPasswordChange} from '../modules/auth/components/FirstPasswordChange'
import {useSelector} from 'react-redux'
import {RootState} from '../../store/store'
import {ListEvent} from '../modules/event/pages/ListEvent'
import {CreateEvent} from '../modules/event/pages/CreateEvent'
import {ListAssortments} from '../modules/assortment/pages/ListAssortments'
import {ViewEditEvent} from '../modules/event/pages/ViewEditEvent'
import {ViewEditAssortments} from '../modules/assortment/pages/ViewEditAssortments'
import useAuth from '../modules/auth/hooks/useAuth'
import SeletecEvents from '../modules/auth/components/SeletecEvents'
import {useAuth as useAuthCtx} from '../../app/modules/auth/core/Auth'
import QuickSearch from '../modules/wishlist/pages/QuickSearch'
import ScanerQR from '../modules/wishlist/pages/ScanerQR'
import {WishListClientCards} from '../modules/wishlist/pages/WishListClientCards'
import {WishListList} from '../modules/wishlist/pages/WishListList'
import {ViewEditWishList} from '../modules/wishlist/pages/ViewEditWishList'
import {WishListConfirmClient} from '../modules/wishlist/pages/WishListConfirmClient'
import EventInformationBlocks from '../modules/event/pages/EventInformationBlocks'
import EventBanner from '../modules/event/pages/EventBanner'
import EventSchedule from '../modules/event/pages/EventSchedule'
import EventGallery from '../modules/event/pages/EventGallery'
import EventMap from '../modules/event/pages/EventMap'
import EventCatalogue from '../modules/event/pages/EventCatalogue'
import ListOrder from '../modules/order/pages/ListOrder'
import ViewOrder from '../modules/order/pages/ViewOrder'
import QuickGiftSearch from '../modules/gift/pages/QuickGiftSearch'
import ListGifts from '../modules/gift/pages/ListGifts'
import ScannerQRGift from '../modules/gift/pages/ScannerQRGift'
import SearchCustomerToGifts from '../modules/gift/pages/SearchCustomerToGifts'

const PrivateRoutes = () => {
  const ConfigurationPage = lazy(() => import('../modules/configuration/ConfigurationPage'))
  const ClientsPage = lazy(() => import('../modules/clients/ClientsPage'))

  const {userLogin} = useSelector((state: RootState) => state.auth)
  const renew_password = userLogin?.renew_password
  const [firtsVisit, setFirtsVisit] = useState<boolean>(true)
  const location = useLocation()
  const {eventSelectedCtx} = useAuthCtx()

  const {
    mutations: {verifyTokenMutation},
  } = useAuth()

  useEffect(() => {
    if (firtsVisit) setFirtsVisit(false)
    else {
      if (location.pathname === '/select-event') return
      verifyTokenMutation.mutateAsync()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getRouteInitial = () => {
    if (userLogin?.admin === 1) return 'configuration/users'
    return userLogin?.capability_permissions.some((item) => item === 'content_event_view')
      ? 'eventos/listar'
      : userLogin?.capability_permissions.some((item) => item === 'entity_customer_attendance')
      ? 'clientes/registro-de-asistencia'
      : userLogin?.capability_permissions.some((item) => item === 'purchase_cart_list')
      ? 'wishlist/listar'
      : userLogin?.capability_permissions.some((item) => item === 'purchase_cart_view')
      ? 'wishlist/busqueda'
      : '/error/403'
  }

  return (
    <Routes>
      {renew_password === 0 ? (
        <>
          {eventSelectedCtx !== '' ? (
            <>
              <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='' element={<Navigate to={getRouteInitial()} />} />

                {/* Ruta Pedidos */}
                {((userLogin?.capability_permissions.includes('generated_order_view') ||
                  userLogin?.admin === 1) && userLogin.limited_scope !== 1) && (
                  <>
                    <Route path='pedidos/listar' element={<ListOrder />} />
                    <Route path='pedidos/detalle/:id' element={<ViewOrder />} />
                  </>
                )}

                {/* Ruta Eventos */}

                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/listar' element={<ListEvent />} />
                )}

                {/* Ruta Clientes */}

                <Route
                  path='clientes/*'
                  element={
                    <SuspensedView>
                      <ClientsPage />
                    </SuspensedView>
                  }
                />

                {/* Lazy Modules */}
                {(userLogin?.capability_permissions.includes('content_event_create') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/crear' element={<CreateEvent />} />
                )}

                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/detalle/:id' element={<ViewEditEvent />} />
                )}

                {/* Event configuration pages */}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route
                    path='eventos/:id/bloques-informativos'
                    element={<EventInformationBlocks />}
                  />
                )}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/:id/banners' element={<EventBanner />} />
                )}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/:id/agenda' element={<EventSchedule />} />
                )}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/:id/galeria' element={<EventGallery />} />
                )}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/:id/mapa' element={<EventMap />} />
                )}
                {(userLogin?.capability_permissions.includes('content_event_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='eventos/:id/catalogo' element={<EventCatalogue />} />
                )}

                {/* Ruta Surtido  <Route path='menu-test' element={<MenuTestPage />} />*/}

                {(userLogin?.capability_permissions.includes('content_product_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='surtido/listar' element={<ListAssortments />} />
                )}

                {(userLogin?.capability_permissions.includes('content_product_view') ||
                  userLogin?.admin === 1) && (
                  <Route path='surtido/detalle/:id' element={<ViewEditAssortments />} />
                )}

                {(userLogin?.capability_permissions.includes('purchase_cart_view') ||
                  userLogin?.admin === 1) && (
                  <>
                    <Route path='wishlist/busqueda' element={<QuickSearch />} />
                    <Route
                      path='wishlist/cliente/:contentEventId/:entityCustomerId'
                      element={<WishListClientCards />}
                    />
                  </>
                )}
                {(userLogin?.capability_permissions.includes('purchase_cart_list') ||
                  userLogin?.admin === 1) && (
                  <>
                    <Route path='wishlist/listar' element={<WishListList />} />
                    <Route path='wishlist/detalle/:id' element={<ViewEditWishList />} />
                    <Route
                      path='wishlist/detalle/:id/confirmar-pedido'
                      element={<WishListConfirmClient />}
                    />
                  </>
                )}

                {/* Ruta Regalos */}
                {(userLogin?.capability_permissions.includes('deal_gift_view') ||
                  userLogin?.admin === 1) && (
                    <>
                      <Route path='regalos/busqueda' element={<QuickGiftSearch />} />
                      <Route path='regalos/busqueda-cliente' element={<SearchCustomerToGifts />} />
                      <Route path='regalos/busqueda-regalos/:contentEventId/:entityCustomerId' element={<SearchCustomerToGifts />} />
                    </>
                )}
                {(userLogin?.capability_permissions.includes('deal_gift_list') ||
                  userLogin?.admin === 1) && (
                    <Route path='regalos/listar' element={<ListGifts />} />
                )}

                {/* Ruta Config */}
                <Route
                  path='configuration/*'
                  element={
                    <SuspensedView>
                      <ConfigurationPage />
                    </SuspensedView>
                  }
                />
              </Route>
              {(userLogin?.capability_permissions.includes('purchase_cart_view') ||
                userLogin?.admin === 1) && (
                <Route path='wishlist/scanerqr' element={<ScanerQR />}></Route>
              )}
              {(userLogin?.capability_permissions.includes('deal_gift_view') ||
                userLogin?.admin === 1) && (
                <Route path='regalos/scanerqr' element={<ScannerQRGift />}></Route>
              )}

              <Route element={<AuthLayout />}>
                <Route path='select-event' element={<SeletecEvents />} />
              </Route>
            </>
          ) : (
            <>
              <Route path='*' element={<Navigate to={'select-event'} />} />
              <Route element={<AuthLayout />}>
                <Route path='select-event' element={<SeletecEvents />} />
              </Route>
            </>
          )}
        </>
      ) : (
        <Route element={<AuthLayout />}>
          <Route path='/*' element={<Navigate to='/change-password' />} />
          <Route path='change-password' element={<FirstPasswordChange />} />
        </Route>
      )}

      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
