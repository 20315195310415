import {IResetPassword} from '../../commons/interfaces/password'
import {IPasswordReset} from '../../commons/interfaces/password/passwordReset'
import {IResetNotify} from '../../commons/interfaces/password/resetNotify'
import api from '../../config/axios'

const passwordService = {
  notify: async (credentials: {username: string}) => {
    try {
      const response = await api.post<IResetNotify>('/api/password/reset/recover', credentials)
      return response
    } catch (error) {
      throw error
    }
  },

  validateToken: async (credentials: {username: string; token: string}) => {
    try {
      const response = await api.post<IPasswordReset>('/api/password/reset/validate', credentials)
      return response
    } catch (error) {
      throw error
    }
  },
  resetPassword: async (credentials: IResetPassword) => {
    try {
      const response = await api.post<IPasswordReset>('/api/password/reset', credentials)
      return response
    } catch (error) {
      throw error
    }
  },
}

export default passwordService
