import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {IChangePassword} from '../../commons/interfaces/password/changePassword'
import {ILogin, User} from '../../commons/interfaces/auth/login'

export interface IAuthState {
  isAuthenticated: boolean
  userLogin: User | null
  token: string
}
const initialState: IAuthState = {
  isAuthenticated: false,
  userLogin: null,
  token: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<ILogin>) => {
      state.isAuthenticated = true
      state.userLogin = action.payload.result.user
      state.token = action.payload.result.token
    },
    changePassword: (state, action: PayloadAction<IChangePassword>) => {
      state.isAuthenticated = true
      state.token = action.payload.token
    },
    toggleRenewPassword: (state) => {
      state.userLogin!.renew_password = 0
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.userLogin = null
      state.token = '' // Limpia el token al cerrar sesión
    },
  },
})

//export const { login } = authSlice.actions;
//export const selectSelectedItem = (state: { auth: IAuth }) => state.auth.selectedItem;

export const {login, changePassword, logout, toggleRenewPassword} = authSlice.actions

export default authSlice.reducer
