/**
 * Component for updating additional information of an event.
 *
 * @component
 * @param {boolean} formDisabled - Indicates whether the form is disabled or not.
 * @returns {JSX.Element} AdditionalInformationUpdate component.
 */
import {useFormContext} from 'react-hook-form';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import { format, startOfDay, endOfDay, addDays, parseISO, max } from 'date-fns';
import eventService from '../../../../../../services/event';
import { useEffect, useState } from 'react';
import { getDateLabel } from '../../../../../../commons/helpers/general';
import { ScheduleForm } from '../../../../../../commons/interfaces/event/createEvent';
import { TimePicker } from '../../../../../../commons/components/datePicker/TimePicker';
import FormElemenWrapper from '../../../../../../commons/components/formElemenWrapper/FormElemenWrapper';
import MapComponent, { Coordinates } from '../maps/MapComponent';
import clsx from 'clsx';
import { DatePickerSimple } from '../../../../../../commons/components/datePicker/DatePickerSimple';

interface IProps {
  formDisabled?: boolean
  coordinates?: Coordinates
}

export const AdditionalInformationUpdate = ({formDisabled, coordinates}: IProps) => {
  // Get the event id from the URL
  const params = useParams();
  const {id} = params;
  // Query to get the event data
  const {
    data: event,
    isLoading,
    isError,
    isFetching,
  } = useQuery(['eventView', id], () => eventService.show(+id!));
  // Form context
  const {
    setValue,
    watch,
    register,
    formState: {errors, touchedFields},
  } = useFormContext();
  // Watch the start and end date
  const startDate = watch('start_date');
  const endDate = watch('end_date');
  // Watch the schedule
  const schedule = watch('content_event_opening');
  //States
  const [loadingMap, setLoadingMap] = useState(true);

  // Function to update the schedule
  const onChange = (date: string, key: string, value: string) => {
    const newSchedule = schedule?.map((item: ScheduleForm) => {
      if (item.date === date) {
        return {...item, [key]: value};
      }
      return item;
    });
    setValue('content_event_opening', newSchedule);
  };

  // Function to render the schedule
  const renderSchedule = () => {
    return schedule?.map((item: ScheduleForm, index: number) => (
      <div className='d-flex row mb-5' key={`schedule_${index}`}>
        <div className='col-12 col-md-5'>
          <input
            type='text'
            autoComplete='off'
            className='form-control bg-transparent'
            disabled
            value={item.date_label}
          />
        </div>
        <div className='d-flex row col-12 col-md-7 align-items-center justify-content-between'>
          <div className='col-12 col-md-5'>
            <TimePicker
              id={`hour_init_${item.date}`}
              handleChange={(value: string) => onChange(item.date, 'hour_init', value)}
              timeValue={item.hour_init}
              formDisabled={formDisabled}
              maxTime={item.hour_end}
            />
          </div>
          <div className='d-none d-md-flex col-md-2 justify-content-center'>-</div>
          <div className='col-12 col-md-5'>
            <TimePicker
              id={`hour_end_${item.date}`}
              handleChange={(value: string) => onChange(item.date, 'hour_end', value)}
              timeValue={item.hour_end}
              formDisabled={formDisabled}
              minTime={item.hour_init}
            />
          </div>
        </div>
      </div>
    ));
  };

  // Function to convert the list to an object
  const convertListToObject = (activities: ScheduleForm[]) : Record<string, ScheduleForm> => {
    return activities.reduce((acc, item) => {
      acc[item.date] = item;
      return acc;
    }, {});
  }

  // Function to search a day in the list and return the hours
  const searchDayInList = (originalList: Record<string, ScheduleForm>, date: string) => {
    if (originalList[date]) {
      return { hour_init: originalList[date].hour_init, hour_end: originalList[date].hour_end };
    }
    return { hour_init: '00:00', hour_end: '23:59' };
  }

  // Function to get the dates in the range
  const getDatesInRange = () => {
    if ( !formDisabled ) {
      setValue('content_event_opening', []);
      setTimeout(() => {
        const dates: ScheduleForm[] = [];
        // Get the original list converted to an object
        const originalList = convertListToObject(JSON.parse(JSON.stringify(schedule)) as ScheduleForm[] || []);
        let currentDate = startOfDay(parseISO(startDate));
        while (currentDate <= endOfDay(parseISO(endDate))) {
          const formattedDate = format(currentDate, 'yyyy-MM-dd');
          const dateLabel = getDateLabel(currentDate);
          // Search the day in the list and get the hours
          const { hour_init, hour_end } = searchDayInList(originalList, formattedDate);
          dates.push({ date: formattedDate, date_label: dateLabel, hour_init, hour_end });
          currentDate = addDays(currentDate, 1);
        };
        setValue('content_event_opening', dates);
      }, 100);
    };
  };

  // Function to handle the coordinates when selecting a new one
  const handleSelectCoordinates = (newCoordinates: Coordinates) => {
    setValue('lat', newCoordinates.lat);
    setValue('lng', newCoordinates.lng);
  }

  //Function to return the date that will be used to limit the end date of the event
  const calculateMinimumDate = (inputDate: string) => {
    // Get the current date
    const currentDate = new Date();
    // Convert the date Parameter to Date object if it is not
    const dateParameterDate = new Date(inputDate);
    // Calculate the minimum date between the Parameter date and the current date
    return format(max([currentDate, dateParameterDate]), 'yyyy-MM-dd');
  }

  // Fetch the data
  useEffect(() => {
    if (startDate && endDate) getDatesInRange();
    else setValue('content_event_opening', []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  // Wait for the coordinates to be loaded
  useEffect(() => {
    setLoadingMap(true);
    setTimeout(() => {
      setLoadingMap(false);
    }, 100);
  }, [coordinates, formDisabled])

  // Loading and error handling
  if (isLoading || isFetching) {
    return <div>Cargando...</div>
  };
  if (isError) {
    return <div>Error al cargar el evento</div>
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-6 mb-6'>
          <input
            {...register('address')}
            className={clsx(
              'form-control',
              {'bg-transparent': !formDisabled},
            )}
            type='text'
            autoComplete='off'
            placeholder='Dirección'
            disabled={formDisabled}
          />
        </div>
        <div className='col-12 col-md-6 mb-6'>
          <input
            {...register('reference')}
            className={clsx(
              'form-control',
              {'bg-transparent': !formDisabled},
            )}
            type='text'
            autoComplete='off'
            placeholder='Referencia'
            disabled={formDisabled}
          />
        </div>
        <div className="col-12 col-md-8">
          { loadingMap ?
              <div>Cargando mapa...</div> :
            <>
              <span className='required text-muted'>Seleccionar ubicación</span>
              <MapComponent
                formDisabled={formDisabled}
                defaultLat={coordinates?.lat}
                defaultLng={coordinates?.lng}
                selectCoordinates={handleSelectCoordinates}
                />
              {(errors.lat || errors.lng) && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {errors.lat && errors.lat.message as string}  {errors.lng && errors.lng.message as string}
                  </span>
                </div>
              )}
            </>
          }
        </div>
      </div>
      <div className='col-12 col-xl-8'>
        <div className='mt-6 mb-6 ms-md-4'>
          <h2 className='title-form fw-bold mb-5'>Días de atención</h2>
          <div className='row  mb-4 '>
            <FormElemenWrapper label='Fecha de inicio' required>
              <DatePickerSimple
                id="start_date"
                handleChange={(value: string) => setValue('start_date', value)}
                formDisabled={formDisabled}
                dateValue={startDate}
                minDate={format(new Date(), 'yyyy-MM-dd')}
                maxDate={endDate}
              />
              {errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{errors.start_date.message as string}</span>
                </div>
              )}
            </FormElemenWrapper>
            <FormElemenWrapper label='Fecha de fin' required>
              <DatePickerSimple
                id="end_date"
                handleChange={(value: string) => setValue('end_date', value)}
                formDisabled={formDisabled}
                dateValue={endDate}
                minDate={startDate ? calculateMinimumDate(startDate) : undefined}
              />
              {errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{errors.end_date.message as string}</span>
                </div>
              )}
            </FormElemenWrapper>
          </div>
        </div>
        {startDate && endDate && (
          <>
            <hr className='hr' />
            <div className='mt-6 ms-md-4'>
              <h2 className='title-form fw-bold mb-5'>Horario de atención</h2>
              {renderSchedule()}
            </div>
          </>
        )}
      </div>
      <div className='row'>
        <div className='col-12'>
          <hr className='hr' />
          <div className='mt-6 mb-6 ms-md-4'>
            <h2 className='title-form fw-bold mb-5'>Contacto</h2>
            <div className='row mb-4 '>
              <FormElemenWrapper label='Correo de contacto' required>
                <input
                  {...register('email')}
                  className={clsx(
                    'form-control',
                    {'bg-transparent': !formDisabled},
                    {'is-invalid': errors?.email},
                    {
                      'is-valid': touchedFields.email && !errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                  disabled={formDisabled}
                />
                {errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{errors.email.message as string}</span>
                  </div>
                )}
              </FormElemenWrapper>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <hr className='hr' />
          <div className='mt-6 mb-6 ms-md-4'>
            <h2 className='title-form fw-bold mb-5'>Sobre la página</h2>
            <div className='row mb-4'>
              <FormElemenWrapper label='URL'>
                <input
                  {...register('url')}
                  className={clsx(
                    'form-control',
                    {'bg-transparent': !formDisabled},
                    {'is-invalid': errors?.url},
                    {
                      'is-valid': touchedFields.url && !errors.url,
                    }
                  )}
                  type='url'
                  name='url'
                  autoComplete='off'
                  disabled={formDisabled}
                />
              </FormElemenWrapper>
              <FormElemenWrapper label='Estado'>
                <select
                  className='form-select bg-bg-transparent'
                  aria-label='Disabled select example'
                  {...register('website_status')}
                  disabled={formDisabled}
                >
                  <option value='0'>Oculto</option>
                  <option value='1'>Visible</option>
                </select>
              </FormElemenWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
