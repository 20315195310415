import {getDeviceInfo} from '../../commons/helpers/general'
import {IWishListCreateUpdate} from '../../commons/interfaces/wishList/wishListCreateUpdate'
import {IWishListItemDelete} from '../../commons/interfaces/wishList/wishListItemDelete'
import {IWishListItemGet} from '../../commons/interfaces/wishList/wishListItemGet'
import {IWishListList} from '../../commons/interfaces/wishList/wishListList'
import {
  IAddPurchare,
  IListProductsWhislist,
} from '../../commons/interfaces/wishList/wishListProduct'

import {IWishListShow} from '../../commons/interfaces/wishList/wishListShow'
import {ISumaryPurchase} from '../../commons/interfaces/wishList/wishListShowSumary'
import api from '../../config/axios'
import ipAddressService from '../general/ipAddressService'

const wishlistService = {
  searchByDocument: async (filters = {} as any) => {
    try {
      const response = await api.get<any>(
        `/api/purchase/cart/show?document_type=${filters.document_type ?? ''}&document_number=${
          filters.document_number ?? ''
        }&content_event_id=${filters.content_event_id ?? ''}&code=${filters.code ?? ''}`
      )
      return response
    } catch (error) {
      throw error
    }
  },

  addUpdateItemPurchase: async (data: IAddPurchare[]) => {
    try {
      const response = await api.post<IWishListCreateUpdate>(`/api/purchase/cart/item`, {
        shopping_list: data,
      })
      return response
    } catch (error) {
      throw error
    }
  },

  updateItemsPurchase : async (data: IAddPurchare[]) => {
    try {
      const response = await api.post<IWishListCreateUpdate>(`/api/purchase/cart/item`, {
        shopping_list: data,
      })
      return response
    } catch (error) {
      throw error
    }
  },
  //entityCustomerId
  getDataProductsByWhislist: async (filters = {} as any) => {
    try {
      const response = await api.get<IListProductsWhislist>(
        `/api/purchase/product?content_event_id=${
          filters.contentEventId ?? ''
        }&entity_customer_id=${filters.entityCustomerId}&entity_supplier_id=${filters.entitySupplierId}&taxonomy_category_id=${filters.taxonomy_category_id ?? ''}&search=${filters.name ?? ''}`
      )
      return response
    } catch (error) {
      throw error
    }
  },

  getSumaryPurchase: async (data: {entityCustomerId: number, params?: {}}) => {
    try {
      const response = await api.get<ISumaryPurchase>(
        `api/purchase/cart/item/get`, {
          params: {
            entity_customer_id: data.entityCustomerId ?? '',
            ...data.params
          }
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },

  list: async (
    page: number | string,
    limit: number | string,
    search: string,
    filters = {} as any
  ) => {
    try {
      const response = await api.get<IWishListList>(
        `/api/purchase/cart/list/?page=${page}&limit=${limit}&search=${
          search ?? ''
        }&document_type=${filters.document_type ?? ''}&document_number=${
          filters.document_number ?? ''
        }&firstname=${filters.firstname ?? ''}&content_event_id=${
          filters.content_event_id ?? ''
        }&content_event_name=${filters.content_event_name ?? ''}&start_date=${
          filters.start_date ?? ''
        }&end_date=${filters.end_date ?? ''}&event_code=${filters.event_code ?? ''}`
      )
      console.log(response)
      return response
    } catch (error) {
      throw error
    }
  },
  show: async (id: any) => {
    try {
      const response = await api.get<IWishListShow>(`/api/purchase/cart/show?id=${id}`)
      return response
    } catch (error) {
      throw error
    }
  },
  listWhislistDetail: async (entity_customer_id: number, search='') => {
    try {
      const response = await api.get<IWishListItemGet>(
        `/api/purchase/cart/item/get?entity_customer_id=${entity_customer_id}&search=${search}`
      )
      return response
    } catch (error) {
      throw error
    }
  },
  cartItemCreateUpdate: async (payload) => {
    try {
      const response = await api.post<IWishListCreateUpdate>(`/api/purchase/cart/item`, payload)
      return response
    } catch (error) {
      throw error
    }
  },
  cardConfirmWislist: async (content_products, entity_customer_id, payment_condition) => {
    try {
      const body = {
        content_products,
        entity_customer_id,
        payment_condition,
      }
      const response = await api.post<IWishListCreateUpdate>(`/api/purchase/order/valued`, body)
      return response
    } catch (error) {
      throw error
    }
  },
  generateWishlist: async (
    content_products,
    entity_customer_id,
    payment_condition,
    dispatch_date
  ) => {
    try {
      // Service to get the public ip
      const device_ip = await ipAddressService.getIpAddres();
      // Get device data
      const {device_useragent, device_browser, device_os} = getDeviceInfo();
      // Build the object to send to the service
      const body = {
        content_products,
        entity_customer_id,
        payment_condition,
        dispatch_date,
        device_browser,
        device_ip: device_ip,
        device_os,
        device_useragent
      }
      const response = await api.post<IWishListCreateUpdate>(`/api/purchase/order`, body)
      return response
    } catch (error) {
      throw error
    }
  },
  cartItemDelete: async (payload) => {
    try {
      const response = await api.delete<IWishListItemDelete>(
        `/api/purchase/cart/item?entity_customer_id=${payload.entity_customer_id}&content_product_id=${payload.content_product_id}`
      )
      return response
    } catch (error) {
      throw error
    }
  },
}

export default wishlistService
