/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLayoutEffect, useState} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import useAuth from '../hooks/useAuth'
import {useNavigate} from 'react-router-dom'
import {logout, toggleRenewPassword} from '../../../../store/auth/authSlice'
import {useDispatch} from 'react-redux'

export function FirstPasswordChange() {
  const {
    forms: {firstPasswordForm},
    mutations: {firstPasswordMutation, logoutMutation},
  } = useAuth()

  const [showPassword, setShowPassword] = useState(true)
  const [showNewPassword, setShowNewPassword] = useState(true)
  const [isFocused, setIsFocused] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (firstPasswordForm.formState.isSubmitted) {
      if (
        firstPasswordForm.watch('password') !== firstPasswordForm.watch('password_confirmation')
      ) {
        firstPasswordForm.setError('password_confirmation', {
          message: 'Las contraseñas no coinciden.',
        })
      } else {
        firstPasswordForm.clearErrors('password_confirmation')
      }
    }
  }, [firstPasswordForm.watch('password'), firstPasswordForm.watch('password_confirmation')])

  return (
    <form
      className='form w-100 '
      onSubmit={firstPasswordForm.handleSubmit(() =>
        firstPasswordMutation.mutate(firstPasswordForm.getValues())
      )}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      {!firstPasswordMutation.isSuccess ? (
        <>
          <div className='text-left mb-10'>
            <h1 className='tw-neutral-900 fw-bolder mb-3 title '>Cambia tu contraseña</h1>
            <div className='tw-neutral-500 fw-lighter fs-4'>Ingresa una nueva contraseña.</div>
          </div>

          <div className='fv-row mb-4'>
            <label className='form-label fw-medium tw-neutral-800 fs-6 '>Nueva contraseña</label>
            <div className='position-relative'>
              <input
                type={`${showPassword ? 'password' : 'text'}`}
                autoComplete='off'
                placeholder='Ingresa tu nueva contraseña'
                {...firstPasswordForm.register('password')}
                onFocus={() => setIsFocused('password')}
                onBlur={() => setIsFocused('')}
                className={clsx(
                  'form-control form-control-auth bg-transparent ps-10 ',
                  {
                    'is-invalid': firstPasswordForm.formState.errors.password,
                  },
                  {
                    'is-valid':
                      isFocused === 'password' && !firstPasswordForm.formState.errors.password,
                  }
                )}
              />

              <div style={{top: '12px', left: '15px'}} className='position-absolute'>
                <i className='bi bi-key text-primary fs-2'></i>{' '}
              </div>
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{top: '50%', right: '15px', transform: 'translateY(-50%)'}}
                className='position-absolute'
              >
                <SVG
                  style={{
                    cursor: 'pointer',
                  }}
                  src={toAbsoluteUrl('/media/svg/login/eye.svg')}
                />
              </div>
            </div>
            {firstPasswordForm.formState.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{firstPasswordForm.formState.errors.password.message}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-medium tw-neutral-800 fs-6 '>
              Confirma tu nueva contraseña
            </label>
            <div className='position-relative'>
              <input
                type={`${showNewPassword ? 'password' : 'text'}`}
                placeholder='Confirma tu nueva contraseña'
                autoComplete='off'
                onFocus={() => setIsFocused('password_confirmation')}
                {...firstPasswordForm.register('password_confirmation')}
                onBlur={() => setIsFocused('')}
                className={clsx(
                  'form-control form-control-auth bg-transparent ps-10 ',
                  {
                    'is-invalid': firstPasswordForm.formState.errors.password_confirmation,
                  },
                  {
                    'is-valid':
                      isFocused === 'password_confirmation' &&
                      !firstPasswordForm.formState.errors.password_confirmation,
                  }
                )}
              />

              <div style={{top: '12px', left: '15px'}} className='position-absolute'>
                <i className='bi bi-key text-primary fs-2'></i>{' '}
              </div>
              <div
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{top: '50%', right: '15px', transform: 'translateY(-50%)'}}
                className='position-absolute'
              >
                <SVG
                  style={{
                    cursor: 'pointer',
                  }}
                  src={toAbsoluteUrl('/media/svg/login/eye.svg')}
                />
              </div>
            </div>
            {firstPasswordForm.formState.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {firstPasswordForm.formState.errors.password_confirmation.message}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10'>
            <span className='tw-neutral-500 fw-lighter'>
              Tu contraseña debe contener mínimo 8 caracteres, una mayúscula, una mínuscula y un
              número.
            </span>
          </div>

          <div className='d-grid mt-5'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={firstPasswordMutation.isLoading}
            >
              {!firstPasswordMutation.isLoading && (
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                  <span className='indicator-label'>Continuar</span>
                </div>
              )}
              {firstPasswordMutation.isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Cargando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className='d-grid mt-5'>
            <button
              type='button'
              className='button-output text-primary'
              onClick={() => logoutMutation.mutate()}
              /*  disabled={isSubmitting || !isValid} */
            >
              {!logoutMutation.isLoading && (
                <div className='d-flex gap-2 align-items-center justify-content-center'>
                  <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
                  <span className='indicator-label'>Cerrar sesión</span>
                </div>
              )}
              {logoutMutation.isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Cargando...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='text-left mb-5'>
            <h1 className='tw-neutral-900 fw-bolder mb-3 title '>Cambia tu contraseña</h1>
            <div className='tw-neutral-500 fw-lighter fs-4'>
              Tu contraseña se ha actualizado correctamente. Intenta iniciar sesión con tus nuevas
              credenciales.
            </div>
          </div>

          <div className='d-grid mt-11'>
            <button
              onClick={() => {
                dispatch(logout())
                navigate('/auth/login', {replace: true})
              }}
              type='button'
              id='kt_sign_in_submit'
              className='btn btn-primary'
            >
              <div className='d-flex gap-2 align-items-center justify-content-center'>
                <SVG src={toAbsoluteUrl('/media/svg/login/left.svg')} />
                <span className='indicator-label'>Continuar</span>
              </div>
            </button>
          </div>
        </>
      )}
      {/* end::Action */}
    </form>
  )
}
