import React from 'react'
import { IconArrow } from '../../../../commons/icons'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'

const Error401 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectAuth=()=>{
    dispatch(logout());
    navigate('/auth');
  }
  
  return (
    <>
      <div className='row gy-5'>
        <div className='col-md-12'>
          <h1 className='fw-bolder fs-6qx  text-white'>401</h1>
        </div>
        <div className='col-md-12 text-white' style={{margin:0}}>
          <div className='fs-3qx'>Acceso no autorizado.</div>
          <p className='fs-2'>Lo sentimos, pero no tienes permiso para acceder a esta página.</p>
        </div>
        <div className='col-md-12'>
          <button type='button' id='kt_sign_in_submit' className='btn mt-1 btn-secondary' onClick={() => redirectAuth()}>
            <div className='d-flex gap-2 align-items-center justify-content-center'>
             <IconArrow />
              <span className='indicator-label'>Ir a inicio de sesión</span>
            </div>
          </button>
        </div>
      </div>
    </>
  )
}

export default Error401
