import { useState } from 'react';

const useModal = (initialState = false) => {
    const [show, setShow] = useState(initialState);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return { show, handleClose, handleShow };
};

export default useModal;
