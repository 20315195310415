import * as React from "react"
import { SVGProps } from "react"
const IconArrowDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={12}
        height={7}
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.5 5.69922L6 1.19922L1.5 5.69922"
            stroke={props.stroke ?? '#171717'}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconArrowDown
