import IconViewCard from '../../../../commons/icons/IconViewCard'
import IconViewTable from '../../../../commons/icons/IconViewTable'

// Props
interface TableViewSelectorProps {
  setViewSelected: (viewSelected: string) => void
  viewSelected: string
}

/**
 * TableViewSelector component
 * This component is responsible for rendering the view selector.
 * The buttons call the setViewSelected function with the viewSelected value.
 * The color of the icons changes depending on the viewSelected value.
 * @param {TableViewSelectorProps} {setViewSelected, viewSelected}
 * @param param.setViewSelected Function to set the view selected
 * @param param.viewSelected View selected
 * @returns {ReactElement}
 * @example
 * <TableViewSelector setViewSelected={setViewSelected} viewSelected={viewSelected} />
 **/
const TableViewSelector = ({setViewSelected, viewSelected}: TableViewSelectorProps) => {
  return (
    <div className='d-flex gap-3 align-items-center'>
      <label className='form-label fs-6 text-dark m-0'>Vista</label>
      <button className='btn-non-style' onClick={() => setViewSelected("table")}>
        <IconViewTable color={viewSelected === 'table' ? '#00A160' : '#BDBDBD'} />
      </button>
      <button className='btn-non-style' onClick={() => setViewSelected("card")}>
        <IconViewCard color={viewSelected === 'card' ? '#00A160' : '#BDBDBD'} />
      </button>
    </div>
  )
}

export default TableViewSelector
