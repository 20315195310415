import {useEffect, useState} from 'react'
import {ProductsToShow} from '../../../../../commons/interfaces/order/orderView'
import {columns} from '../../config/OrderViewConfig'
import {
  NEXT_SORT_DIRECTION,
  SortDirection,
} from '../../../wishlist/components/tables/tables.interface'
import clsx from 'clsx'

// Props interface
interface IProps {
  data: ProductsToShow[]
  searchValue?: string
}

/**
 * TableOrderListProducts component
 * @param {ProductsToShow[]} data - The data to show in the table.
 * @param {string} searchValue - The value to search in the table.
 * @returns {JSX.Element} TableOrderListProducts component
 * @example
 * <TableOrderListProducts data={data} searchValue={searchValue} />
**/
const TableOrderListProducts = ({data, searchValue}: IProps) => {
  // State
  // Items to show in the table
  const [items, setItems] = useState<ProductsToShow[]>(data)
  // Sort column
  const [sortColumn, setSortColumn] = useState<string | null>(null)
  // Sort direction
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.NONE)

  // Use effect to filter the data
  useEffect(() => {
    // If there is a search value
    if (searchValue) {
      // Filter the data
      const filteredData = data.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase())
      })
      // Set the items
      setItems(filteredData)
    } else {
      // Set the items
      setItems(data)
    }
  }, [data, searchValue])

  // Function to sort the columns
  const sortColumns = (sortDirection, sortColumn) => {
    // If there is a sort column
    if (sortColumn) {
      // Sort the data
      const sortedData = [...items!].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === SortDirection.ASC ? -1 : 1
        if (a[sortColumn] > b[sortColumn]) return sortDirection === SortDirection.ASC ? 1 : -1
        return 0
      });
      // Set the items
      setItems(sortedData);
    }
  }

  // Function to handle the sort
  const handleSort = (column: string) => {
    // If the sort column is different
    if (sortColumn !== column) {
      // Set the sort column
      setSortDirection(SortDirection.ASC)
      // Set the sort direction
      setSortColumn(column)
      // Sort the columns
      sortColumns(SortDirection.ASC, column)
    } else {
      // Get the next sort direction
      const direction = NEXT_SORT_DIRECTION[sortDirection];
      // If the direction is none
      if (direction === SortDirection.NONE) {
        // Set the sort column
        setSortColumn(null);
        // Set the sort direction
        setSortDirection(SortDirection.NONE);
      } else {
        // Set the sort direction
        setSortDirection(direction);
        // Sort the columns
        sortColumns(direction, column);
      }
    }
  }

  return (
    <div className='table-responsive rounded'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead className='bg-head-table '>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                style={{minWidth: `${column.width}px`}}
                role='button'
                onClick={() => handleSort(column.code)}
              >
                {column.label}
                {sortColumn === column.code && (
                  <i
                    className={clsx('fas p-0', {
                      'fa-sort-up': sortDirection === SortDirection.DESC,
                      'fa-sort-down': sortDirection === SortDirection.ASC,
                    })}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.sku}</span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  <span
                    className='ellipsis text-muted me-2 fs-7 fw-semibold'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title={item.name}
                  >
                    {item.name}
                  </span>
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.brand}</span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {item.quantity ? item.quantity.toString() : '-'}
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {item.unit_measure}
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {item.conversion_unit}
                </span>
              </td>
              <td>
                <span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {item?.entity_supplier?.business_name || ''}
                  </span>
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.line}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableOrderListProducts
