import { Daum } from '../../../../../../commons/interfaces/userPerson/userPersonList'
import { IconSearch2 } from '../../../../../../commons/icons'

interface IProps {
  data: Daum[] | undefined
  isNotResult?: boolean,
}
export const TableListUser = ({ data, isNotResult }: IProps) => {

  return (
    <div className='table-responsive rounded'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => (
            <tr key={item.id}>
              <td>
                <span className='tw-neutral-600 fw-light tw-neutral-600 d-block fs-7'>
                  <span className='tw-neutral-600 me-2 fs-7 fw-light'>{item.firstname} {item.lastname}</span>
                </span>
              </td>
            </tr>
          ))}
          {isNotResult && (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  <p style={{ fontWeight: 300 }} className=' text-label mb-2'>No se han encontrado resultados en esta búsqueda.</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}
