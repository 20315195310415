import {SVGProps} from 'react'
const EventBannerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M12.375 20.8333L11.5 24.3333L10.3333 25.5H19.6667L18.5 24.3333L17.625 20.8333M4.5 16.1667H25.5M6.83333 20.8333H23.1667C24.4553 20.8333 25.5 19.7887 25.5 18.5V6.83333C25.5 5.54467 24.4553 4.5 23.1667 4.5H6.83333C5.54467 4.5 4.5 5.54467 4.5 6.83333V18.5C4.5 19.7887 5.54467 20.8333 6.83333 20.8333Z'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default EventBannerIcon
