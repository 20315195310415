import * as React from 'react'
import {SVGProps} from 'react'
const IconExit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 11}
    height={props.height ?? 12}
    viewBox='0 0 11 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 10.5L10 1.5M1 1.5L10 10.5'
      stroke={props.fill ?? '#00A160'}
      strokeWidth={1.67}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default IconExit
