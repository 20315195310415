import * as React from "react"
import { SVGProps } from "react"
const IconConfig = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.69693 2.02457C7.02858 0.658475 8.97142 0.658476 9.30307 2.02457C9.51731 2.90706 10.5284 3.32585 11.3039 2.85333C12.5043 2.12186 13.8781 3.49566 13.1467 4.69614C12.6742 5.47164 13.0929 6.48269 13.9754 6.69693C15.3415 7.02858 15.3415 8.97142 13.9754 9.30307C13.0929 9.51731 12.6742 10.5284 13.1467 11.3039C13.8781 12.5043 12.5043 13.8781 11.3039 13.1467C10.5284 12.6742 9.51731 13.0929 9.30307 13.9754C8.97142 15.3415 7.02858 15.3415 6.69693 13.9754C6.48269 13.0929 5.47164 12.6742 4.69614 13.1467C3.49566 13.8781 2.12186 12.5043 2.85333 11.3039C3.32585 10.5284 2.90706 9.51731 2.02457 9.30307C0.658476 8.97142 0.658475 7.02858 2.02457 6.69693C2.90706 6.48269 3.32585 5.47164 2.85333 4.69614C2.12186 3.49566 3.49566 2.12186 4.69614 2.85333C5.47164 3.32585 6.48269 2.90706 6.69693 2.02457Z"
            stroke="#989898"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.3333 8C10.3333 9.28866 9.28866 10.3333 8 10.3333C6.71134 10.3333 5.66667 9.28866 5.66667 8C5.66667 6.71134 6.71134 5.66667 8 5.66667C9.28866 5.66667 10.3333 6.71134 10.3333 8Z"
            stroke="#989898"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconConfig