import { EventCatalogue } from "../../../../commons/interfaces/event/eventCatalogue.interface";

export const defaultCatalogueForm: EventCatalogue = {
  management_file_public: [],
  management_file_public_thumbnail: null,
  name: '',
}

export const catalogueFormRules = {
  management_file_public: { required: true },
  management_file_public_thumbnail: { required: true },
  name: { required: true },
}