// Prop
interface IconViewTableProps {
  color: string
}

/**
 * IconViewTable component
 * @param param {IconViewTableProps} {color}
 * @returns
 * @example
 * <IconViewTable color='#BDBDBD' />
 */
const IconViewTable = ({color = '#BDBDBD'}: IconViewTableProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
    <path
      d='M3 5.09375H17M3 8.59375H17M3 12.0938H17M3 15.5938H17'
      stroke={color}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default IconViewTable
