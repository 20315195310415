import api from '../../config/axios'
import { ILogin } from '../../commons/interfaces/auth/login'
import { IChangePassword } from '../../commons/interfaces/password/changePassword'

const authService = {
  login: async (credentials: { username: string; password: string} , recaptcha: string ) => {
    let headers = {
      'Content-Type': 'application/json',
      'g-recaptcha-response': recaptcha,
    };

    try {
      const response = await api.post<ILogin>('/api/auth/login', credentials, { headers })
      return response // Reemplaza '/login' con la ruta real de inicio de sesión
    } catch (error) {
      throw error // Manejo de errores de inicio de sesión
    }
  },
  changePassword: async (credentials: { password: string; password_confirmation: string }) => {
    try {
      const response = await api.put<IChangePassword>('/api/password/reset', credentials)
      return response
    } catch (error) {
      throw error // Manejo de errores de inicio de sesión
    }
  },
  logout: async () => {
    try {
      const response = await api.delete('/api/auth/logout') // Reemplaza '/login' con la ruta real de inicio de sesión
      return response
    } catch (error) {
      throw error // Manejo de errores de inicio de sesión
    }
  },
  verifyToken: async () => {
    try {
      const response = await api.post<ILogin>('/api/auth/validate', null)
      return response
    } catch (error) {
      throw error
    }
  },
  refreshToken: async () => {
    try {
      const response = await api.put<ILogin>('/api/auth/refresh', null)
      return response
    } catch (error) {
      throw error
    }
  },
}
export default authService

/*   export const loginApi = async (credentials: { username: string; password: string }, dispatch) => {
    const api = useApi(); // Mueve esta línea al interior de la función
    
    try {
      // Realiza la solicitud de inicio de sesión a tu API
      const response = await api.post('/api/auth', credentials);
      // Actualiza el estado de autenticación en Redux
      dispatch(login(response.data));
  
      return response.data;
    } catch (error) {
      // Maneja los errores de inicio de sesión
      console.error('Error de inicio de sesión:', error);
      // throw error;
    }
  };

  export const changePasswordApi = async (credentials:{ password: string; password_confirmation: string },dispatch) => {
    const api = useApi();
      try {
        // Realiza la solicitud de inicio de sesión a tu API
        const response = await api.put('/api/password', credentials);  
        // Actualiza el estado de autenticación en Redux
        console.log(response.data);
        
       dispatch(changePassword(response.data));
    
        return response.data;
      } catch (error) {
        // Maneja los errores de inicio de sesión
        console.error('Error cambio de contrasenia:', error);
        //throw error;
      }
    };

    export const logoutApi = async (dispatch) => {
      const api = useApi();
      //const dispatch: AppDispatch = useDispatch();
        try {
          // Realiza la solicitud de inicio de sesión a tu API
          const response = await api.delete('/api/auth');  
          // Actualiza el estado de autenticación en Redux
          dispatch(logout(response.data.message));
      
          return response.data;
        } catch (error) {
          // Maneja los errores de inicio de sesión
          console.error('Error al cerrar  sesión:', error);
          //throw error;
        }
      };
     */
