// wishlistDataSlice.js

import { createSlice } from '@reduxjs/toolkit';

const wishlistDataSlice = createSlice({
  name: 'wishlistData',
  initialState: {
    entity_customer_id: 10,
    payment_condition: 'CON',
    dispatch_date: '2023-10-31',
    content_products: [3],
  },
  reducers: {
    setEntityCustomerId: (state, action) => {
      state.entity_customer_id = action.payload;
    },
    setPaymentCondition: (state, action) => {
      state.payment_condition = action.payload;
    },
    setDispatchDate: (state, action) => {
      state.dispatch_date = action.payload;
    },
    setContentProducts: (state, action) => {
      state.content_products = action.payload;
    },
  },
});

export const {
  setEntityCustomerId,
  setPaymentCondition,
  setDispatchDate,
  setContentProducts,
} = wishlistDataSlice.actions;

export default wishlistDataSlice.reducer;
