import {useNavigate} from 'react-router-dom'
import Dropdown from 'react-overlays/Dropdown'
import React, {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import DropdownIcon from '../../../../../commons/components/overlay/DropdownIcon'
import {
  IconAlertCircle,
  IconCancelCircle,
  IconCheckCircle,
  IconDot,
  IconExit,
  IconEye,
  IconSearch2,
  IconSlider,
  IconTrash,
} from '../../../../../commons/icons'
import './TableListDetail.css'
import {ResultWishDetailList} from '../../../../../commons/interfaces/wishList/wishListList'
import clsx from 'clsx'
import useModal from '../../../../../commons/hooks/useModal'
import {ModalConfirmation} from '../../../../../commons/components/modals/ModalConfirmation'
import {IWishListItemGet, Result} from '../../../../../commons/interfaces/wishList/wishListItemGet'
import {ModalUpdateItem} from '../modals/ModalUpdateItem'
import {useMutation, useQueryClient} from 'react-query'
import wishlistService from '../../../../../services/whislist'
import {toast} from 'react-toastify'
import {NEXT_SORT_DIRECTION, SortDirection} from './tables.interface'

interface IProps {
  data?: IWishListItemGet
  refetched: boolean
  isNotResult?: boolean
  isNotResultFilter?: boolean
  searchValue?: string
  onStoreSelectedData: (items: any) => void
  setCountSelected: (item: any) => void
}

const Column = {
  SKU: 'sku',
  NAME: 'name',
  QUANTITY: 'quantity',
  UNIT_MEASURE: 'unit_measure',
  MASTER_PACKAGE: 'master_package',
  BRAND: 'brand',
  LINE: 'line',
  SUPPLIER: 'supplier',
} as const
type Column = typeof Column[keyof typeof Column]

export const TableListDetailWishList = ({
  data,
  refetched,
  isNotResult,
  isNotResultFilter,
  searchValue,
  onStoreSelectedData,
  setCountSelected,
}: IProps) => {
  const navigation = useNavigate()
  const queryClient = useQueryClient()

  const [items, setItems] = useState<Result[] | undefined>(data?.result)
  const [filteredData, setFilteredData] = useState<Result[] | undefined>(data?.result)
  const [sortColumn, setSortColumn] = useState<Column | null>(null)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.NONE)
  const {show: showAmount, handleClose: closeAmount, handleShow: openAmount} = useModal()
  const {show: showDelete, handleClose: closeDelete, handleShow: openDelete} = useModal()

  const [selectedItem, setSelectedItem] = useState<Result | null>()
  const [selectedItemDelete, setSelectedItemDelete] = useState<Result | null>()
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    if (data?.result) {
      const updatedItems = data?.result.map((item) => ({
        ...item,
        supplier: item?.entity_supplier?.business_name || '',
        selected: item?.selected,
      }))

      setItems(updatedItems)
    }
  }, [data])

  useEffect(() => {
    console.log('items', items)
    setFilteredData(
      items?.filter((item) => {
        const searchTerms = [item.sku, item.line, item.name, item?.entity_supplier?.business_name]
          .join(' ')
          .toLowerCase()
        return searchTerms.includes(searchValue!.toLowerCase())
      })
    )
  }, [items, searchValue])

  const handleOpenAmount = (item) => {
    setSelectedItem(item)
    openAmount()
  }

  const handleCloseAmount = () => {
    setSelectedItem(null)
    closeAmount()
  }

  const handleOpenDelete = (item) => {
    setSelectedItemDelete(item)
    openDelete()
  }

  const handleCloseDelete = () => {
    setSelectedItemDelete(null)
    closeDelete()
  }

  const handleSort = (column: Column) => {
    if (sortColumn !== column) {
      setSortColumn(column)
      setSortDirection(SortDirection.ASC)

      return
    }

    setSortDirection((direction) => NEXT_SORT_DIRECTION[direction])
  }

  useEffect(() => {
    if (sortColumn) {
      const sortedData = [...filteredData!].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === SortDirection.ASC ? -1 : 1
        if (a[sortColumn] > b[sortColumn]) return sortDirection === SortDirection.ASC ? 1 : -1
        return 0
      })
      setFilteredData(sortedData)
    }
  }, [filteredData, sortColumn, sortDirection])

  const handleCheckboxChange = (id) => {
    const updatedItems = items?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: item.selected === 0 ? 1 : 0,
        }
      }
      return item
    })
    setItems(updatedItems)
    onStoreSelectedData(updatedItems)
  }

  const handleSelectAllChange = () => {
    const updatedSelectAll = !selectAll
    setSelectAll(updatedSelectAll)

    const updatedItems = items?.map((item) => ({
      ...item,
      selected: updatedSelectAll ? 1 : 0,
    }))

    setItems(updatedItems)
    onStoreSelectedData(updatedItems)
  }

  const userMutation = useMutation({
    mutationFn: wishlistService.cartItemDelete,
    onSuccess: () => {
      console.log('Se realizo los cambios')
      //si sale todo ok quiero cambiar la cantidad de data
      toast.success('El producto se ha eliminado del pre-pedido.', {
        position: 'bottom-right',
        autoClose: 5000, // Tiempo de cierre automático en milisegundos
        hideProgressBar: true, // Mostrar barra de progreso
        closeOnClick: true, // Cerrar al hacer clic
        draggable: true, // Arrastrable
        progress: undefined, // Personalizar la barra de progreso
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      queryClient.invalidateQueries('listDetailWishlist')
    },
    onError: (e: any) => {
      console.log(e.response)

      //setShowModalError(true)
      /* if (e.response.status) {
        
      } else {
         
      } */
    },
  })

  const onSubmitDelete = () => {
    const sendBackend = {
      entity_customer_id: selectedItemDelete?.entity_customer.id,
      content_product_id: selectedItemDelete?.id,
    }
    //console.log(sendBackend);

    userMutation.mutate(sendBackend)
  }

  useEffect(() => {
    const countSelected = items?.filter((item) => item?.selected)?.length
    setCountSelected(countSelected)
  }, [items])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded '>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle'>
        {/* begin::Table head */}
        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-50px d-flex justify-content-end  '>
              <div className='form-check '>
                <input
                  className='form-check-input w-20px h-20px'
                  type='checkbox'
                  onChange={handleSelectAllChange}
                  checked={selectAll}
                />
              </div>
            </th>
            <th className='min-w-50px'></th>
            <th className='min-w-150px' onClick={() => handleSort(Column.SKU)} role='button'>
              Código{' '}
              {sortColumn === Column.SKU && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
            <th className='min-w-400px' onClick={() => handleSort(Column.NAME)} role='button'>
              Descripción{' '}
              {sortColumn === Column.NAME && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
            <th className='min-w-150px' onClick={() => handleSort(Column.BRAND)} role='button'>
              Marca{' '}
              {sortColumn === Column.BRAND && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
            <th className='min-w-150px' onClick={() => handleSort(Column.QUANTITY)} role='button'>
              Cantidad Solicitada{' '}
              {sortColumn === Column.QUANTITY && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
            <th
              className='min-w-175px'
              onClick={() => handleSort(Column.MASTER_PACKAGE)}
              role='button'
            >
              Master{' '}
              {sortColumn === Column.MASTER_PACKAGE && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
            <th
              className='min-w-150px'
              onClick={() => handleSort(Column.UNIT_MEASURE)}
              role='button'
            >
              Unidad de Medida{' '}
              {sortColumn === Column.UNIT_MEASURE && (
                <i
                  className={clsx('fas', {
                    'fa-sort-up': sortDirection === SortDirection.DESC,
                    'fa-sort-down': sortDirection === SortDirection.ASC,
                  })}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <span className='text-muted d-flex justify-content-end fw-semibold text-muted d-block fs-7'>
                    <div className='form-check mb-2'>
                      <input
                        type='checkbox'
                        className='form-check-input w-20px h-20px'
                        checked={item.selected === 1}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </div>
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <DropdownIcon>
                      <div className='w-180px'>
                        <div
                          onClick={() => handleOpenAmount(item)}
                          className=' cursor-pointer menu-item px-5 py-2 d-flex justify-content-center align-items-center gap-3'
                        >
                          <div className=''>
                            <IconSlider color='#00A160' />
                          </div>

                          <div className='text-label fw-lighter tw-green-700'>Cambiar cantidad</div>
                        </div>
                        <div
                          onClick={() => handleOpenDelete(item)}
                          className='cursor-pointer menu-item px-5 pt-5 d-flex justify-content-start align-items-center gap-3'
                        >
                          <div className='w-25'>
                            <IconTrash />
                          </div>

                          <div className='text-label fw-lighter tw-destructive-500'>Eliminar</div>
                        </div>
                      </div>
                    </DropdownIcon>
                  </div>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.sku}</span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    <span
                      className='ellipsis text-muted me-2 fs-7 fw-semibold'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title={item.name}
                    >
                      {item.name}
                    </span>
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{item.brand}</span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{item.quantity}</span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{item.master_package}</span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{item.unit_measure}</span>
                </td>
                {/* <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{item.line}</span>
                </td> */}
                {/* <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {item.entity_supplier ? item.entity_supplier.business_name : ''}
                  </span>
                </td> */}
              </tr>
            )
          })}
          {filteredData?.length === 0 && (
            <tr>
              <td colSpan={12}>
                <div className='d-flex justify-content-center text-aling-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  {/* {isNotResultFilter && (<p style={{ fontWeight: 300 }} className=' text-label mb-2'>No se han encontrado resultados en esta búsqueda.</p>)} */}
                  <p className='text-label tw-green-700'>
                    {isNotResultFilter
                      ? 'Por favor, intenta otra vez.'
                      : 'No se han encontrado resultados en esta búsqueda.'}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}

      <ModalUpdateItem show={showAmount} onHide={handleCloseAmount} data={selectedItem} />

      <ModalConfirmation
        icon={<IconCancelCircle />}
        title='¿Seguro que deseas eliminar el producto del pre-pedido?'
        textButton='Sí, eliminar'
        textButtonCancel='No, continuar'
        show={showDelete}
        onHide={handleCloseDelete}
        onClick={onSubmitDelete}
      />
    </div>
  )
}
