// Prop
interface IconViewCardProps {
  color: string
}

/**
 * IconViewCard component
 * @param param {IconViewCardProps} {color}
 * @returns
 * @example
 * <IconViewCard color='#BDBDBD' />
 */
const IconViewCard = ({color = '#BDBDBD'}: IconViewCardProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
    <path
      d='M3 5.09375C3 4.12725 3.7835 3.34375 4.75 3.34375H6.5C7.4665 3.34375 8.25 4.12725 8.25 5.09375V6.84375C8.25 7.81025 7.4665 8.59375 6.5 8.59375H4.75C3.7835 8.59375 3 7.81025 3 6.84375V5.09375Z'
      fill={color}
    />
    <path
      d='M11.75 5.09375C11.75 4.12725 12.5335 3.34375 13.5 3.34375H15.25C16.2165 3.34375 17 4.12725 17 5.09375V6.84375C17 7.81025 16.2165 8.59375 15.25 8.59375H13.5C12.5335 8.59375 11.75 7.81025 11.75 6.84375V5.09375Z'
      fill={color}
    />
    <path
      d='M3 13.8438C3 12.8773 3.7835 12.0938 4.75 12.0938H6.5C7.4665 12.0938 8.25 12.8773 8.25 13.8438V15.5938C8.25 16.5602 7.4665 17.3438 6.5 17.3438H4.75C3.7835 17.3438 3 16.5602 3 15.5938V13.8438Z'
      fill={color}
    />
    <path
      d='M11.75 13.8438C11.75 12.8773 12.5335 12.0938 13.5 12.0938H15.25C16.2165 12.0938 17 12.8773 17 13.8438V15.5938C17 16.5602 16.2165 17.3438 15.25 17.3438H13.5C12.5335 17.3438 11.75 16.5602 11.75 15.5938V13.8438Z'
      fill={color}
    />
    <path
      d='M3 5.09375C3 4.12725 3.7835 3.34375 4.75 3.34375H6.5C7.4665 3.34375 8.25 4.12725 8.25 5.09375V6.84375C8.25 7.81025 7.4665 8.59375 6.5 8.59375H4.75C3.7835 8.59375 3 7.81025 3 6.84375V5.09375Z'
      stroke={color}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.75 5.09375C11.75 4.12725 12.5335 3.34375 13.5 3.34375H15.25C16.2165 3.34375 17 4.12725 17 5.09375V6.84375C17 7.81025 16.2165 8.59375 15.25 8.59375H13.5C12.5335 8.59375 11.75 7.81025 11.75 6.84375V5.09375Z'
      stroke={color}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 13.8438C3 12.8773 3.7835 12.0938 4.75 12.0938H6.5C7.4665 12.0938 8.25 12.8773 8.25 13.8438V15.5938C8.25 16.5602 7.4665 17.3438 6.5 17.3438H4.75C3.7835 17.3438 3 16.5602 3 15.5938V13.8438Z'
      stroke={color}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.75 13.8438C11.75 12.8773 12.5335 12.0938 13.5 12.0938H15.25C16.2165 12.0938 17 12.8773 17 13.8438V15.5938C17 16.5602 16.2165 17.3438 15.25 17.3438H13.5C12.5335 17.3438 11.75 16.5602 11.75 15.5938V13.8438Z'
      stroke={color}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconViewCard;
