import { EventGallery } from "../../../../commons/interfaces/event/eventGallery.interface";

export const defaultGalleryForm: EventGallery = {
  management_file_public: [],
  name: '',
  content: '',
}

export const galleryFormRules = {
  management_file_public: { required: true },
  name: { required: true },
}