import Modal from 'react-bootstrap/Modal'

import IconCheckCircle from '../../../../../commons/icons/IconCheckCircle'
import { IconExit } from '../../../../../commons/icons'

export const ModalSuccess = (props) => {
  /*  onSubmit={methods.handleSubmit(onSubmit)} */

  const onSubmitModal = () => {
    props.onSubmit()
    props.onHide()
  }

  return (
    <Modal
      {...props}
      dialogClassName='bs-modal-width'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      {/*  <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
      <Modal.Body>
        <div className='position-relative h-auto'>
          <div className='mt-2'>
            <div className='d-flex flex-column align-items-center'>
              <IconCheckCircle />
              <h4 className='fs-3 px-5 text-center fw-medium tw-neutral-800 mt-8'>
                ¿Seguro que deseas guardar los cambios?
              </h4>
            </div>

            <div className='d-grid gap-2 mt-7'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary '
                onClick={onSubmitModal}
              /*  disabled={isSubmitting || !isValid} */
              >
                Si, guardar
              </button>
            </div>

            <div className='d-grid gap-2 mt-7'>
              <button
                type='button'
                id='kt_sign_in_submit'
                className='button-output'
                onClick={props.onHide}
              /*  disabled={isSubmitting || !isValid} */
              >
                Cancelar
              </button>
            </div>
          </div>

          <div onClick={props.onHide} className='position-absolute top-0 end-0 cursor-pointer'>
            <IconExit fill='#7C7C7C' width={14} height={14} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
