import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {MainTitle} from '../../../../commons/components/Title/MainTitle'
import {toast} from 'react-toastify'
import DndSection from '../../../../commons/components/dndForms/DndForms'
import {ModalErrors} from '../../../../commons/components/modals/ModalErrors'
import {useSelector} from 'react-redux'
import {useMutation, useQueryClient, useQuery} from 'react-query'
import {useErrorHandling} from '../../../../commons/hooks/useErrorHandling'
import {IconCheckCircle, IconPencil} from '../../../../commons/icons'
import {EventCatalogueForm} from '../../../../commons/interfaces/event/eventCatalogue.interface'
import eventCatalogueService from '../../../../services/event/eventCatalogueService'
import {RootState} from '../../../../store/store'
import {defaultCatalogueForm, catalogueFormRules} from '../config/EventCatalogueConfig'

/*
 * EventCataloguePage
 * This component is responsible for rendering the page of catalogue of the event.
 * It uses the DndSection component to render the form with the catalogue.
 * It uses the useQuery, useMutation and useQueryClient hooks from react-query to make the requests to the API.
 * It uses the useSelector hook from react-redux to access the userLogin state.
 * It uses the useErrorHandling hook to handle the errors.
 */
const EventCataloguePage = () => {
  const params = useParams()
  const queryClient = useQueryClient()
  const {userLogin} = useSelector((state: RootState) => state.auth)
  const {errorType, handleApiError, clearError} = useErrorHandling()

  // The useParams hook returns an object with the params of the URL.
  // In this case, we are destructuring the id from the params object.
  const {id} = params

  // The useState hook is used to manage the formDisabled state.
  const [formDisabled, setFormDisabled] = useState(true)

  // The useQuery hook returns an object with the data, isLoading and isError properties.
  const {
    data: event,
    isLoading,
    isError,
    refetch
  } = useQuery(['eventCatalogueView', id], () => eventCatalogueService.show(+id!))

  // The useMutation hook returns an object with the mutation function and the status of the mutation.
  const userMutation = useMutation({
    // The mutationFn is the function that makes the request to the API.
    mutationFn: eventCatalogueService.upsert,
    // The onSuccess function is called when the mutation is successful.
    // It shows a success toast and navigates to the event detail page.
    // It also invalidates the query to update the data.
    onSuccess: () => {
      toast.success('Los cambios fueron guardados con éxito.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      queryClient.invalidateQueries(['eventCatalogueView', id],{ refetchActive: true })
      refetch()
      setFormDisabled(true)
    },
    // The onError function is called when the mutation has an error.
    onError: (error: any) => {
      handleApiError(error)
    },
  })

  // The onSubmit function is called when the edit button is clicked.
  const onSubmit = (elements: EventCatalogueForm[]) => {
    const data = {
      content_event_id: Number(id),
      content_event_catalog: elements.map((element) => {
        const {code, cardTitle, ...rest} = element
        return rest
      }),
    }
    userMutation.mutate(data)
  }

  // The isLoading aproperty is used to show a loading message.
  if (isLoading) {
    return <div>Cargando...</div>
  }

  // The isError property is used to show an error message.
  if (isError) {
    return <div>Error al cargar la galería</div>
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-column'>
        <div className='d-flex justify-content-between align-items-center mb-6'>
          <MainTitle
            disabled={formDisabled}
            title='Catálogo del Evento'
            editTitle='Catálogo del Evento'
            icon
          />
          {/* The button is used to enable the form to edit the catalogue. */}
          {formDisabled &&
            event?.data?.result.length !== 0 &&
            (userLogin?.capability_permissions.includes('content_event_update') ||
              userLogin?.admin === 1) && (
              <button
                onClick={() => setFormDisabled(false)}
                type='button'
                className='btn btn-primary btn-outline-primary me-2 d-flex align-items-center gap-3'
              >
                <IconPencil />
                Editar catálogo
              </button>
            )}
        </div>
        {/* The DndSection component is used to render the form with the catalogue. */}
        {event?.data?.result && (
          <DndSection
            defaultForm={defaultCatalogueForm}
            formRules={catalogueFormRules}
            initialForms={event.data.result}
            formName='catalogue'
            formDisabled={formDisabled && event.data.result.length !== 0}
            prefixCode='Catálogo'
            handleFunction={onSubmit}
            addButtonText='Añadir catálogo'
          />
        )}
      </div>
      {/* The ModalErrors component is used to show an error message. */}
      {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
    </>
  )
}

export default EventCataloguePage
