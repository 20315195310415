import * as React from "react"
import { SVGProps } from "react"
const IconGift = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={19}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.99922 5.92144V17.5492M8.99922 5.92144C8.99922 5.92144 8.99922 4.58623 8.99922 4.13255C8.99922 3.14458 9.80013 2.34366 10.7881 2.34366C11.7761 2.34366 12.577 3.14458 12.577 4.13255C12.577 5.12053 11.7761 5.92144 10.7881 5.92144C10.2538 5.92144 8.99922 5.92144 8.99922 5.92144ZM8.99922 5.92144C8.99922 5.92144 8.99922 4.18882 8.99922 3.68533C8.99922 2.45036 7.99808 1.44922 6.76311 1.44922C5.52814 1.44922 4.527 2.45036 4.527 3.68533C4.527 4.9203 5.52814 5.92144 6.76311 5.92144C7.49458 5.92144 8.99922 5.92144 8.99922 5.92144ZM2.73811 9.49922H15.2603M2.73811 9.49922C1.75013 9.49922 0.949219 8.69831 0.949219 7.71033C0.949219 6.72235 1.75013 5.92144 2.73811 5.92144H15.2603C16.2483 5.92144 17.0492 6.72235 17.0492 7.71033C17.0492 8.69831 16.2483 9.49922 15.2603 9.49922M2.73811 9.49922L2.73811 15.7603C2.73811 16.7483 3.53902 17.5492 4.527 17.5492H13.4714C14.4594 17.5492 15.2603 16.7483 15.2603 15.7603V9.49922"
            stroke={props.stroke ? props.stroke : "#fff"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconGift