import {useEffect, useState} from 'react'
import {MainTitle} from '../../../../commons/components/Title/MainTitle'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import OrderService from '../../../../services/order'
import GeneralData from '../components/tabs/GeneralData'
import OrderListProducts from '../components/tabs/OrderListProducts'
import {
  GeneralDataform,
  GeneratedOrderItem,
  ProductsToShow,
} from '../../../../commons/interfaces/order/orderView'
import {GeneralDataDefault} from '../config/OrderViewConfig'

const ViewOrder = () => {
  const params = useParams()
  const {id} = params
  const [generalData, setGeneralData] = useState<GeneralDataform>(GeneralDataDefault)
  const [products, setProducts] = useState<ProductsToShow[]>([])

  const {
    data: orderList,
    isLoading,
    isError,
    isFetching,
  } = useQuery(['orderView', id], () => OrderService.show(+id!))

  const getProductsToShow = (generated_order_item: GeneratedOrderItem[]) => {
    let productsToShow: ProductsToShow[] = []
    generated_order_item.forEach((product) => {
      const productToShow: ProductsToShow = {
        id: product.id,
        quantity: product.quantity,
        price: product.content_product.price,
        sku: product.content_product.sku,
        name: product.content_product.name,
        code_as400: product.content_product.code_as400,
        unit_measure: product.content_product.unit_measure,
        line: product.content_product.line,
        brand: product.content_product.brand,
        master_package: product.content_product.master_package,
        entity_supplier: product.content_product.entity_supplier,
        conversion_unit: product.content_product.conversion_unit,
      }
      productsToShow.push(productToShow)
    })
    setProducts(productsToShow)
  }

  useEffect(() => {
    if (orderList) {
      setGeneralData({
        document_type: orderList.data.result.purchase_order.document_type,
        document_number: orderList.data.result.purchase_order.document_number,
        firstname: orderList.data.result.purchase_order.entity_customer.firstname,
        lastname: orderList.data.result.purchase_order.entity_customer.lastname,
        event_name: orderList.data.result.content_event?.name || '',
        start_date: orderList.data.result.content_event?.start_date || '',
        end_date: orderList.data.result.content_event?.end_date || '',
        status: orderList.data.result.state,
      })
      getProductsToShow(orderList.data.result.generated_order_item || [])
    }
  }, [orderList, isLoading])

  if (isLoading || isFetching) {
    return <div>Cargando...</div>
  }

  if (isError) {
    return <div>Error al cargar el pedido</div>
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-column'>
        <div className='d-flex justify-content-between align-items-center mb-6'>
          <MainTitle title='Ver detalle del pedido' editTitle='Ver detalle del pedido' icon />
        </div>
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x ms-3  mt-5 mb-7 fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              Datos general
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              Productos del pedido
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <GeneralData data={generalData} />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
            <OrderListProducts order={generalData} products={products} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewOrder
