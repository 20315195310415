import * as React from "react"
import { SVGProps } from "react"
const IconEye = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={12}
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path

            d="M10.2004 5.99837C10.2004 7.21381 9.21511 8.19912 7.99967 8.19912C6.78423 8.19912 5.79892 7.21381 5.79892 5.99837C5.79892 4.78293 6.78423 3.79762 7.99967 3.79762C9.21511 3.79762 10.2004 4.78293 10.2004 5.99837Z"
            stroke={props.color ? props.color : "#989898"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 5.99834C1.93479 3.02213 4.71528 0.863281 7.99999 0.863281C11.2847 0.863281 14.0652 3.02216 15 5.99839C14.0652 8.97461 11.2847 11.1335 8.00001 11.1335C4.71527 11.1335 1.93477 8.97458 1 5.99834Z"
            stroke={props.color ? props.color : "#989898"}
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconEye