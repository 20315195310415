import * as React from "react"
import { SVGProps } from "react"
const IconCalculator = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={12}
        height={16}
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.9001 4.49922H8.1001M8.1001 11.4992V9.39922M6.0001 11.4992H6.0071M3.9001 11.4992H3.9071M3.9001 9.39922H3.9071M6.0001 9.39922H6.0071M8.1001 7.29922H8.1071M6.0001 7.29922H6.0071M3.9001 7.29922H3.9071M2.5001 14.2992H9.5001C10.2733 14.2992 10.9001 13.6724 10.9001 12.8992V3.09922C10.9001 2.32602 10.2733 1.69922 9.5001 1.69922H2.5001C1.7269 1.69922 1.1001 2.32602 1.1001 3.09922V12.8992C1.1001 13.6724 1.7269 14.2992 2.5001 14.2992Z"
            stroke="white"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default IconCalculator