import React, { useEffect } from 'react';

// Coordinates interface
export interface Coordinates {
  lat: number | undefined;
  lng: number | undefined;
}

// Lima coordinates
const LimaCoordinates = { lat: -12.0431800, lng: -77.0282400 }

/**
 * Google Map component
 * @param defaultLat Default latitude
 * @param defaultLng Default longitude
 * @param selectCoordinates Function to select coordinates
 * @param formDisabled Form disabled
 * @returns Google Map component
 * @example
 * <GoogleMap
 *  defaultLat={coordinates?.lat}
 *  defaultLng={coordinates?.lng}
 *  selectCoordinates={handleSelectCoordinates}
 *  formDisabled={formDisabled}
 * />
 **/
interface Props {
  defaultLat?: number;
  defaultLng?: number;
  selectCoordinates?: (coordinates: Coordinates) => void;
  formDisabled?: boolean;
}
// Google Map component
const GoogleMap: React.FC<Props> = ({defaultLat, defaultLng, selectCoordinates, formDisabled}) => {
  // Load the map
  useEffect(() => {
    // Map and marker
    let map: google.maps.Map;
    let marker: google.maps.Marker;
    // Initialize the map
    const initMap = () => {
      // Initial coordinates
      const initialCoordinates = defaultLat && defaultLng ? { lat: defaultLat, lng: defaultLng } : LimaCoordinates;
      // Conditional to check if the map exists
      if ( document.getElementById('map') ) {
        // Create the map
        map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
          zoom: defaultLat && defaultLng ? 16 : 8,
          center: initialCoordinates
        });
        // Create the marker if the coordinates are set
        if (defaultLat && defaultLng) {
          marker = new google.maps.Marker({
            position: initialCoordinates,
            map: map
          });
        }
        // Add the listener to select the coordinates if the form is not disabled
        if(!formDisabled) {
          google.maps.event.addListener(map, 'dblclick', (event: google.maps.KmlMouseEvent) => {
            const clickedLocation = event.latLng;
            if (marker) {
              marker.setMap(null);
            }
            marker = new google.maps.Marker({
              position: clickedLocation,
              map: map
            });
            if (selectCoordinates)
              selectCoordinates({
                lat: clickedLocation?.lat(),
                lng: clickedLocation?.lng()
              })
          });
        }
      }
    };
    // Load the script
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyTYv0qvWLqWYyFzKv3mmbIeoDcA9ZolE&callback&async=true`;
      script.onload = initMap;
      document.head.appendChild(script);
    };

    loadScript();

    return () => {
      if (map) {
        google.maps.event.clearInstanceListeners(map);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="map" style={{ height: '400px', width: '100%' }} onTouchStart={() => {}}></div>
    </>
  );
};

export default GoogleMap;