const IconPosition = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M5 22.5C6.38071 22.5 7.5 23.6193 7.5 25C7.5 26.3807 6.38071 27.5 5 27.5C3.61929 27.5 2.5 26.3807 2.5 25C2.5 23.6193 3.61929 22.5 5 22.5Z'
        fill='#989898'
      />
      <path
        d='M5 15C6.38071 15 7.5 16.1193 7.5 17.5C7.5 18.8807 6.38071 20 5 20C3.61929 20 2.5 18.8807 2.5 17.5C2.5 16.1193 3.61929 15 5 15Z'
        fill='#989898'
      />
      <path
        d='M12.5 22.5C13.8807 22.5 15 23.6193 15 25C15 26.3807 13.8807 27.5 12.5 27.5C11.1193 27.5 10 26.3807 10 25C10 23.6193 11.1193 22.5 12.5 22.5Z'
        fill='#989898'
      />
      <path
        d='M12.5 15C13.8807 15 15 16.1193 15 17.5C15 18.8807 13.8807 20 12.5 20C11.1193 20 10 18.8807 10 17.5C10 16.1193 11.1193 15 12.5 15Z'
        fill='#989898'
      />
      <path
        d='M20 22.5C21.3807 22.5 22.5 23.6193 22.5 25C22.5 26.3807 21.3807 27.5 20 27.5C18.6193 27.5 17.5 26.3807 17.5 25C17.5 23.6193 18.6193 22.5 20 22.5Z'
        fill='#989898'
      />
      <path
        d='M20 15C21.3807 15 22.5 16.1193 22.5 17.5C22.5 18.8807 21.3807 20 20 20C18.6193 20 17.5 18.8807 17.5 17.5C17.5 16.1193 18.6193 15 20 15Z'
        fill='#989898'
      />
      <path
        d='M27.5 22.5C28.8807 22.5 30 23.6193 30 25C30 26.3807 28.8807 27.5 27.5 27.5C26.1193 27.5 25 26.3807 25 25C25 23.6193 26.1193 22.5 27.5 22.5Z'
        fill='#989898'
      />
      <path
        d='M27.5 15C28.8807 15 30 16.1193 30 17.5C30 18.8807 28.8807 20 27.5 20C26.1193 20 25 18.8807 25 17.5C25 16.1193 26.1193 15 27.5 15Z'
        fill='#989898'
      />
      <path
        d='M35 22.5C36.3807 22.5 37.5 23.6193 37.5 25C37.5 26.3807 36.3807 27.5 35 27.5C33.6193 27.5 32.5 26.3807 32.5 25C32.5 23.6193 33.6193 22.5 35 22.5Z'
        fill='#989898'
      />
      <path
        d='M35 15C36.3807 15 37.5 16.1193 37.5 17.5C37.5 18.8807 36.3807 20 35 20C33.6193 20 32.5 18.8807 32.5 17.5C32.5 16.1193 33.6193 15 35 15Z'
        fill='#989898'
      />
    </svg>
  )
}

export default IconPosition
