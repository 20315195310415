/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Select from 'react-select'
import Offcanvas from 'react-bootstrap/Offcanvas'
import jsPDF from 'jspdf'
import {
  IconCancelCircle,
  IconCheckCircle,
  IconPrint,
  IconTrash,
  IconUpdate,
} from '../../../../commons/icons'
import { toast } from 'react-toastify'
import IconErrorCircle from '../../../../commons/icons/IconErrorCircle'
import { useMutation } from 'react-query'
import wishlistService from '../../../../services/whislist'
import { ModalConfirmation } from '../../../../commons/components/modals/ModalConfirmation'
import useModal from '../../../../commons/hooks/useModal'
import autoTable from 'jspdf-autotable'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Product } from '../../../../commons/interfaces/wishList/wishListShowSumary'
import SearchFilter from '../../../../commons/components/search/SearchFilter'
import { IOrderFilter } from '../../../../commons/interfaces/order/orderList'

// Props
interface ShoppingCartOffCanvasProps {
  show: boolean
  handleClose: () => void
  handleShow: () => void
  entityCustomerId: number
  nameCustomer?: string
  pinCustomer?: string
  refreshData: (data: Product[]) => void
  removeProductFromCart: (id: number) => void
}

/**
 * ShoppingCartOffCanvas component
 * This component is responsible for rendering the offcanvas of the shopping cart.
 * @param param {ShoppingCartOffCanvasProps} {show, handleClose, entityCustomerId, nameCustomer, refreshData, removeProductFromCart}
 * @param param.show Show modal
 * @param param.handleClose Hide modal
 * @param param.entityCustomerId Entity customer id
 * @param param.nameCustomer Name of the customer
 * @param param.refreshData Refresh data of the cart, this function is executed when the products are updated
 * @param param.removeProductFromCart Remove product from cart, this function is executed when a product is deleted
 * @returns
 * @example
 * <ShoppingCartOffCanvas
 * show={show}
 * handleClose={handleClose}
 * entityCustomerId={entityCustomerId}
 * nameCustomer={nameCustomer}
 * refreshData={refreshData}
 * removeProductFromCart={removeProductFromCart} />
 */
export const ShoppingCartOffCanvas = (props: ShoppingCartOffCanvasProps) => {
  // Destructuring props
  const { show, handleClose, entityCustomerId, nameCustomer='', pinCustomer='', refreshData, removeProductFromCart } = props
  const [dataProducts, setDataProducts] = useState<Product[]>([])
  const [dataToShow, setDataToShow] = useState<Product[]>([])
  const [orderFilter, setOrderFilter] = useState<IOrderFilter>(ORDER_FILTER.find(o => o.value === DEFAULT_ORDER)!);
  const { show: showDelete, handleClose: closeDelete, handleShow: openDelete } = useModal()
  const [idItemDelete, setItemDelete] = useState(0)
  const [notResult, setNotResult] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleInputChange = (event, index: number) => {
    const { name, value } = event.target
    const indexRow = dataProducts.findIndex((item) => item.id === index)
    const product = dataProducts[indexRow]
    let listProducts = [...dataProducts]

    if (name.includes('inputUND')) {
      listProducts[indexRow].quantity = value
      listProducts[indexRow].quantityMaster =
        Math.round((value / product.conversion_unit) * 100) / 100
    } else {
      if (product.quantityMaster === 0) {
        listProducts[indexRow].quantityMaster = value
      }
      listProducts[indexRow].quantityMaster = Math.round(value * 100) / 100
      listProducts[indexRow].quantity = Math.round(value * product.conversion_unit)
    }
    setDataProducts([...listProducts])
  }

  const handleIncrement = (event, index: number) => {
    let listProducts = [...dataProducts]
    const indexRow = listProducts.findIndex((item) => item.id === index)
    const product = listProducts[indexRow]

    if (event.target.name.includes('inputUND')) {
      listProducts[indexRow].quantity = Math.round(product.quantity) + 1
      listProducts[indexRow].quantityMaster =
        Math.round(((product.quantity) / product.conversion_unit) * 100) / 100
    } else {
      listProducts[indexRow].quantityMaster = Math.round((product.quantityMaster! + 1) * 100) / 100
      listProducts[indexRow].quantity = Math.round(
        (product.quantityMaster!) * product.conversion_unit
      )
    }
    setDataProducts([...listProducts])
  }

  const handleDecrement = (event, index: number) => {
    const indexRow = dataProducts.findIndex((item) => item.id === index)
    const product = dataProducts[indexRow]
    let listProducts = [...dataProducts]

    if (event.target.name.includes('inputUND')) {
      if (product.quantity > 1) {
        listProducts[indexRow].quantity = product.quantity - 1
        listProducts[indexRow].quantityMaster =
          Math.round(((product.quantity) / product.conversion_unit) * 100) / 100
      }
    } else {
      if (product.quantityMaster! > 1) {
        listProducts[indexRow].quantityMaster =
          Math.round((product.quantityMaster! - 1) * 100) / 100
        listProducts[indexRow].quantity = Math.round(
          (product.quantityMaster!) * product.conversion_unit
        )
      }
    }
    setDataProducts([...listProducts])
  }

  const generarPdf = () => {
    const unit = 'pt'
    const size = 'A4'
    const orientation = 'landscape'
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    const customer = `Resumen pre-pedido: ${pinCustomer ?? ""}`;
    const headers = [
      [
        'Código',
        'Descripción',
        'Marca',
        'Cantidad solicitada',
        'Master',
        'Unidad de medida',
        'Equi. en unidad master',
      ],
    ]

    doc.text(customer, marginLeft, 100)
    doc.addImage(toAbsoluteUrl('/media/logos/logo.png'), 'PNG', 40, 20, 50, 50)
    autoTable(doc, { html: '#my-table-report' })
    autoTable(doc, {
      startY: 120,
      head: headers,
      body: dataProducts.map((item) => {
        return [
          item.sku,
          item.name,
          item.brand,
          item.quantity ? item.quantity.toString() : '-',
          item.master_package,
          item.unit_measure,
          item.quantityMaster ? item.quantityMaster.toString() : '-',
        ]
      }),
      headStyles: { lineWidth: 0.5, fillColor: [0, 161, 96], textColor: [255, 255, 255] },
    })
    window.open(doc.output('bloburl'), '_blank')
  }

  useEffect(() => {
    if (show) {
      setDataProducts([])
      setDataToShow([])
      getDataSumary()
    }
  }, [show, orderFilter])

  const onSubmitDelete = async () => {
    await deleteItemPurchase.mutateAsync({
      entity_customer_id: entityCustomerId,
      content_product_id: idItemDelete,
    })
  }

  const getDataSumary = async () => {
    const { order_sort, order_field } = orderFilter;
    await whislistMutation.mutateAsync({entityCustomerId: entityCustomerId, params: { order_sort, order_field }});
  }

  const whislistMutation = useMutation(wishlistService.getSumaryPurchase, {
    onSuccess: (resp) => {
      const data = [...resp.data.result].map((item) => {
        return { ...item, quantityMaster: Math.round((item.quantity / item.conversion_unit!) * 100) / 100 }
      })
      setDataToShow(data)
      setDataProducts(data)
      setNotResult(data.length > 0 ? false : true)
    },
    onError: (e: any) => {
      showError('Al parecer ocurrio un error al obtener el resumen. Intenteló más tarde.')
      handleClose()
    },
  })

  const whislistUpdate = useMutation(wishlistService.addUpdateItemPurchase, {
    onSuccess: (resp) => {
      setLoading(false)
      toast.success('Los productos fueron actualizados con éxito', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      handleClose()
      // Refresh the list of products in the cart
      refreshData(dataProducts)
    },
    onError: (e: any) => {
      setLoading(false)
      showError('Al parecer ocurrio un error al editar los productos. Intenteló más tarde.')
      handleClose()
    },
  })

  const deleteItemPurchase = useMutation(wishlistService.cartItemDelete, {
    onSuccess: (resp) => {
      getDataSumary()
      showError('El producto se ha eliminado del pre-pedido.')
      // Reemove product from cart
      removeProductFromCart(idItemDelete);
    },
    onError: (e: any) => {
      showError('Al parecer ocurrio un error al eliminar. Intenteló más tarde.')
      handleClose()
    },
  })

  const showError = (message: string) => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: <IconErrorCircle />,
      bodyClassName: 'custom-toast-error',
    })
  }

  const handleSearch = (data) => {
    if (!data.length){
      setDataToShow([...dataProducts])
      return
    }
    
    const listProducts = dataProducts.filter(o => 
      o.name.toLowerCase().includes(data.toLowerCase())
    )
    setDataToShow(listProducts);
  }

  return (
    <>
      <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header className='bg-white' closeButton>
          <Offcanvas.Title>Resumen</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ marginBottom: "60px" }} className='bg-gray-200 p-5'>
          <div className='d-flex justify-content-between align-items-center gap-10 mb-5'>
            <SearchFilter manageSearch={handleSearch} name='item'/>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#00a16080',
                },
              })}
              styles={{
                indicatorSeparator: () => ({display: 'none'}),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#dcdcdc',
                  overflow: 'auto',
                  height: '44px',
                  width: '200px',
                  backgroundColor: 'white',
                  borderRadius: '6px'
                }),
                option: (styles, {isDisabled, isFocused, isSelected}) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused || isSelected ? '#EFEFEF' : 'white',
                    fontWeight: isSelected ? 500 : 400,
                    color: isSelected ? '#00A160' : '#464646',
                    cursor: isDisabled ? 'not-allowed' : 'default',
                    '&:active': {
                      backgroundColor: '#EFEFEF',
                    },
                  }
                },
              }}
              placeholder=''
              onChange={(e) => {
                if (e) setOrderFilter(e)
              }}
            isSearchable={false}
            options={ORDER_FILTER}
            value={orderFilter}
            />
          </div>
          <div className={'pb-20 ' + notResult ? 'h-100' : ''}>
            {dataToShow.length > 0 &&
              dataToShow.map((item, index) => (
                <div
                  key={index}
                  className='fv-row col-12 mb-3 bg-white rounded'
                  style={{ padding: 16 }}
                >
                  <div className='d-flex gap-2 justify-content-between'>
                    <p className='card-wishlist-content fs-5 fw-semibold'>{item.name}</p>
                    <IconTrash
                      style={{ cursor: 'pointer' }}
                      width={20}
                      height={20}
                      onClick={() => {
                        setItemDelete(item.id)
                        openDelete()
                      }}
                    />
                  </div>
                  <div className='d-flex gap-3 justify-content-between px-1 mt-5'>
                    <div className='d-flex justify-content-between align-items-center '>
                      <span className='card-wishlist-title fs-7 pe-4 '>{item.unit_measure}</span>
                      <div className='counter-container'>
                        <button
                          className='decrement-button text-white wdb'
                          type='button'
                          name={'inputUND' + index}
                          onClick={(event) => {
                            handleDecrement(event, item.id)
                          }}
                        >
                          -
                        </button>
                        <input
                          className='count'
                          type='number'
                          name={'inputUND' + index}
                          value={item.quantity}
                          onChange={(event) => {
                            handleInputChange(event, item.id)
                          }}
                          min='1'
                          step='1'
                          onKeyDown={(event) => {
                            if (event.key === '.') {
                              event.preventDefault() // Evita el carácter "." en el input
                            }
                          }}
                        />
                        <button
                          className='increment-button text-white px-3'
                          name={'inputUND' + index}
                          type='button'
                          onClick={(event) => {
                            handleIncrement(event, item.id)
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center '>
                      <span className='card-wishlist-title fs-7 pe-4 '>{item.master_package}</span>
                      <div className='counter-container'>
                        <button
                          className='decrement-button text-white wdb'
                          name={'inputMaster' + index}
                          type='button'
                          onClick={(event) => {
                            handleDecrement(event, item.id)
                          }}
                        >
                          -
                        </button>
                        <input
                          className='count'
                          type='number'
                          name={'inputMaster' + index}
                          value={item.quantityMaster}
                          onChange={(event) => {
                            handleInputChange(event, item.id)
                          }}
                          min='0'
                          step='1'
                          onKeyDown={(event) => {
                            if (event.key === '.') {
                              event.preventDefault() // Evita el carácter "." en el input
                            }
                          }}
                        />
                        <button
                          className='increment-button text-white px-3'
                          name={'inputMaster' + index}
                          onClick={(event) => {
                            handleIncrement(event, item.id)
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            
            {(!whislistMutation.isLoading && dataToShow.length === 0) &&
              <section className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <p className='text-muted'>No se encontró item.</p>
              </section>
            }
            
            {notResult && (
              <section className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <p className='text-muted'>Aún no has agregado productos a tu lista.</p>
              </section>
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: '20px',
              background: 'white',
            }}
          >
            <div className='d-flex justify-content-end'>
              <div>
                <button
                  type='button'
                  disabled={loading || dataProducts.length === 0 || dataProducts.some(item => (item.quantity === 0 || !item.quantity))}
                  className='btn btn-outline btn-outline-primary btn-sm  me-4'
                  onClick={async () => {
                    setLoading(true)
                    const dataProductsEdit = dataProducts.map((item) => {
                      return {
                        entity_customer_id: entityCustomerId,
                        content_product_id: item.id,
                        quantity: item.quantity,
                        selected: item.selected,
                      }
                    })
                    await whislistUpdate.mutateAsync(dataProductsEdit)
                  }}
                >
                  {!loading && (
                    <>
                      <IconUpdate className='me-2' />
                      Actualizar
                    </>
                  )}

                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Cargando...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-primary  btn-sm'
                  onClick={generarPdf}
                  disabled={dataProducts.length === 0}
                >
                  <IconPrint className='me-2' />
                  Imprimir
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalConfirmation
        icon={<IconCancelCircle />}
        title='¿Seguro que deseas eliminar el producto del pre-pedido?'
        textButton='Sí, eliminar'
        textButtonCancel='No, continuar'
        show={showDelete}
        onHide={closeDelete}
        onClick={onSubmitDelete}
      />
    </>
  )
}

const ORDER_FILTER: IOrderFilter[] = [
  {value: 1, label: "Más reciente", order_sort: "DESC", order_field: "purchase_cart_item.created_at"},
  {value: 2, label: "Más antiguo", order_sort: "ASC", order_field: "purchase_cart_item.created_at"},
  {value: 3, label: "Por nombre", order_sort: "ASC", order_field: "name"},
]

const DEFAULT_ORDER = 1;