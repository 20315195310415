import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SVG from 'react-inlinesvg'
import { IconAlertCircle, IconExit } from '../../../../../../commons/icons';
/* import { toAbsoluteUrl } from '../../../../../_metronic/helpers'; */
/* import IconCheckCircle from '../../../../../commons/icons/IconCheckCircle';
import IconCancelCircle from '../../../../../commons/icons/IconCancelCircle'; */
/* import { IconAlertCircle } from '../../../../../commons/icons'; */

export const ModalAlert = (props) => {
    /*  onSubmit={methods.handleSubmit(onSubmit)} */

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/*  <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="position-relative h-auto">
                    <div className="">
                        <div className="d-flex flex-column align-items-center">
                            <IconAlertCircle />
                            <h4 className='fs-2 px-5 text-center text-label my-5'>Campos Incompletos</h4>
                            <p className='text-label fw-light  mt-1'>
                                Por favor, complete todos los campos obligatorios antes de continuar. Se requiere la información en ciertos campos para procesar su solicitud.
                            </p>
                        </div>
                        <div className="d-grid gap-2 mt-5 ">
                            <button
                                type='button'
                                id='kt_sign_in_submit'
                                className='btn btn-primary'
                                onClick={props.onHide}
                            /*  disabled={isSubmitting || !isValid} */
                            >
                                Aceptar
                            </button>
                        </div>

                        {/* <div className="d-grid gap-2 mt-5 ">
                            <button
                                type='button'
                                id='kt_sign_in_submit'
                                className='button-output'
                                onClick={props.onHide}
                             disabled
                            >
                                Cancelar
                            </button>
                        </div> */}
                    </div>

                    <div onClick={props.onHide} className="position-absolute top-0 end-0 cursor-pointer">
                        <IconExit />
                    </div>


                </div>

            </Modal.Body>

        </Modal>
    )
}




