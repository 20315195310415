import * as React from "react"
import { SVGProps } from "react"
const IconFilter = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={12}
        height={14}
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.487305 1.48672C0.487305 1.05179 0.83988 0.699219 1.2748 0.699219H10.7248C11.1597 0.699219 11.5123 1.05179 11.5123 1.48672V3.84921C11.5123 4.05807 11.4293 4.25837 11.2816 4.40606L7.57479 8.1129V10.9367C7.57479 11.1456 7.49183 11.3459 7.34414 11.4935L5.76914 13.0685C5.54392 13.2938 5.2052 13.3611 4.91093 13.2393C4.61667 13.1174 4.4248 12.8302 4.4248 12.5117V8.1129L0.717958 4.40606C0.570273 4.25837 0.487305 4.05807 0.487305 3.84921V1.48672Z"
            fill={props.fill ?? "#00A160"}
        />
    </svg>
)
export default IconFilter