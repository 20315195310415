import { useState } from 'react';

export function useErrorHandling() {
    const [errorType, setErrorType] = useState<string>('');

    const handleApiError = (e) => {
        console.log(e);


        if (e.response) {
            const statusCode = e.response.data.code;

            let type = 'error_general';

            if (statusCode === 422) {
                type = 'error_422';
            } else if (statusCode === 500) {
                type = 'error_500';
            } else if (statusCode === 409) {
                type = 'error_409';
            }

            setErrorType(type);
        } else {
            setErrorType('error_general');
        }
    }

    const clearError = () => {
        setErrorType('');
    };

    return { errorType, handleApiError, clearError };
}