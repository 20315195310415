
import * as React from "react"
import { SVGProps } from "react"
const ArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={8}
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5002 6.79922L1.7002 3.99922M1.7002 3.99922L4.5002 1.19922M1.7002 3.99922L14.3002 3.99922"
      stroke="#00A160"
      strokeWidth={1.67}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowBack
