import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {useFormContext} from 'react-hook-form'
import {IconExit} from '../../../../../commons/icons'
import {DatePickerFilter} from '../../../../../commons/components/datePicker/DatePickerFilter'
import {useAuth} from '../../../auth'
import clsx from 'clsx'

export const FilterOffCanvas = ({filter, setFilter, show, handleClose, handleShow, initFilter}) => {
  const arregloDocumentType = filter?.entity_customer_document_type?.split(',')?.map(String)
  const filterArregloDocumentType = arregloDocumentType ? arregloDocumentType?.slice(1) : []
  const {eventSelectedCtx} = useAuth()
  const [isFocused, setIsFocused] = useState<string>('')
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: {errors},
  } = useFormContext()

  useEffect(() => {
    if (initFilter) {
      setValue('entity_customer_document_number', initFilter.entity_customer_document_number || '')
      setValue('entity_customer_name', initFilter.entity_customer_name || '')
      setValue('content_event_code', initFilter.content_event_code || '')
      setValue('content_event_name', initFilter.content_event_name || '')
      setValue('content_event_start_date', initFilter.content_event_start_date || '')
      setValue('content_event_end_date', initFilter.content_event_end_date || '')
      if (initFilter.entity_customer_document_type) {
        const documentTypes = initFilter.entity_customer_document_type?.split(',')
        setDocumentType(documentTypes)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFilter])

  const [documentType, setDocumentType] = useState<any>(filterArregloDocumentType)

  const handleApplyFilter = (data) => {
    const formatDocumentType = documentType.join(',')
    setFilter({
      ...data,
      entity_customer_document_type: formatDocumentType,
      content_event_id: eventSelectedCtx === 0 ? '' : eventSelectedCtx,
    })
    handleClose()
  }

  const handleResetFilter = () => {
    reset({
      entity_customer_document_number: '',
      entity_customer_name: '',
      content_event_id: eventSelectedCtx === 0 ? '' : eventSelectedCtx,
      content_event_code: '',
      content_event_name: '',
      content_event_start_date: '',
      content_event_end_date: '',
    })
    setFilter()
    handleClose()
    setDocumentType([])
  }
  const handleCheckboxChange = (e) => {
    const value = e.target.value
    if (e.target.checked) {
      // Agrega el valor a la lista si el checkbox se marca
      setDocumentType([...documentType, value])
    } else {
      // Elimina el valor de la lista si el checkbox se desmarca
      setDocumentType(documentType.filter((item) => item !== value))
    }
  }

  return (
    <>
      <Button variant='primary' onClick={handleShow} className='me-2'>
        <IconExit />
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header className='bg-white' closeButton>
          <Offcanvas.Title>Filtros</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className=' h-100'>
            <div className='fv-row col-12 mb-7'>
              <div className='form-label fs-6 text-dark mb-3'>Tipo de documento del cliente</div>
              <div className='d-flex align-items-center gap-5'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='DNI'
                    className='form-check-input'
                    checked={documentType?.includes('DNI')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    DNI
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='CE'
                    className='form-check-input'
                    checked={documentType?.includes('CE')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    CE
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='RUC'
                    className='form-check-input'
                    checked={documentType?.includes('RUC')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    RUC
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    value='PA'
                    className='form-check-input'
                    checked={documentType?.includes('PA')}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className='form-check-label text-black fw-light'
                    htmlFor='flexCheckDefault'
                  >
                    PASAPORTE
                  </label>
                </div>
              </div>
            </div>
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Número de documento</label>
              <input
                placeholder='Ingresar número de documento'
                {...register('entity_customer_document_number')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.entity_customer_document_number },
                  {
                    'is-valid': isFocused === 'entity_customer_document_number' && !errors.entity_customer_document_number,
                  }
                )}
                onFocus={() => setIsFocused('entity_customer_document_number')}
                type='text'
                autoComplete='off'
              />
            </div>

            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Nombre del cliente</label>
              <input
                placeholder='Ingresar nombre'
                {...register('entity_customer_name')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.entity_customer_name },
                  {
                    'is-valid': isFocused === 'entity_customer_name' && !errors.entity_customer_name,
                  }
                )}
                onFocus={() => setIsFocused('entity_customer_name')}
                type='text'
                autoComplete='off'
              />
            </div>

            {eventSelectedCtx === 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Fecha del evento</label>
                <DatePickerFilter
                  start_date={filter?.content_event_end_date}
                  end_date={filter?.content_event_start_date}
                  visible={false}
                />
              </div>
            )}

            {eventSelectedCtx === 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Código del evento</label>
                <input
                  placeholder='Ingresar código del evento'
                  {...register('content_event_code')}
                  className={clsx(
                    'form-control custom-input',
                    { 'is-invalid': errors.content_event_code },
                    {
                      'is-valid': isFocused === 'content_event_code' && !errors.content_event_code,
                    }
                  )}
                  onFocus={() => setIsFocused('content_event_code')}
                  type='text'
                  autoComplete='off'
                />
              </div>
            )}
            {eventSelectedCtx === 0 && (
              <div className='fv-row col-12 mb-7'>
                <label className='form-label fs-6 text-dark'>Nombre del evento</label>
                <input
                  placeholder='Ingresar nombre del evento'
                  {...register('content_event_name')}
                  className={clsx(
                    'form-control custom-input',
                    { 'is-invalid': errors.content_event_name },
                    {
                      'is-valid': isFocused === 'content_event_name' && !errors.content_event_name,
                    }
                  )}
                  onFocus={() => setIsFocused('content_event_name')}
                  type='text'
                  autoComplete='off'
                />
              </div>
            )}

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                padding: '20px',
                background: 'white',
              }}
            >
              {/* Contenido del footer */}
              <div className='d-flex '>
                <div className=' flex-grow-1 '></div>
                <div className=''>
                  <button
                    type='button'
                    className='btn btn-outline btn-outline-primary btn-sm  me-4'
                    onClick={handleResetFilter}
                  >
                    Limpiar
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary  btn-sm'
                    onClick={handleSubmit(handleApplyFilter)}
                  >
                    Aplicar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
