/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store/store'

const SidebarMenuMain = () => {

  const {userLogin} = useSelector((state: RootState) => state.auth)

  return (
    <>
      {((userLogin?.capability_permissions.includes('generated_order_view') ||
          userLogin?.admin === 1) && userLogin.limited_scope !== 1) && (
        <SidebarMenuItem to='/pedidos/listar' title='Pedidos' urlIcon='/media/svg/menu/shop-car' />
      )}

      {(userLogin?.capability_permissions.includes('content_event_view') ||
        userLogin?.admin === 1) && (
        <SidebarMenuItem to='/eventos/listar' title='Eventos' urlIcon='/media/svg/menu/ticket' />
      )}

      {(userLogin?.capability_permissions.includes('entity_customer_view') ||
        userLogin?.admin === 1) && (
        <SidebarMenuItemWithSub
          to='/clientes/'
          title='Clientes'
          urlIcon='/media/svg/menu/users'
          /*   fontIcon='bi-archive' */
          /*  icon='element-plus' */
        >
          <SidebarMenuItem to='/clientes/listar' title='Lista de clientes' hasBullet={true} />

          {(userLogin?.capability_permissions.includes('entity_customer_attendance') ||
            userLogin?.admin === 1) && (
            <SidebarMenuItem
              to='/clientes/registro-de-asistencia'
              title='Registo de asistencia'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {(userLogin?.capability_permissions.includes('content_product_view') ||
        userLogin?.admin === 1) && (
        <SidebarMenuItem
          to='/surtido/listar'
          title='Surtido'
          urlIcon='/media/svg/menu/shopping-bag'
        />
      )}

      {(userLogin?.capability_permissions.includes('purchase_cart_list') ||
        userLogin?.capability_permissions.includes('purchase_cart_view') ||
        userLogin?.admin === 1) && (
        <SidebarMenuItemWithSub to='#' title='Pre-pedido' urlIcon='/media/svg/menu/heart'>
          {(userLogin?.capability_permissions.includes('purchase_cart_view') ||  userLogin?.admin === 1) && (
            <SidebarMenuItem to='/wishlist/busqueda' title='Ingreso de pedidos' hasBullet={true} />
          )}
          {(userLogin?.capability_permissions.includes('purchase_cart_list') ||  userLogin?.admin === 1) && (
            <SidebarMenuItem to='/wishlist/listar' title='Lista de pre-pedido' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {( userLogin?.capability_permissions.includes('deal_gift_view') ||
        userLogin?.capability_permissions.includes('deal_gift_list') ||
        userLogin?.admin === 1 ) && (
          <SidebarMenuItemWithSub to='#' title='Regalos' urlIcon='/media/svg/menu/gift'>
            {(userLogin?.capability_permissions.includes('deal_gift_view') || userLogin?.admin === 1) && (
              <SidebarMenuItem to='/regalos/busqueda' title='Entrega de regalos' hasBullet={true} />
            )}
            {(userLogin?.capability_permissions.includes('deal_gift_list') || userLogin?.admin === 1) && (
              <SidebarMenuItem to='/regalos/listar' title='Regalos' hasBullet={true} />
            )}
          </SidebarMenuItemWithSub>
      )}

      {(userLogin?.capability_permissions.includes('user_person_view') ||
        userLogin?.admin === 1) && (
        <SidebarMenuItemWithSub
          to='/configuration/'
          title='Configuración'
          urlIcon='/media/svg/menu/config'
        >
          <SidebarMenuItem to='/configuration/users' title='Usuarios' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
