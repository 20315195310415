import React from 'react'
interface IProps {
    icon: JSX.Element
    title: string
    type: string
    onClick?: () => void
  }
  
export default function Toast({icon, title, type, onClick}:IProps) {
  return (
    <div className={'toast-message bgt-'+type}>
        {icon}
        <span>{title}</span>
    </div>
  )
}
