export const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SortDirection = typeof SortDirection[keyof typeof SortDirection]

export const NEXT_SORT_DIRECTION: {
  [key in SortDirection]: SortDirection
} = {
  [SortDirection.ASC]: SortDirection.DESC,
  [SortDirection.DESC]: SortDirection.NONE,
  [SortDirection.NONE]: SortDirection.ASC,
}
