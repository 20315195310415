import * as React from "react"
const IconUsers = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        fill="none"
        {...props}
    >
        <path
            stroke={props.stroke ? props.stroke : "#000"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.67}
            d="M9 2.66a3.578 3.578 0 1 1 0 4.733m2.683 10.156H.949v-.894a5.367 5.367 0 1 1 10.734 0v.894Zm0 0h5.366v-.894A5.367 5.367 0 0 0 9 12.006m.895-6.979a3.578 3.578 0 1 1-7.156 0 3.578 3.578 0 0 1 7.156 0Z"
        />
    </svg>
)
export default IconUsers
