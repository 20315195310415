import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'


const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout()
  const { userLogin } = useSelector((state: RootState) => state.auth);

  const shortName = userLogin && (userLogin?.firstname[0] + userLogin?.lastname[0])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/*  <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/*   <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
 */}

      <div className='d-flex ms-3 justify-content-around '>
        <div className={` d-flex align-items-center  gap-2 `}>
          <div className={`short-name`} >
            <span >{shortName}</span>
          </div>

          <span className="box-name ">{userLogin?.firstname} {userLogin?.lastname}</span>

          <div className={clsx('app-navbar-item')}>
            <div
              className={clsx('cursor-pointer symbol', userAvatarClass)}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <div className="p-3">
                <img src="/media/svg/menu/dots-vertical.svg" className='h-15px ' alt="" />
              </div>

            </div>
            <HeaderUserMenu />
          </div>


        </div>
      </div>





      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Navbar }
