import {SVGProps} from 'react'

const EventGalleryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path
      d='M4.5 20.25L10.5188 14.2312C11.544 13.206 13.206 13.206 14.2312 14.2312L20.25 20.25M17.625 17.625L19.7063 15.5437C20.7315 14.5185 22.3935 14.5185 23.4187 15.5437L25.5 17.625M17.625 9.75H17.6381M7.125 25.5H22.875C24.3247 25.5 25.5 24.3247 25.5 22.875V7.125C25.5 5.67525 24.3247 4.5 22.875 4.5H7.125C5.67525 4.5 4.5 5.67525 4.5 7.125V22.875C4.5 24.3247 5.67525 25.5 7.125 25.5Z'
      stroke='white'
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EventGalleryIcon
