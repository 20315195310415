import { useAuth } from "../../app/modules/auth";
import { IEventCreate } from "../../commons/interfaces/event/createEvent";
import { IEventGet } from "../../commons/interfaces/event/eventGet";
import { IEventList } from "../../commons/interfaces/event/eventList";
import { IEventShow } from "../../commons/interfaces/event/eventShow";
import { IEventUpdate } from "../../commons/interfaces/event/eventUpdate";
import api from "../../config/axios";




const eventService = {
  list: async (page: number | string, limit: number | string, search: string, filters = {} as any) => {
    try {
      const response = await api.get<IEventList>(`/api/content/event/list?page=${page}&limit=${limit}&search=${search ?? ''}&code=${filters.code ?? ''}&name=${filters.name ?? ''}&start_date=${filters.start_date ?? ''}&end_date=${filters.end_date ?? ''}&code_as400=${filters.code_as400 ?? ''}&status=${filters.status ?? ''}&user_creator=${filters.user_creator ?? ''}&id=${filters.id ?? ''}`);
      return response; 
    } catch (error) {
        throw(error);
    }
  },
  create: async (data) => {
    try {
      const response = await api.post<IEventCreate>('/api/content/event',data);
      return response; 
    } catch (error) {
        throw(error);
    }
  },
  get: async () => {
    try {
      const response = await api.get<IEventGet>('/api/content/event/get');
      return response; 
    } catch (error) {
        throw(error);
    }
  },
  update: async (data) => {
    try {
      const response = await api.put<IEventUpdate>('/api/content/event',data);
      return response; 
    } catch (error) {
        throw(error);
    }
  },
  show: async (id:number) => {
    try {
      const response = await api.get<IEventShow>(`/api/content/event/show?id=${id}`)
      return response; 
    } catch (error) {
        throw(error);
    }
  }

}



export default eventService